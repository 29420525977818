.dashboard {
  .dashboard-layout {
    max-width: 100% !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .dashboard {
    .dashboard-layout {
      max-width: 100% !important;
      padding: 0 !important;
      .mobile-grid {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
}
