.card-container {
  background: #ffffff;
  box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-radius: 0.8vw;
}

.chart-card{
  background: #ffffff;
  box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3);
  width: 100%;
}
@media screen and (max-width: 600px) {
  .card-container{
    border-radius: 2.415vw;
  }
}