.certificate {
  margin-top: 4.48vw;
  .topsearchdiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 47px;
    .search-container {
      width: 32.16vw;
      position: relative;
      .MuiFormControl-root {
        width: 100%;
        .MuiInputBase-root {
          background: #d9d9d9;
          border-radius: 40px;
          height: 4vw;
          input {
            padding: 17px 64px 17px 26px;
            font-size: 1.28vw;

            &::placeholder {
              color: #000;
              opacity: 1;
              font-family: 'Inter', sans-serif !important;
            }
          }
          fieldset {
            border: 0;
            border-color: transparent;
          }
        }
      }
      .searchicon {
        position: absolute;
        top: 60%;
        right: 20px;
        transform: translateY(-50%);
        img {
          width: 31px;
        }
      }
    }
  }
  .genrate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.12vw 3.2vw;
    h5 {
      font-size: 2vw !important;
      color: #3e3e3e;
    }
    .darkbtn {
      box-shadow: none;
      border-radius: 1.2vw !important;
      font-size: 1.6vw !important;
      color: #fffcfc !important;
      height: 4.08vw;
    }
  }

  .genrate-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.12vw 3.2vw;
    h5 {
      font-size: 2vw !important;
      color: #3e3e3e;
      flex: 1;
    }
    .eidt {
      display: flex;
      column-gap: 34px;
      align-items: center;
      img {
        cursor: pointer;
      }
      .icons {
        display: flex;
        column-gap: 34px;
        align-items: center;
        .eyebal {
          width: 46px;
          height: auto;
        }
        .download {
          width: 32px;
          height: 32px;
        }
        .send {
          width: 57px;
          height: auto;
        }
      }
      .darkbtn {
        box-shadow: none;
        width: fit-content;
        border-radius: 1.2vw !important;
        font-size: 1.6vw !important;
        color: #fffcfc !important;
        height: 4.08vw;
      }
    }
  }
}

@media  (min-width: 1930px) and (max-width:6000px) {
  .certificate{
      .topsearchdiv{
        .search-container {

          .MuiFormControl-root .MuiInputBase-root {
            border-radius: 3.2vw;
            input{
            padding: 1.28vw 2vw 1.28vw 2.08vw;
          }
        }
          .search-icon img {
            height: 1.8vw;
            width: 1.8vw;
            margin-right: 0.5vw;
        }
        }
      }
    }
  }
@media screen and (min-width: 1930px) and (max-width: 4000px) {
  .certificate .topsearchdiv .search-container .searchicon {
    position: absolute;
    top: 55%;
    right: 40px;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .certificate .topsearchdiv .search-container .searchicon {
    position: absolute;
    top: 55%;
    right: 20px;
    transform: translateY(-50%);
    img {
      width: 20px;
    }
  }
}

@media screen and (max-width: 800px) {
  .certificate .topsearchdiv .search-container .MuiFormControl-root .MuiInputBase-root {
    height: 6vw;
  }
  .certificate .topsearchdiv .search-container .searchicon {
    position: absolute;
    top: 55%;
    right: 20px;
    transform: translateY(-50%);
    img {
      width: 20px;
    }
  }
}

@media screen and (max-width: 600px) {
  .certificate {
    padding: 0 24px;
    .topsearchdiv {
      align-items: center;
      justify-content: center;
      margin-bottom: 47px;
      .search-container {
        width: 82.367vw;
        .MuiFormControl-root {
          width: 100%;
          .MuiInputBase-root {
            background: #d9d9d9;
            border-radius: 40px;
            height: 12.077vw;
            input {
              padding: 17px 64px 17px 26px;
              font-size: 4.28vw;
            }
            fieldset {
              border: 0;
              border-color: transparent;
            }
          }
        }
        .searchicon {
          position: absolute;
          top: 60%;
          right: 20px;
          transform: translateY(-50%);
          img {
            width: 31px;
          }
        }
      }
    }
    .genrate {
      flex-direction: column;
      h5 {
        font-size: 7.246vw !important;
        color: #3e3e3e;
      }
      .darkbtn {
        width: 100%;
        border-radius: 3.623vw !important;
        font-size: 4.831vw !important;
        color: #fffcfc !important;
        height: 12.319vw;
      }
    }

    .genrate-edit {
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      h5 {
        font-size: 7.246vw !important;
        color: #3e3e3e;
      }
      .eidt {
        display: flex;
        row-gap: 34px;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          cursor: pointer;
        }
        .icons {
          display: flex;
          column-gap: 34px;
          .eyebal,
          .download {
            width: 46px;
            height: auto;
          }
          .send {
            width: 57px;
            height: auto;
          }
        }
        .darkbtn {
          width: 100%;
          border-radius: 3.623vw !important;
          font-size: 4.831vw !important;
          color: #fffcfc !important;
          height: 12.319vw;
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
