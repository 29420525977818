.send-message {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
    padding: 0;
  }
  width: 100%;
  min-width: 394px;
  position: relative;
  column-gap: 12px;
  display: flex;
  flex-direction: column;

  input::placeholder {
    color: red;
    font-family: 'Inter', sans-serif !important;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
  }

  .messagesbox {
    height: 315px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    direction: ltr;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 1.2vw;
      background: #afaeae;
      border-radius: 25vw;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 25vw;
    }
    &::-webkit-scrollbar-thumb {
      background: #f5445e;
      border-radius: 25vw;
    }

    .message-preview-box {
      background: #ffffff;
      border: 0.2px solid #000000;
      border-radius: 15px;
      padding: 25px 21px;
      position: relative;
      margin-right: 12px;
      width: 27.84vw;
      span {
        padding-left: 10px;
      }
    }

    .date-text {
      position: absolute;
      right: 21px;
      font-size: 12px;
      bottom: 10px;
    }
  }
 
  .form-box {
    display: flex;
    flex-direction: column;
    gap: 26px;

    textarea {
      border-radius: 15px;
      height: 266px;
      margin-top: 26px;
      padding: 21px;
    }

    .darkbtn {
      padding: 13px 0 20px !important;
      border-radius: 15px !important;
      box-shadow: none !important;
      text-transform: capitalize !important;
      font-size: 1.6vw !important;
    }
  }
  .form-container {
    width: 27.84vw;
  }
}

.message_preview_new_scroll{
  height: 800px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 1.2vw;
    background: #afaeae;
    border-radius: 25vw;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 25vw;
  }
  &::-webkit-scrollbar-thumb {
    background: #f5445e;
    border-radius: 25vw;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .send-message {
    min-width: 31.488vw;
    .messagesbox {
      .message-preview-box {
        .message-text {
          font-size: 1.25rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 4000px) {
  .send-message {
    .messagesbox {
      .message-preview-box {
        width: 98%;
      }
    }
    .form-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .send-message {
    .messagesbox {
      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
        border-radius: 0;
      }
      &::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 25vw;
      }
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 0;
      }
    }
  }
}
