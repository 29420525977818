.allreviews {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
    padding: 0;
  }
  margin-top: 4.48vw;
  .search_result_container {
    max-width: 100% !important;
    padding: 0;

    .searchbtninput {
      padding-top: 9.6vw;
      margin-bottom: 3.52vw;
      .font25px {
        font-size: 3.2vw !important;
      }
    }
  }

  .MuiGrid-grid-xs-3 {
    background-color: #f4f4f4;
    margin-top: 1.1vw;
    .sidebar {
      width: inherit;
    }
  }
  .message-section {
    padding-bottom: 5rem;
    .feedback-id-card-container {
      .message-card {
        .reviewr-details {
          .review-status {
            color: #f5445e;
          }
        }
      }
    }
    .card-container {
      grid-template-columns: auto 2fr;
      gap: 25px;
      display: grid;
      .scroll-dir {
        direction: rtl;
        overflow-y: scroll;
        overflow-x: hidden;
      
        .Review-preview {
          direction: ltr;
          display: flex;
          flex-direction: column;
          margin-left: 12px;
          gap: 20px;
          .review-card {
            border-radius: 20px;
            display: flex;
            overflow: hidden;
            gap: 20px;
            border: 1px solid #000;
            align-items: center;
            padding-right: 12px;
            height: 113px;
            .profile-img {
              display: flex;
            }
            .review-details {
              p {
                font-size: 1.12vw;
              }
              .review {
                color: #f5445e;
              }
            }
          }
        }
      }
      .send-message {
        overflow: auto;
        .form-container {
          .form-rating {
            direction: ltr;
            overflow-x: hidden;
            &::-webkit-scrollbar {
              width: 1.2vw;
              background: #afaeae;
              border-radius: 25vw;
            }
            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px grey;
              border-radius: 25vw;
            }
            &::-webkit-scrollbar-thumb {
              background: #f5445e;
              border-radius: 25vw;
            }
            height: 320px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .form-rating-cards {
              border: 0.2px solid #000000;
              border-radius: 15px;
              padding: 25px 21px;
              display: flex;
              gap: 10px;
              flex-direction: column;
              align-items: flex-start;
              margin-right: 12px;
              span {
                label {
                  color: #f5445e;
                  svg {
                    width: 3.36vw;
                    height: 3.36vw;
                  }
                }
              }
            }
          }
        }
        .stars {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }
    .feedback-id-card-container {
      grid-template-columns: 1fr;
      .feedback-id-messagebox {
        direction: ltr;
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        gap: 20px;
        .message-preview-box {
          background: #ffffff;
          border: 0.2px solid #000000;
          border-radius: 15px;
          padding: 25px 21px;
          position: relative;
        }
      }
      .form-container {
        width: 100%;
        .form-box {
          display: flex;
          flex-direction: column;
          gap: 26px;
          textarea {
            border-radius: 15px;
            height: 200px;
            margin-top: 20px;
            padding: 21px;
          }
          .text-area {
            &::placeholder {
              color: #000 !important;
              font-family: 'Inter', sans-serif !important;
            }
          }
          .darkbtn {
            padding: 13px 0 20px !important;
            border-radius: 15px !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }
}

.allreviews .message-section .card-container .scroll-dir .Review-preview .review-card{
  width: 400px;
}

@media only screen and (max-width: 600px) {
  .allreviews {
    padding: 0 20px;
    overflow-x: hidden;
    .all-screen-container,
    .css-1oqqzyl-MuiContainer-root {
      max-width: 100% !important;
    }
    .message-section {
      .feedback-id-card-container {
        .message-card {
          .reviewr-details {
            h3,
            h4,
            p {
              font-size: 14px;
            }
          }
        }
        .whitebtn{
          width: 100% !important;
          height: 12vw !important;
          font-size: 4.8vw !important;
          border-radius: 4.8vw !important;
          border: 2px solid #f5445e;
        }
      }
      .card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .scroll-dir {
          height: 100%;
          .Review-preview {
            .review-card {
              width: 100%;
              .profile-img {
                img {
                  width: 21.739vw;
                }
              }
              .review-details {
                p {
                  font-size: 3.382vw;
                }
              }
            }
          }
          .feedback-id-messagebox {
            .message-preview-box {
              .stars {
                column-gap: 1.5vw;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                img {
                  width: 7.5vw;
                  height: 7.5vw;
                }
              }
            }
          }
        }
      }
      .feedback-id-card-container {
        .form-container {
          .form-box .darkbtn {
            padding: 20px 0 !important;
          }
          .form-rating {
            height: 320px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .form-rating-cards {
              border: 0.2px solid #000000;
              border-radius: 15px;
              padding: 25px 21px;
              display: flex;
              gap: 10px;
              flex-direction: column;
              align-items: flex-start;
              span {
                label {
                  color: #f5445e;
                }
              }
            }
          }
        }
      }
    }
    .search_result_container {
      .searchbtninput {
        margin-bottom: 10.628vw;
        .font25px {
          font-size: 9.662vw !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 1930px) {
  .allreviews {
    .search_result_container {
      max-width: 70% !important;
      padding: 0;
      .above4Ksidebar {
        padding-left: 0 !important;
      }
      .above4K {
        max-width: 90% !important;
      }
    }
  }
  .allreviews .message-section .card-container .scroll-dir .Review-preview .review-card{
    width: 550px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .allreviews {
    .message-section {
      .card-container {
        .scroll-dir {
          width: 26.667vw;
          .Review-preview {
            .review-card {
              width: 200px;
              height: 100px;
              padding-right: 4px;
              gap: 10px;
              .profile-img {
                width: 80px;
                img {
                  width: 100%;
                  object-fit: cover;
                }
              }
              .review-details {
                .title {
                  font-size: 1.556vw;
                }
                p {
                  font-size: 1.556vw;
                }
              }
            }
          }
        }
        .send-message {
          .messagesbox {
            .message-preview-box {
              h3,
              span {
                font-size: 1.556vw;
              }
            }
          }
          .stars {
            padding-left: 10px;
            img {
              width: 3.5vw;
              height: 3.5vw;
            }
          }
        }
      }
      .mobile-gap {
        column-gap: 2.172vw !important;
      }
    }
  }
  .send-message {
    .messagesbox {
      .message-preview-box {
        padding: 15px 6px !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .allreviews {
    .searchbtninput {
      .mobileshow {
        display: none;
      }
    }
    .message-section {
      .card-container {
        grid-template-columns: auto auto;
        column-gap: 2rem;
        .send-message {
          min-width: 100%;
          .message-preview-box {
            width: 100%;
          }
        }
      }
      .empty-review {
        grid-template-columns: 1fr;
      }
    }
  }
}
