.mobilemenu {
  border-top: 1px solid #e0e0e0;

  li {
    a {
      color: #2f2b23;
    }
  }
  .lastbtn {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .darkbtn {
    font-size: 14px !important;
    line-height: 17px !important;
    border-radius: 15px !important;
    min-height: 42px;
    width: 100%;
    box-shadow: none !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordion-root:before {
    height: 0 !important;
  }
  ul {
    list-style-type: none;
    padding: 0px !important;
    margin: 0px !important;
  }

  .MuiAccordionDetails-root {
    display: inherit;
  }
  .subject-text-new {
    a {
      color: #000000;
    }
  }
  .MuiTypography-body1 {
    font-size: 16px !important;
    line-height: 20px !important;
    color: #000000 !important;
    a {
      color: #000000;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .inner-according {
    background-color: transparent;
  }
  .inner-according-last-section {
    display: flex;
    align-items: center;
    padding: 0px 20px 0 20px;
    margin: 10px 0 25px;
  }
  .seeall-text-new {
    text-decoration: underline;
  }
  .seller-art-heading {
    padding: 20px 20px 10px;
    cursor: pointer;
  }
  .MuiAccordionSummary-content {
    margin: 10px 0;
    align-items: center;
  }

  .MuiAccordionSummary-root {
    padding: 0px 20px 0 20px;
    width: 100%;
  }
  .submenu {
    .MuiAccordionSummary-root {
      padding: 0px 20px 0 16px;
      width: 100%;
      background-color: #ebeaea;
    }
  }
  .MuiSvgIcon-root {
    width: 10vw;
    height: 8vw;
    font-size: 6.5vw;
    transform: rotate(270deg);
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0px 0;
  }

  .MuiAccordionDetails-root {
    background: #ebeaea;
  }
  .subject-text-new {
    font-weight: 600 !important;
    margin-bottom: 15px;
    font-size: 4.2vw !important;
    line-height: 4.83vw !important;
  }
  .geometry-text {
    font-size: 4.2vw !important;
    line-height: 4.83vw !important;
    margin-bottom: 10px;
    a {
      color: #000000;
    }
  }
  .grid-center {
    text-align: center;
    margin-top: 5vw !important;
  }
  .grid-padd {
    padding-left: 28px;
  }
  .buy-art-img-mobile {
    width: 27px;
    height: 27px;
    margin-right: 15px;
  }
  .MuiTypography-body1 .Mui-expanded {
    border: none;

    svg.MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }

  li {
    padding: 10px 20px;
  }
}
