.main-container {
  width: 100%;
  background: #f6f6f6;
  border-radius: 1.6vw;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  gap: 1.6vw;
  padding: 1.5vw;
  margin-bottom: 1.6vw;
  box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3);
  h5 {
    font-size: 1.6vw;
    font-weight: 700;
    color: #000;
  }
  .svg-img {
    width: 15vw;
    height: auto;
    object-fit: contain;
  }
  .darkbtn {
    font-size: 1.6vw !important;
    width: fit-content;
    height: 4vw !important;
    box-shadow: none;
  }
}

@media screen and (max-width: 600px) {
  .main-container {
    border-radius: 3.6vw;
    gap: 6.6vw;
    padding: 4.5vw;
    margin-bottom: 5vw;
    h5 {
      font-size: 5.6vw;
      text-align: center;
    }
    .svg-img {
      width: 45vw;
    }

    .darkbtn {
      font-size: 4.6vw !important;
      height: 12vw !important;
      border-radius: 4.5vw !important;
      width: 100%;
    }
  }
}
