.badge {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0.400vw 0.800vw;
  color: #fff;
  border-radius: 0.800vw;
  font-size: 1.120vw;
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  .badge {
    padding: 1.5vw 2.8vw;
    border-radius: 3.623vw;
    font-size: 4.348vw;
}
}