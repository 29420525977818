.bio-profile {
  .mainheadart {
    margin-top: 4.48vw;
  }
  .bio-edit {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.76vw !important;
    gap: 0 !important;
    h5 {
      font-size: 2vw;
      padding-bottom: 2.32vw;
    }
    .textareabox {
      margin-bottom: 1.36vw;
      textarea {
        height: 27.44vw !important;
        font-size: 1.6vw;
        color: #000;
      }
      .MuiTextField-root {
        width: 100%;
      }
      .MuiInputBase-root::after {
        border: none;
      }
      .MuiInput-underline {
        background: #fffcfc;
        border: 1px solid #d9d9d9;
        border-radius: 1.2vw;
        padding: 1.28vw 1.68vw;
        font-size: 1.6vw;
        line-height: 1.92vw;
      }
      .MuiInput-underline:before {
        content: none;
      }
    }
    .graybtn {
      box-shadow: none;
      font-size: 1.6vw !important;
      padding: 1.12vw 0 1.04vw !important;
      border-radius: 1.2vw !important;
      height: 4.08vw;
      text-transform: capitalize !important;
    }
  }
  .darkbtn {
    box-shadow: none;
    font-size: 1.6vw !important;
    padding: 1.12vw 0 1.04vw !important;
    border-radius: 1.2vw !important;
    height: 4.08vw;
    text-transform: capitalize !important;
  }
  .bio-share {
    display: flex;
    flex-direction: column;
  }
  .bio-share {
    h5 {
      font-size: 2vw;
      margin-top: 17px;
    }
    .soical-sharing {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 4vw;
      padding-left: 4.48vw;
      .soical-icons {
        display: flex;
        img {
          width: 3.2vw;
          height: 3.2vw;
        }
      }
      .text-field {
        background: #fffcfc;
        width: 100%;
        height: 4vw;
        .textfiledspan {
          width: inherit;
          height: inherit;
          .MuiFormControl-root {
            width: inherit;
            height: inherit;
            .MuiInputBase-root {
              border-radius: 1.2vw;
              height: inherit;
              input {
                width: 100%;
                height: inherit;
                font-size: 1.6vw;
                fieldset {
                  border-color: #d9d9d9;
                  height: inherit;
                }
              }
            }
          }
        }
      }
    }
    .graybtn {
      box-shadow: none;
      font-size: 1.6vw !important;
      padding: 1.12vw 0 1.04vw !important;
      border-radius: 1.2vw !important;
      height: 4.08vw;
      text-transform: capitalize !important;
    }

  }
}

.shareuri{
  display:block !important;
  row-gap: 0 !important;
  column-gap: 0 !important;
  button {
    margin-left: 2rem;
  }
  .shareable_link{
    margin-bottom: 8px;
  }
}
.share_ui_inner{
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .bio-profile {
    .bio-share {
      .soical-sharing {
        .text-field {
          .textfiledspan {
            .MuiFormControl-root {
              .MuiInputBase-root {
                input {
                  font-size: 3.623vw;
                }
              }
            }
          }
        }
        .marginbottom{
          margin-bottom: 2vw;
        }
      }
      .graybtn {
        width: 100% !important;
        font-size: 4.831vw !important;
        padding: 3.382vw 0 3.14vw !important;
        border-radius: 3.623vw !important;
        height: 12.319vw;
      }
    }
    padding: 0 20px;
    .bio-edit {
      margin-bottom: 11.353vw !important;
      gap: 0 !important;
      h5 {
        font-size: 4.831vw;
        padding-bottom: 4.106vw;
      }
      .textareabox {
        margin-bottom: 4.831vw;
        textarea {
          height: inherit !important;
          padding-top: 3.865vw;
          font-size: 4.831vw;
        }
        .MuiTextField-root {
          height: 250px;
        }
        .MuiInput-underline {
          height: inherit;
          border-radius: 15px;
          font-size: 4.831vw;
          line-height: 5.797vw;
        }
        .MuiInput-underline:before {
          content: none;
        }
      }
      .graybtn {
        width: 100% !important;
        font-size: 4.831vw !important;
        padding: 3.382vw 0 3.14vw !important;
        border-radius: 3.623vw !important;
        height: 12.319vw;
      }
    }
    .darkbtn {
      box-shadow: none;
      font-size: 4.831vw !important;
      padding: 3.382vw 0 3.14vw !important;
      border-radius: 3.623vw !important;
      height: 12.319vw;
    }
    .bio-share {
      display: flex;
      flex-direction: column;
    }
    .bio-share {
      .soical-sharing {
        padding: 0;
        .soical-icons {
          img {
            width: 33px;
            height: 33px;
          }
        }
        .text-field {
          height: 50px;
          .textfiledspan {
            .MuiFormControl-root {
              .MuiInputBase-root {
                border-radius: 15px;
              }
            }
          }
        }
      }
      h5 {
        font-size: 25px;
        margin-top: 17px;
      }
    }
  }
  .share_ui_inner{
    display: block;

    button{
      margin-top: 20px;
      margin-left: 0;
    }
  }
}
