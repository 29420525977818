.mysubscription {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3.48vw;
  margin-bottom: 9.92vw;

  .additional_text {
    margin-top: 2vw;
    text-align: center;
  }

  h2 {
    font-size: 3.2vw;
    margin-bottom: 5.2vw;
  }

  .MuiToggleButton-root {
    border: none;
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    background-color: #d9d9d9;
    width: 10.32vw;
    border-radius: 0.8vw;
    font-size: 1.12vw;
    line-height: 1.34vw;
    text-transform: capitalize;
    height: 3.36vw;
    color: #3e3e3e;
    margin-left: -1vw !important;

    &:hover {
      background: #d9d9d9 !important;
    }
  }

  .whitebtn {
    color: #f5445e;
    font-size: 1.4vw !important;
    height: 3.6vw;
    border: 0.08vw solid #f5445e;
    border-radius: 0.8vw !important;
    background: #fff;
    width: 18vw;
    margin-right: 15.36vw;
    cursor: pointer;
  }

  .Mui-selected {
    background-color: #f5445e !important;
    color: #fffcfc !important;
    margin-left: -1vw !important;
    border-left: 1px solid transparent;
    border-top-left-radius: 0.8vw !important;
    border-bottom-left-radius: 0.8vw !important;
    border-top-right-radius: 0.8vw !important;
    border-bottom-right-radius: 0.8vw !important;
    z-index: 9;

    &:hover {
      background: #f5445e !important;
    }
  }

  .btn-toggle-section {
    display: flex;
    justify-content: flex-start;
    width: inherit;
  }

  .btn-toggle-text-section {
    display: flex;
    justify-content: space-between;
    width: inherit;
    margin-top: 2vw;
    align-items: center;
  }

  .MuiToggleButtonGroup-root.MuiToggleButtonGroup-grouped:not(:first-of-type) {
    margin-left: -1vw;
    border-left: 1px solid transparent;
    border-top-left-radius: 0.8vw;
    border-bottom-left-radius: 0.8vw;
  }

  .plans {
    display: flex;
    margin: 42px 0 50px;
    gap: 2vw;
    flex-wrap: wrap;

    .plan-card {
      padding: 27px 35px;
      width: 31%;
      background: #fff;
      box-shadow: 0px 4px 40px 0.2px rgba(0, 0, 0, 0.3);
      border-radius: 0.8vw;
      color: #3e3e3e;
      position: relative;

      .popular {
        display: none;
      }

      h3,
      h4,
      h5,
      h6 {
        font-size: 2vw;
        margin: 0;
      }

      .free_text_new {
        font-size: 2.8vw;
      }

      h4 {
        margin-top: 1.68vw;
        margin-bottom: 1.12vw;
      }

      h5 {
        margin-top: 2.08vw;
        margin-bottom: 1.16vw;
      }

      span {
        font-size: 1.2vw;
      }

      h6 {
        color: #80c86e;
        margin-bottom: 2.88vw;
      }

      .check-list {
        align-items: flex-start;
        -webkit-column-gap: 8px;
        column-gap: 8px;
        display: flex;

        .blacktick {
          width: 1vw;
          height: 1vw;
          margin-top: 0.3vw;
        }
      }

      .plan_panding {
        .darkbtn {
          height: 3.68vw !important;
          border-radius: 1.2vw;
          position: absolute;
          bottom: 27px;
        }
      }

      .artwork_text_new {
        padding: 0;
        text-align: left;
      }

      .plans-detail {
        text-align: left;
        font-size: 1.28vw;
        line-height: 1.52vw;
        margin-bottom: 3.96vw;
        margin-top: 1vw;
      }

      p {
        font-size: 1.2vw;
        text-align: center;
        padding: 0 1.6vw;
      }
      .save30px {
        text-align: left;
        margin: 0.5vw 0vw 1vw 0vw;
      }

      .defualt {
        background: #e5e5e5;
        border-radius: 1.6vw;
        padding: 0.5vw 1.6vw;
        color: #c1c1c1;
        min-width: 14.56vw !important;
        position: absolute;
        bottom: 27px;
      }

      .darkbtn {
        box-shadow: none;
        border-radius: 1.2vw !important;
        height: 3.36vw;
        font-size: 1.44vw !important;
        min-width: 15.56vw !important;
        position: absolute;
        bottom: 27px;
        color: #000000 !important;
        background-color: #fff !important;
        border: 1px solid #000000;
      }

      .error {
        color: #f5445e;
        margin-bottom: 12px;
        font-size: 20px;
      }

      .graybtn {
        padding: 0 60px !important;
        box-shadow: none;
        border-radius: 15px !important;
        height: 42px;
        font-size: 1.6vw !important;
      }
    }

    .most-popular {
      position: relative;
      width: 31%;
      border-radius: 1.6vw;
      color: #fff;
      align-items: initial;
      border-radius: 1.6vw;
      padding: 27px 35px;
      background: linear-gradient(180deg, #ffa284 0%, #f5445e 100%);

      .check-list {
        .blacktick {
          -webkit-filter: invert(100%);
          filter: invert(100%);
        }
      }

      .darkbtn {
        background-color: #fff !important;
        color: #f5445e !important;
        height: 3.68vw !important;
        border-radius: 1.24vw !important;
        position: absolute;
        bottom: 27px;
        border: none;
      }

      h3 {
        font-size: 2vw;
      }

      .strip {
        color: #ffffffa6;
        margin-bottom: 0;
      }

      h6 {
        margin-bottom: 36px;
      }

      h5 {
        margin-top: 0;
        margin-bottom: 0;
      }

      .special-offer-text {
        display: flex;
        flex-direction: column;

        .top-text {
          font-size: 1.6vw;
          padding: 0;
          padding-top: 1.6vw;
          color: #fffcfc;
          font-weight: 400 !important;
        }

        .bottom-text {
          font-size: 3.2vw;
          font-size: 3.1vw;
        }
      }

      .popular {
        width: 28.84vw;
        width: 48%;
        background: #f5445e;
        position: absolute;
        top: -35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px 20px 0px 0px;
        left: 0;

        p {
          margin: 0;
          color: #fff;
          padding: 15px;
          font-size: 1.6vw;
        }
      }
    }

    .add-padding {
      padding-top: 10.4vw;
    }
  }

  .policy-plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4.8vw;

    p,
    a {
      margin: 0;
      font-size: 1.6vw;
      color: #000;
      text-align: center;
    }

    a {
      text-decoration: underline;
    }
  }

  .subscriptiondetail {
    padding: 2.4vw !important;
    align-items: flex-end;
    flex-direction: column;

    .table_last_btn {
      .darkbtn {
        height: 3.9vw !important;
        box-shadow: none;
        min-width: 13.84vw !important;
        font-size: 1.6vw !important;
      }
    }

    .plancard {
      width: 100%;
      height: 9.32vw;
      border-radius: 0.8vw;
      background: #eaeaea;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.5vw;
      margin-bottom: 2vw;

      h2,
      h5,
      h6 {
        margin: 0;
        padding: 0;
        color: #3e3e3e;
      }

      h2 {
        font-size: 2vw;
      }

      h5 {
        font-size: 1.44vw;
        margin-bottom: 0.5vw;
      }

      h6 {
        font-size: 1.2vw;
        color: #000;
      }
    }

    .subscriptiontable {
      table {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        tbody {
          tr {
            th {
              width: 30.36vw;
              font-size: 1.28vw;
              padding: 20px 16px;
              border-bottom: 1px solid #d9d9d9;
              color: #3e3e3e;
              font-family: 'Inter', sans-serif !important;
              padding-left: 81px;
            }

            td {
              width: 20.36vw;
              font-size: 1.28vw;
              border-bottom: 1px solid #d9d9d9;
              color: #3e3e3e;
              padding: 6px;
              font-family: 'Inter', sans-serif !important;

              img {
                width: 2.4vw;
                height: 2.32vw;
              }
            }
          }
        }
      }
    }

    .planbtn {
      display: flex;

      div {
        width: 19.36vw;
        display: flex;
        justify-content: center;
      }

      .darkbtn {
        box-shadow: none;
        width: 13.84vw;
        height: 4vw;
        border-radius: 1.2vw !important;
        font-size: 1.6vw !important;
      }
    }
  }
}

.my_subscription_new {
  width: 100% !important;
  padding: 0 !important;
  max-width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.cancel-plan-popup {
  padding: 0 !important;

  .redeem-voucher {
    font-size: 1.6vw;
    padding: 24px;
    border-bottom: 1px solid #d9d9d9;
  }

  .cancel-plan {
    color: #000;

    .cancel {
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: flex-start;
      padding: 24px;
      column-gap: 5vw;

      h4 {
        font-size: 1.2vw;
      }

      .MuiFormGroup-root {
        label {
          .MuiTypography-body1 {
            font-size: 1.2vw;
          }
        }
      }
    }

    .canel-reason {
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      padding: 24px;
      column-gap: 4.5vw;
      justify-content: start;
      align-items: center;

      .reason {
        font-size: 1.2vw;
        margin: 0;
      }

      .MuiFormControl-root {
        margin: 0;
        width: 100%;

        .MuiInputBase-root {
          height: 4vw;
          border-radius: 1.2vw;
          background: #fffcfc;
          border: 1px solid #d9d9d9;
          font-size: 1.2vw;
        }

        svg {
          font-size: 2vw;
        }

        fieldset {
          border: none;
        }
      }

      .error {
        font-size: 1.2vw;
        color: red;
      }
    }

    .cancelbtns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 24px;
      column-gap: 0.96vw;

      .whitebtn,
      .darkbtn {
        margin: 0.8vw 0;
        width: fit-content !important;
        border-radius: 1.2vw !important;
        height: 4vw !important;
        font-size: 1.4vw !important;
        padding: 0 1.3vw !important;
      }

      .whitebtn {
        border: 1px solid #f5445e;
      }
    }
  }
}

.membership_page {
  .plans .most-popular .darkbtn {
    width: 80%;
    min-width: 16.56vw !important;
  }
  .plans .plan-card .darkbtn {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .mysubscription {
    .btn-toggle-text-section {
      display: block;
      text-align: center;
      margin-top: 25px;

      .font20pxtypo {
        margin-bottom: 25px;
      }
    }

    .subscriptiondetail .subscriptiontable table tbody tr td img {
      width: 7.72vw;
      height: 8.04vw;
    }

    .subscriptiondetail .plancard {
      height: 20.32vw;
      border-radius: 10px;
      padding: 2.5vw;
      margin-bottom: 4vw;
    }

    .subscriptiondetail .plancard h5 {
      font-size: 4.44vw;
      margin-bottom: 2.5vw;
    }

    .subscriptiondetail .plancard h2 {
      font-size: 3vw;
    }

    .subscriptiondetail .plancard h6 {
      font-size: 3vw !important;
    }

    .MuiToggleButton-root {
      width: 32.32vw;
      border-radius: 10px;
      font-size: 3.12vw;
      line-height: 5.34vw;
      height: 8.36vw;
    }

    .Mui-selected {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      margin-left: -3vw !important;
    }

    .plans .plan-card p {
      font-size: 4.2vw;
    }
    .plans .plan-card .save30px {
      text-align: left;
      margin: 1.5vw 0vw 3.2vw 3.4vw;
    }

    .plans .plan-card .plan_panding {
      .darkbtn,
      .graybtn {
        height: 9.68vw !important;
        border-radius: 10px !important;
      }
    }

    .plans .most-popular .darkbtn,
    .plans .plan-card .darkbtn {
      height: 9.68vw !important;
      border-radius: 10px !important;
    }

    h2 {
      font-size: 9.662vw;
      margin-bottom: 5.2vw;
    }

    .plans {
      flex-direction: column;
      row-gap: 20px;

      .plan-card {
        padding: 44px 20px 18px;
        width: 90%;
        margin: auto;
        border-radius: 2.415vw;

        .defualt {
          font-size: 4.6vw;
          border-radius: 4.6vw;
          padding: 3vw 2vw;
          position: relative;
          width: 100%;
        }

        span {
          font-size: 4.2vw;
        }

        h3,
        h6 {
          font-size: 6.454vw;
          margin-bottom: 15px;
        }

        h4,
        h5 {
          font-size: 8.454vw;
          margin-top: 2vw;
        }

        .plans-detail {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 62px;
        }

        div {
          width: 100%;
        }

        .darkbtn,
        .graybtn {
          width: 87%;
          min-width: 87% !important;
          font-size: 20px !important;
          padding: 0 !important;
        }
      }

      .most-popular {
        width: 90%;
        margin: 20px auto 0;
        border-radius: 20px;
        padding: 44px 20px 18px;

        h3 {
          margin-bottom: 10px;
        }

        .strip {
          text-decoration: line-through;
        }

        h6 {
          margin-bottom: 36px;
        }

        .popular {
          width: 90%;
          margin: -20px auto 0;
          border-radius: 20px 20px 0px 0px;

          p {
            margin: 0;
            color: #fff;
            padding: 15px;
            font-size: 4vw;
          }
        }

        .special-offer-text .top-text {
          padding: 14px 0 0;
          font-size: 4.8vw;
          line-height: 5.5vw;
        }

        .special-offer-text .bottom-text {
          font-size: 9.6vw;
        }

        h3 {
          font-size: 7vw;
          text-align: center;
        }
      }

      .add-padding {
        padding-top: 15vw;
      }
    }

    .btn-toggle-section {
      flex-direction: column;

      .whitebtn {
        font-size: 4.4vw !important;
        height: 10.9vw;
        border-radius: 3.623vw !important;
        width: 100%;
        margin-right: 0;
        margin-bottom: 3.5vw;
      }

      .MuiToggleButtonGroup-root {
        width: 100%;
      }

      .MuiToggleButton-root {
        width: 100%;
        border-radius: 2.415vw;
        font-size: 4.6vw;
        height: 10.9vw;
      }
    }

    .subscriptiondetail {
      padding: 2.4vw !important;
      align-items: flex-end;
      flex-direction: column;

      .subscriptionplan {
        display: flex;
        column-gap: 1.6vw;

        .plancard {
          width: 57.005vw;
          height: 37.198vw;
          border-radius: 0.8vw;
          background: #eaeaea;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h2,
          h5,
          h6 {
            margin: 0;
            padding: 0;
            color: #3e3e3e;
          }

          h2 {
            font-size: 8.454vw;
          }

          h5 {
            font-size: 3.348vw;
            margin-bottom: 2.08vw;
          }

          h6 {
            font-size: 3.623vw;
            color: #000;
          }
        }
      }

      .subscriptiontable {
        table {
          min-width: 945px;

          tbody {
            border-top: 1px solid;

            tr {
              th,
              td {
                width: 75.005vw;
                font-size: 3.865vw;
              }
            }
          }
        }
      }

      .planbtn {
        display: flex;

        div {
          width: 57.005vw;
          display: flex;
          justify-content: center;
        }

        .darkbtn {
          width: 33.84vw;
          height: 8vw;
          border-radius: 2.2vw !important;
          font-size: 3vw !important;
        }
      }
    }

    .policy-plan {
      p,
      a {
        font-size: 4.831vw;
      }
    }
  }
  .membership_page {
    .plans .plan-card {
      .darkbtn,
      .graybtn {
        width: 100%;
        min-width: 87% !important;
        font-size: 20px !important;
        padding: 0 !important;
        position: relative;
      }
    }
  }
  .mysubscription .plans .plan-card .check-list .blacktick {
    width: 4vw;
    height: 4vw;
    margin-top: 0.6vw;
  }

  .mysubscription .plans .plan-card .free_text_new {
    margin-bottom: 3vw;
    font-size: 4.5vw;
    margin-top: 0;
  }

  .mysubscription .additional_text {
    margin-top: 5vw;
    font-size: 2.8vw !important;
    text-align: center;
  }

  .cancel-plan-popup {
    .redeem-voucher {
      font-size: 4.2vw;
    }

    .cancel-plan {
      .cancel {
        column-gap: 5vw;

        h4 {
          font-size: 3.5vw;
          padding-top: 9px;
        }

        .MuiFormGroup-root {
          label {
            .MuiTypography-body1 {
              font-size: 3.5vw;
            }
          }
        }
      }

      .canel-reason {
        display: flex;
        flex-direction: column;

        .reason {
          font-size: 3.5vw;
        }

        .MuiFormControl-root {
          width: 100%;

          .MuiInputBase-root {
            height: 9vw;
            border-radius: 3.2vw;
            font-size: 3.4vw;
          }

          svg {
            font-size: 7vw;
          }

          fieldset {
            border: none;
          }
        }

        .error {
          font-size: 3.5vw;
        }
      }

      .cancelbtns {
        flex-direction: column;

        .whitebtn,
        .darkbtn {
          margin: 2.5vw 0 0;
          width: 100% !important;
          border-radius: 3.2vw !important;
          height: 9vw !important;
          font-size: 3.4vw !important;
          padding: 0 !important;
        }

        .whitebtn {
          border: 1px solid #f5445e;
        }

        .darkbtn {
          margin-bottom: 2.5vw;
        }
      }
    }
  }
}

@media screen and (min-width: 100px) and (max-width: 414px) {
  .mysubscription .subscriptiondetail .subscriptiontable table {
    min-width: clamp(627px, 228vw, 940px);
  }
}

@media screen and (min-width: 1930px) {
  .mysubscription .plans .plan-card .darkbtn {
    width: 85%;
  }
  .mysubscription .plans .plan-card .defualt {
    width: 85%;
  }
}
