.addartworkpage {
  padding-top: 7.84vw;

  .error_text {
    margin-bottom: 0;
  }
input{
  &::placeholder {
    color: #000;
    opacity: 1;
    font-family: 'Inter', sans-serif !important;
}
}
.MuiInputLabel-root{
  color: #000 !important; 
}
  .darkbtn,
  .graybtn {
    width: 100%;
    box-shadow: none;
    height: 4vw;
    margin-bottom: 3rem;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.12vw;

    h2 {
      font-size: 3.2vw;
    }

    .whitebtn {
      color: #f5445e;
      height: 4.8vw;
      border: 0.08vw solid #f5445e;
      background: #fff;
      width: 20.8vw;
    }
  }

  .addartworkcard {
    padding: 1.6vw 1.92vw 2.56vw !important;
    border-radius: 0.8vw !important;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 2vw;
    }

    .add-image-card {
      display: flex;
      column-gap: 1.2vw;
      justify-content: center;

      .primary-card,
      .secondary-card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 23.04vw;
        height: 21.76vw;
        background: #fff;
        border: 1px solid #000;
        border-radius: 1.6vw;
        flex-wrap: wrap;

        .closebtn {
          display: flex;
          justify-content: center;
          background: rgb(245, 68, 94);
          width: 25px;
          height: 25px;
          position: absolute;
          top: -5px;
          right: -5px;
          border-radius: 25px;
          color: #fff;
          align-items: center;

          svg {
            font-size: 20px;
            margin: 0 !important;
          }
        }

        img {
          width: 6.56vw;
          height: auto;
          margin-bottom: 2.24vw;
        }

        .user-upload {
          width: 8.56vw;
          height: auto;
          margin-bottom: 2.24vw;
          border-radius: 1.2vw;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }

        h6 {
          font-size: 1.6vw;
          margin-bottom: 0.8vw;
        }

        p {
          font-size: 0.8vw;
        }
      }

      .secondary-card {
        width: 35.04vw;
      }
    }
  }

  .addartworkdescription {
    padding: 1.6vw 1.92vw !important;
    flex-direction: column;
    gap: 0 !important;

    #artworkDesc {
      .text-editor {
        margin-bottom: 1.6vw;

        .ql-toolbar {
          background: #fffcfc;
          border: 2px solid #d9d9d9;
          border-bottom: none;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          padding: 15px 24px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .ql-header {
            width: 120px;
            font-size: 20px;

            .ql-picker-label {
              &:hover {
                color: #f5445e !important;

                svg {
                  .ql-stroke {
                    stroke: #f5445e !important;
                  }
                }
              }
            }

            .ql-picker-options {
              .ql-picker-item {
                &:hover {
                  color: #f5445e !important;
                }
              }

              .ql-selected {
                color: #f5445e !important;
              }
            }
          }
        }

        .quill {
          background: #fffcfc;
          height: 310px;

          .ql-container {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            border: 2px solid #d9d9d9;

            .ql-editor {
              padding: 15px 24px;

              &::before {
                left: 32px;
                font-size: 1.6vw;
                color: rgba(0, 0, 0, 0.6);
                font-style: normal !important;
              }

              p {
                font-size: 1.6vw;
              }
            }
          }
        }

        .input-error-message {
          color: #ff0000;
          margin-top: 10px;
          font-size: 1vw;
        }
      }
    }

    p {
      font-size: 2vw;
      padding-bottom: 2.4vw;
      margin: 0;
    }
.artworkDesc{
  padding-bottom: 0;
  margin-bottom: 16px;
}
    .textfiledspan,
    .selectbox {
      margin-bottom: 1.2vw;
    }

    .MuiFormControl-root {
      width: 100%;
      margin: 0;
      .MuiInputBase-root {
        border-radius: 10px;
        height: 4vw;
        background: #fffcfc;
        font-size: 1.6vw;
        padding: 1.68vw;

        .MuiSelect-select {
          padding: 0;
        }

        svg {
          font-size: 2.2vw;
          color: #000;
        }

        input {
          height: 4vw;
          padding: 0;
        }

        fieldset {
          border-color: #d9d9d9;
        }
      }
    }

    .textareabox {
      margin-bottom: 1.2vw;

      .MuiFormControl-root {
        width: 100%;
        margin: 0;
        resize: none;
        label {
          font-size: 1.6vw;
        }

        .MuiInputBase-root {
          height: auto;

          &::after,
          &::before {
            border-bottom: none;
          }
        }
      }

      textarea {
        height: 270px !important;
      }
    }
  }

  .addartworksize {
    padding: 2.32vw 2.24vw 1.44vw !important;
    display: flex;
    flex-direction: column;
    gap: 0 !important;
    
    .select_shipping{
      margin-bottom: 20px;
      label{
        width: fit-content;

      }
      .MuiTypography-root.MuiTypography-body1{
        font-size: 1.4vw !important;
        line-height: 1.72vw !important;
        margin-left: 15px;
        font-family: 'Varela Round', sans-serif !important;
      }
      .MuiButtonBase-root.MuiCheckbox-root{
        color:#D9D9D9;

      }
      .MuiButtonBase-root.MuiCheckbox-root.Mui-checked{
        color:#EC425B;

      }
    }

    .MuiFormControl-root {
      .MuiInputBase-root {
        input {
          font-size: 1.6vw;
          color: #000;
          padding: 0 14px;

          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px #fffcfc inset;
          }
        }
      }
    }

    p {
      font-size: 2vw;
      padding-bottom: 0.96vw;
      margin: 0;
    }

    .size {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.52vw;

      p {
        font-size: 1.6vw;
        padding-bottom: 0;
        margin: 0;
        margin-top: 0.85vw;
      }

      .MuiFormControl-root {
        width: 15.12vw;
        margin: 0;
        .MuiInputBase-root {
          border-radius: 10px;
          height: 4vw;
          background: #fffcfc;
          font-size: 1.6vw;

          svg {
            font-size: 2.2vw;
            color: #000;
          }

          input {
            height: 4vw;
            text-align: center;
            padding: 0 14px;

            &:-webkit-autofill {
              -webkit-box-shadow: 0 0 0px 1000px #fffcfc inset;
            }
          }

          fieldset {
            border-color: #d9d9d9;

            
          }
        }
      }

      .textfiledspan {
        margin-bottom: 0;

        
      }
    }

    .selectbox {
      margin-bottom: 1.12vw;

      .MuiFormControl-root {
        .MuiInputBase-root {
          font-size: 1.6vw;
          color: #000;

          .MuiSelect-select {
            font-size: 1.6vw;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .textfiledspan {
      margin-bottom: 1.12vw;
    }

    .MuiFormControl-root {
      width: 100%;
      margin: 0;
      .MuiInputBase-root {
        border-radius: 10px;
        height: 4vw;
        background: #fffcfc;

        svg {
          font-size: 2.2vw;
          color: #000;
        }

        input {
          height: 4vw;
        }

        fieldset {
          border-color: #d9d9d9;

        
        }
      }
    }

    .whitebtn {
     
      font-size: 1.4vw !important;
      line-height: 1.72vw !important;
      background: #fff;
      border: 1px solid #f5445e !important;
      color: #f5445e !important;
      border-radius: 1.2vw !important;
      height: 4vw;
      min-width: 24.88vw !important;
      width: fit-content;
    }
  }
.author_size_btn{
  .outlinebtn{
    min-height:4vw ;
    border-radius: 1rem !important;
  }
}
  .extra-tag {
    padding: 1.6vw 1.92vw 2.56vw !important;
    border-radius: 0.8vw !important;
    display: flex;
    flex-direction: column;
    row-gap: 2vw !important;

    .tooltip-section {
      .selectbox {
        width: 100%;
      }

      display: flex;

      img {
        width: 1.5vw;
        height: 1.5vw;
        margin-top: 1.1vw;
        margin-left: 0.5vw;
      }

    }

    p {
      font-size: 2vw;
      margin: 0;
      padding: 0;
    }

    .adult-text-new {
      font-size: 1.4vw;
      margin-bottom: 1vw;
      line-height: 2vw;
    }

    .presets {
      .tags {
        display: flex;
        width: 100%;
        margin-bottom: 1.12vw;
        align-items: baseline;

        p {
          font-size: 1.6vw;
          padding-bottom: 2.8vw;
          margin: 0;
          padding: 0;
          flex: 1;
        }

        .taginput {
          flex: 2;
          width: 100%;

          .ReactTags__tags {
            display: flex;
            flex-direction: column-reverse;

            .ReactTags__tagInputField {
              width: 100%;
              border-radius: 10px;
              height: 4vw;
              background: #fffcfc;
              border: 1px solid #d9d9d9;
              padding: 1vw;
              padding-right: 5vw;
            }

            .ReactTags__selected {
              display: flex;
              flex-wrap: wrap;
              column-gap: 1vw;

              span {
                background: #d9d9d9;
                padding: 10px;
                border-radius: 12px;
                display: flex;
                column-gap: 5px;
                margin-top: 1.6vw;
                word-break: break-all;

              
                button {
                  cursor: pointer;
                  background: #f5445e;
                  border: none;
                  border-radius: 50px;
                  color: #fff;
                  font-size: 20px;
                  line-height: 0;
                  height: 10px;
                  width: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 14px;
                 
                }
              }
            }
          }

          .darkbtn {
            position: absolute;
            top: 0.56vw;
            right: 15px;
            line-height: 0 !important;
            width: 3.04vw;
            height: 2.72vw;
            padding: 0 !important;
            min-width: 0 !important;
            box-shadow: none;
            min-height: 0 !important;
            border-radius: 0.4vw !important;
            cursor: pointer;
            margin: 0;
            font-size: 1.6vw !important;
            padding-top: 2px !important;
          }
        }
      }

      .selectbox {
        margin-bottom: 1.2vw;

        .MuiFormControl-root {
          width: 100%;
          margin: 0;
          .MuiInputBase-root {
            border-radius: 10px;
            height: 4vw;
            background: #fffcfc;

            fieldset {
              border-color: #d9d9d9;
            }

            input {
              height: 4vw;
            }

            svg {
              font-size: 2.2vw;
              color: #000;
            }
          }
        }
      }

      .color-pick {
        display: flex;
        flex-wrap: wrap;
        column-gap: 2.4vw;
        row-gap: 2.4vw;
        padding-left: 1.2vw;
        align-items: center;

        p {
          margin-right: 0.48vw;
          font-size: 1.6vw;
        }

        .MuiFormControlLabel-root {
          .MuiButtonBase-root {
            width: 3.44vw;
            height: 3.44vw;
          }
        }

        .colordiv {
          width: 3.44vw;
          height: 3.44vw;
          cursor: pointer;

          span {
            visibility: hidden;
          }
        }
      }
    }
  }

 
}
ul{
  li{
    .MuiButtonBase-root.MuiCheckbox-root.Mui-checked.MuiCheckbox-root{
      color: #FFF;
    }
  }
}
.availability-section{
  label{
    width: fit-content;
    font-size: 1.3vw !important;
    line-height: 1.68vw !important;
    font-family: 'Inter', sans-serif !important;

  }
}
.addartworkpage .extra-tag .presets .selectbox .MuiFormControl-root .MuiInputBase-root {
  font-size: 1.6vw;
}

.addartworkpage .extra-tag .presets .tags .taginput .ReactTags__tags .ReactTags__selected {
  span {
    font-size: 1.6vw;
    align-items: center;

    button {
      font-size: 1.6vw;
      padding: 1.086vw;
    }
  }
}

.addartworkpage .extra-tag .presets .tags .taginput .ReactTags__tags .ReactTags__tagInputField {
  font-size: 1.6vw;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipArrow{
  box-shadow: 0px 4px 4px 0px #00000040;
background-color:#f8f8f8 ;
border-radius: 10px;
padding: 14px;
color: #000;
font-size: 14px;
font-family: 'Inter', sans-serif !important;

.MuiTooltip-arrow{
color:#f8f8f8 ;

}
}
.disable_label{
  .MuiTypography-root .MuiBox-root {
    color: #8B8B8B;
    align-items: baseline;
    svg{
      color: #000;
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .addartworkpage {
    padding-top: 11.3vw;

    .heading {
      h2 {
        font-size: 5.089vw;
      }

      .whitebtn {
        border-radius: 2.545vw !important;
        width: 33.079vw;
        height: 7.634vw;
        font-size: 2.545vw !important;
      }
    }

    .addartworkcard {
      padding: 2.545vw 3.053vw !important;

      .add-image-card {
        column-gap: 1.908vw;
      }
    }
  }


}

@media screen and (min-width: 601px) and (max-width: 1250px) {
  .addartworkpage {
    .addartworkdescription {
      .MuiFormControl-root {
        .MuiInputBase-root {
          height: 6.5vw;
        }
      }
    }

    .addartworksize {
      .MuiFormControl-root {
        .MuiInputBase-root {
          height: 6.5vw;
        }
      }

      .size {
        p {
          margin-top: 1.85vw;
        }

        .MuiFormControl-root {
          .MuiInputBase-root {
            height: 6.5vw;
          }
        }
      }
    }

    .extra-tag {
      .presets {
        .selectbox {
          .MuiFormControl-root {
            .MuiInputBase-root {
              height: 6.5vw;
            }
          }
        }

        .tags {
          .taginput {
            .darkbtn {
              top: 1.8vw;
            }

            .ReactTags__tags {
              .ReactTags__tagInputField {
                height: 6.5vw;
              }
            }
          }
        }
      }

    }
  }
 
}

@media screen and (max-width: 600px) {
  .addartworkpage .addartworksize .select_shipping .MuiTypography-root.MuiTypography-body1{
    font-size: 3.5vw !important;
    line-height: 5.72vw !important;
    padding-left: 8px;
  }
  .availability-section{
    label{
      font-size: 4.347vw !important;
    line-height: 5.7971vw !important;
    }
  }
  .addartworkpage .addartworksize {
    .textfiledspan, .selectbox, .size {
      margin-bottom: 3.12vw;
    }
  }
  .addartworkpage .addartworksize p{
    padding-bottom: 3.5vw;
  }
  .addartworkpage .addartworkdescription .textfiledspan, .addartworkpage .addartworkdescription .selectbox{
    margin-bottom: 3.12vw;
  }
  .addartworkpage .extra-tag .presets .selectbox{
    margin-bottom: 3.12vw;
  }
  .addartworkpage {
    padding-top: 20.773vw;
    width: 90%;
    margin: 0 auto;

    .whitebtn {
      font-size: 3.5vw !important;
      line-height: 5.72vw !important;
    }
    .extra-tag {
      .tooltip-section {

        img {
          width: 4vw;
          height: 4vw;
          margin-top: 6vw;
          margin-left: 1.5vw;
        }

      }

      .adult-text-new {
        font-size: 3.2vw;
        margin-bottom: 4vw;
        line-height: 4vw;
      }
    }

    .addartworkcard {
      padding: 4.831vw 5.797vw 15.942vw !important;
      border-radius: 4.831vw !important;
      gap: 0 !important;

      h4 {
        font-size: 6.039vw;
        padding-bottom: 5.072vw;
      }

      .add-image-card {
        flex-direction: column;
        row-gap: 12.802vw;

        .primary-card,
        .secondary-card {
          width: 100%;
          padding: 13.285vw 0;
          height: auto;
          border-radius: 4.831vw;

          .user-upload {
            width: 39vw;
            border-radius: 3.623vw;
          }

          .user-upload:nth-last-child(3) {
            margin-left: 5px;
          }

          img {
            width: 19.807vw;
          }

          h6 {
            font-size: 4.831vw;
          }

          p {
            font-size: 2.415vw;
          }
        }
      }
    }

    .heading {
      justify-content: center;
      display: block;
      text-align: center;

      h2 {
        font-size: 9.662vw;
      }

      .whitebtn {
        margin-top: 20px;
        width: 100%;
        font-size: 3.5vw !important;
        height: 9vw;
      }
    }

    .addartworkdescription {
      padding: 4.831vw 5.797vw !important;

      #artworkDesc {
        .text-editor {
          margin-bottom: 4.2vw;

          .quill {
            .ql-container {
              .ql-editor {
                &::before {
                  font-size: 3.6vw;
                }
              }
            }
          }

          .ql-toolbar {
            row-gap: 12px;

            .ql-header {
              width: 100%;

              .ql-picker-label {
                padding-left: 5px;
              }
            }
          }

          .input-error-message {
            font-size: 3.6vw;
          }
        }
      }

      .textfiledspan {
        .error_text {
          margin-bottom: 1.8vw;
          font-size: 3.6vw;
          line-height: 3.5vw;
        }
      }

      p {
        font-size: 6.039vw;
        padding-bottom: 8.454vw;
      }

      .multi-select {
        margin-bottom: 4.831vw;
      }

      .MuiFormControl-root {
        margin-bottom: 3.623vw;

        .MuiInputBase-root {
          height: 16vw;
          font-size: 4.831vw;
          padding: 0 8.213vw;

          .MuiSelect-select {
            height: inherit !important;
            display: flex;
            align-items: center;
          }

          svg {
            font-size: 7vw;
          }

          input {
            height: inherit !important;
          }

          fieldset {
            border-color: #d9d9d9;
          }
        }
      }

      .selectbox {
        .MuiFormControl-root {
          margin-bottom: 0;
        }

        .MuiInputBase-root {
          margin-bottom: 3vw;
        }
      }

      .textareabox {
        margin-bottom: 3.623vw;

        .MuiFormControl-root {
          width: 100%;
          margin: 0;
          resize: none;

          label {
            font-size: 3.6vw;
          }

          .MuiInputBase-root {
            height: auto !important;

            &::after,
            &::before {
              border-bottom: none;
            }
          }
        }
      }
    }

    .addartworksize {
      padding: 7.005vw 3.382vw 8.213vw !important;

      p {
        font-size: 6.039vw;
        padding-bottom: 8.454vw;
      }

      .size {
        display: flex;
        flex-direction: column;

        p {
          display: none;
        }

        .textfiledspan {
          width: 100%;
          margin-bottom: 3.14vw;

          .error_text {
            margin-bottom: 1.8vw;
            font-size: 3.6vw;
            line-height: 3.5vw;
            position: relative;
            margin-top: 5px;
          }
        }

        .MuiFormControl-root {
          width: 100%;

          .MuiInputBase-root {
            height: 16vw;

            svg {
              font-size: 2.2vw;
              color: #000;
            }

            input {
              height: 12vw;
              font-size: 4.831vw;
              padding: 0;
            }

            fieldset {
              border-color: #d9d9d9;

             
            }
          }
        }
      }

      .selectbox {
        margin-bottom: 3.14vw;

        .MuiFormControl-root {
          .MuiInputBase-root {
            .MuiSelect-select {
              font-size: 4.6vw;
              display: block;
              min-height: 0;
            }
          }
        }
      }

      .textfiledspan {
        margin-bottom: 3.14vw;
      }

      .MuiFormControl-root {
        width: 100%;
        margin: 0;

        .MuiInputBase-root {
          border-radius: 10px;
          height: 16vw;
          background: #fffcfc;

          svg {
            font-size: 7.2vw;
            color: #000;
          }

          input {
            font-size: 4.831vw;
          }

          fieldset {
            border-color: #d9d9d9;
          }
        }
      }

      .whitebtn {
        font-size: 4.831vw !important;
        line-height: 1.5625vw !important;
        background: #fff;
        border: 1px solid #f5445e !important;
        color: #f5445e !important;
   
        height: 12.077vw;
      }
    }

    .extra-tag {
      padding: 8.454vw 5.797vw 4.348vw !important;
      border-radius: 2.415vw !important;
      display: flex;
      flex-direction: column;
      row-gap: 2.72vw !important;

      p {
        font-size: 6.039vw;
      }

      .presets {
        .selectbox {
          .MuiFormControl-root {
            .MuiInputBase-root {
              font-size: 4.831vw !important;
            }
          }
        }

        .tags {
          display: flex;
          flex-direction: column;
          align-items: self-start;

          p {
            font-size: 4.831vw;
            padding-bottom: 8.213vw;
          }

          .taginput {
            flex: 2;
            width: 100%;
            margin-bottom: 3.623vw;

            .ReactTags__tags {
              display: flex;
              flex-direction: column-reverse;

              .ReactTags__tagInputField {
                height: 14vw;
                padding-right: 18vw;
              }
            }

            .addbtn {
              top: 2.899vw;
              right: 3.623vw;
              padding: 2px 9px;
              border-radius: 9px;
            }

            .darkbtn {
              width: 10.386vw;
              height: 9.903vw;
              top: 1.8vw;
              font-size: 4.831vw !important;
              border-radius: 1.208vw !important;
            }
          }
        }

        .selectbox {
          margin-bottom: 4.2vw;

          .MuiFormControl-root {
            .MuiInputBase-root {
              height: 16vw;

              svg {
                font-size: 7.2vw;
              }
            }
          }
        }

        .color-pick {
          padding-left: 5.2vw;
          column-gap: 4.4vw;
          row-gap: 4.4vw;

          .MuiFormControlLabel-root {
            margin: 0;
          }

          p {
            margin-right: 100%;
            font-size: 4.831vw;
          }

          .MuiFormControlLabel-root {
            .MuiButtonBase-root {
              width: 10.386vw;
              height: 10.386vw;
            }
          }

          .colordiv {
            width: 10.386vw;
            height: 10.386vw;
          }
        }
      }
    }

    .darkbtn,
    .graybtn {
      height: 12.077vw;
      border-radius: 3.623vw !important;
      font-size: 4.831vw !important;
    }

    textarea {
      padding: 12px 0;
    }
  }
  .addartworkpage .extra-tag .presets .tags .taginput .ReactTags__tags .ReactTags__selected span{
    font-size: 3.8vw;

  }
  .MuiMenu-paper {
    .MuiList-root {
      width: 100%;
      overflow: auto;

      .drop-down-bg {
        width: 150%;
      }
    }
  }

  .addartworkpage .addartworksize .MuiFormControl-root .MuiInputBase-root input {
    height: 10vw;
  }
}

@media screen and (min-width: 700px) and (max-width: 1250px) {
  .addartworkpage .extra-tag .tooltip-section img{
    margin-top: 2.5vw;
  }
}