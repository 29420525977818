.whitebtn {
  font-family: 'Inter', sans-serif !important;

  font-style: normal;
  font-weight: 400;
  font-size: 1.28vw !important;
  line-height: 1.6vw !important;
  text-align: center;
  background: #fffcfc;
  border: 1px solid #000000;
  box-shadow: none;
  color: #000;
  border-radius: 0.8vw !important;
  padding: 0.9vw 1.165vw !important;
  font-weight: normal !important;
  min-width: 7.986vw !important;
  letter-spacing: 0px !important;
  min-height: 2.864vw;
  text-transform: inherit !important;
}
.darkbtn {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.28vw !important;
  line-height: 1.6vw !important;
  text-align: center;
  color: #fffcfc !important;
  background: #f5445e !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.8vw !important;
  font-weight: normal !important;
  min-width: 7.986vw !important;
  letter-spacing: 0px !important;
  min-height: 2.864vw;
  text-transform: inherit !important;
}
.graybtn {
  font-family: 'Inter', sans-serif !important;

  font-style: normal;
  font-weight: 400;
  font-size: 1.28vw !important;
  line-height: 1.6vw !important;
  text-align: center;
  color: #000 !important;
  background: #E2E2E2 !important;
  border-radius: 0.8vw !important;
  font-weight: normal !important;
  min-width: 7.986vw !important;
  letter-spacing: 0px !important;
  min-height: 2.864vw;
  text-transform: inherit !important;
}
.greenbtn {
  font-family: 'Inter', sans-serif !important;

  font-style: normal;
  font-weight: 400;
  font-size: 1.28vw !important;
  line-height: 1.6vw !important;
  text-align: center;
  color: #fffcfc !important;
  background: #32ba7c !important;
  border-radius: 0.8vw !important;
  padding: 11px 55px !important;
  font-weight: normal !important;
  letter-spacing: 0px !important;
  min-height: 2.864vw;
  text-transform: inherit !important;
}

.outlinebtn {
  font-family: 'Inter', sans-serif !important;

  font-style: normal;
  font-weight: 400;
  font-size: 1.28vw !important;
  line-height: 1.6vw !important;
  text-align: center;
  color: #f5445e !important;
  background: transparent !important;
  border-radius: 0.8vw !important;
  padding: 11px 55px !important;
  font-weight: normal !important;
  letter-spacing: 0px !important;
  min-height: 2.864vw;
  text-transform: inherit !important;
  border: 2px solid !important;
}


.disable_darkbtn{
  color: #000 !important;
  background: #E2E2E2 !important;
  pointer-events: none;
  border-radius: 0.8vw !important;
  font-size: 1.28vw !important;
  line-height: 1.6vw !important;
}
.disable_darkbtn_studio{
  color: #000 !important;
  background: #E2E2E2 !important;
  pointer-events: none;
  border-radius: 0.8vw !important;
  font-size: 0.7vw !important;
  line-height: 1.6vw !important;
}

@media screen and (max-width: 600px) {
.disable_darkbtn, .outlinebtn, .greenbtn, .graybtn, .darkbtn, .whitebtn {
 border-radius: 2.415vw !important;
 font-size: 3.864vw !important;
    line-height: 4.83vw !important; 
}
}