.sidegridcolor {
  background-color: #1e282c;
  margin-top: 1vw !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
  .main-head {
    align-items: center;
  }
  .log_outbtn {
    margin-top: 3vw;
    margin-bottom: 2vw;
    .darkbtn {
      box-shadow: none;
      width: 100%;
      min-height: 3.2vw;
      font-size: 1.2vw !important;
    }
  }
  .mainlogo {
    width: 10vw;
    height: auto;
    margin: 2vw 0 0 2.5vw;
  }
  .location_img {
    width: 1.5vw;
    height: 1.5vw;
  }
  .sidebar {
    .sidemenubar {
      padding-top: 0.6vw;
      padding-left: 0;
      margin-top: 0;
      li {
        padding: 0 4.24vw;
        padding: 0 3vw;
        list-style: none;
        font-size: 1.2vw;
        line-height: 2vw;
        cursor: pointer;
        display: flex;
        align-items: center;
        .MuiPaper-root {
          width: 100%;
        }
        a {
          color: #fffcfc;
          text-decoration: none;
        }
      }
      .activesidebar {
        padding-left: calc(16px + 0.96vw);
        padding-right: 0.96vw;
        padding-left: 3.5vw;
        a {
          color: #fff;
          width: 100%;
          padding: 10px;
        }
      }
      .new_sidebar_icon {
        margin-right: 1vw;
      }
      .side-bar-height {
        height: 4vw !important;

        a {
          padding-left: 1vw;
        }
      }
      .hoverimg {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: -30px;
          transform: translateY(-50%);
          background-image: url('../../assets/images/icons/lock.svg');
          width: 20px;
          height: 26px;
        }
      }
      .hoverimg:hover {
        position: relative;
        &::after {
          content: 'Upgrade';
          top: 0;
          transform: translateY(0);
          background-image: none;
          height: auto;
          font-size: 1vw;
          font-weight: 900;
          color: #319918;
        }
      }
      .subheading-2 {
        .hoverimg {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            background-image: url('../../assets/images/icons/lock-white.svg');
            width: 20px;
            height: 26px;
          }
        }
      }
      .subheading-2 {
        .hoverimg:hover {
          position: relative;
          &::after {
            content: 'Upgrade';
            top: 50%;
            transform: translate(0%, -50%);
            background-image: none;
            height: auto;
            font-size: 1vw;
            font-weight: 900;
            color: white;
            right: 10px;
            width: fit-content;
          }
        }
      }
    }
  }
}

.hoverimg {
  position: relative;
  &::after {
    content: 'Upgrade';
    height: auto;
    font-size: 4vw;
    font-weight: 900;
    color: #319918;
    margin-left: 15px;
  }
}
.dashmenu {
  height: 12.25vw;
  text-align: center;
  background: #f5445e;
  button {
    color: #fff !important;
    font-size: 4.5vw !important ;
    line-height: 10vw !important;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif !important;

    width: 100%;
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .sidegridcolor {
    margin-top: 2vw !important;
    .sidebar {
      .sidemenubar li {
        font-size: 2.172vw;
        a {
          font-size: 1.2vw;
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .sidebar {
    .log_outbtn {
      margin-top: 7vw;
      margin-bottom: 4vw;
      .darkbtn {
        max-width: 100% !important;
        width: 100% !important;
        height: 12vw !important;
        border-radius: 3.6vw !important;
        font-size: 4vw !important;
        line-height: 5.8vw !important;
        box-shadow: none;
      }
    }
    .sidemenubar {
      .side-bar-height {
        display: flex;
        align-items: center;

        a {
          padding: 0 20px;
        }
      }
    }
  }
  .sidebar {
    .main-head {
      a {
        padding: 0 20px;
      }
    }
  }
  .sidebar .sidemenubar {
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    li {
      padding: 5vw 8.24vw;
      list-style: none;
      font-size: 5vw;
      line-height: 6vw;
      margin-bottom: 0vw;
      border-bottom: 1px solid #d9d9d9;
      a {
        color: #fff;
      }
    }
    .activesidebar {
      padding-bottom: 7vw;
      a {
        color: #fff;
        padding: 0 20px;
      }
    }
  }

  .sidebae_mobile_toggle {
    .MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
      background-color: #1e282c;
      top: 0;
    }
  }
  .hoverimg {
    &::after {
      color: #fff;
    }
  }
}
@media screen and (min-width: 601px) and (max-width: 920px) {
  .sidebar .sidemenubar li {
    list-style: none;
    font-size: 3vw;
    line-height: 4vw;
  }
  .dashmenu {
    height: 9.5vw;
  }
  .dashmenu button {
    line-height: 7vw !important;
  }
}
@media screen and (max-width: 786px) {
  .sidegridcolor {
    .sidebar {
      .sidemenubar {
        display: none;
      }
    }
  }
}
@media only screen and (min-width: 1930px) {
  .sidebar {
    .sidemenubar li {
      padding: 1.76vw 2.5vw;
      font-size: 1.5vw;
      line-height: 2.4vw;
      margin-bottom: 1vw;
    }
  }
}
