.saved-addresses {
  margin-top: 4.48vw;
  .saved-card {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    flex-direction: column;
    margin: 0 0 1.2vw;
    min-width: 180px;
    row-gap: 15px;
    p {
      font-size: 1.2vw;
      margin: 0;
    }
    .card-text {
      text-transform: capitalize;
      margin: 0;
       
    }
    h4 {
      font-size: 1.6vw !important;
    }
    .add-card {
      display: flex;
      gap: 0.5rem;
      background: #f5445e;
      padding: 12px 12px;
      border-radius: 0.8vw;
      width: inherit;
      color: #fff;
      cursor: pointer;
      .plusimg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .activity {
    justify-content: space-between;
    align-items: center;
    .address-details {
      p {
        font-size: 1.2vw;
        color: #000;
      }
    }
    .darkbtn {
      box-shadow: none;
      height: 4vw;
      width: 15vw;
    }
  }
  .details {
    .address-details {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      .address-text {
        display: flex;
        gap: 12px;
        align-items: center;
        img {
          width: 70px;
          height: auto;
        }
      }
      .font14px {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
      .delete-section {
        display: flex;
        row-gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        justify-content: end;
        flex: 1;
        column-gap: 15px;
      }
      .editbtn,
      .trashbtn,
      .markbtn {
        display: flex;
        justify-content: center;
        column-gap: 5px;
        background: #f5445e;
        padding: 10px 15px;
        border-radius: 0.8vw;
        align-items: center;
        color: #fff;
        cursor: pointer;
        width: 100%;
        max-width: 160px;
        min-width: 160px;
        .edit {
          height: 1.5rem;
        }
      }
    }
  }
  .save-cards {
    .saved-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;
    }
  }
}
.search_result_container {
  max-width: 100% !important;
  padding: 0;
}

.MuiGrid-grid-xs-3 {
  background-color: #1e282c;
  margin-top: 1.1vw;
  .sidebar {
    width: inherit;
  }
}

.Add-Address {
  .password {
    .delivryaddpayreview {
      .deladdfildes {
        .popup-field {
          .usecredit {
            svg {
              color: #f5445e;
            }
          }
        }
      }
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.Add-Address {
  .redeem-voucher {
    .voucher-text {
      font-size: 24px;
      padding: 1vw 0 2vw;
    }
  }
  .password {
    .delivryaddpayreview {
      .deladdfildes {
        .MuiFormControl-root {
          width: 100%;
        }
        .MuiOutlinedInput-root {
          border-radius: 1.2vw;
        }
        .popup-field {
          display: flex;
          row-gap: 15px;
          column-gap: 15px;
          flex-wrap: wrap;
          .inputicon {
            position: relative;
            svg {
              position: absolute;
              top: 50%;
              right: 20px;
              transform: translateY(-50%);
              color: #74ce83 !important;
            }
          }
          .name-field,
          .surname-field,
          .address-field,
          .company-field,
          .email-field,
          .number-field {
            width: 39vw;
            max-width: 343.5px;
            min-width: 180px;
          }
          .town-field,
          .post-field,
          .country-field {
            width: 25.2vw;
            max-width: 224px;
            min-width: 150px;
          }
        }
        .saveandcanbtn {
          width: 100%;
          display: flex;
          column-gap: 20px;
          justify-content: center;
          .darkbtn {
            box-shadow: none;
            padding: 0.5rem 1.1rem !important;
            min-width: 150px !important;
            min-height: 50px !important;
          }
          .graybtn {
            box-shadow: none;
            padding: 0.5rem 1.1rem !important;
            min-width: 150px !important;
            min-height: 50px !important;
            color: rgba(0, 0, 0, 0.26) !important;
            font-weight: 500 !important;
          }
          .whitebtn {
            padding: 0.5rem 1.1rem !important;
            min-width: 150px !important;
            min-height: 50px !important;
            font-family: 'Inter', sans-serif !important;
            font-style: normal;
            font-weight: 400;
            text-align: center;
            background: #fffcfc !important;
            border: 1px solid #000000;
            box-shadow: none;
            color: #000;
            letter-spacing: 0px !important;
            text-transform: inherit !important;
          }
        }
      }
    }
  }
  .MuiPaper-root.MuiDialog-paper {
    max-width: 750px !important;
    width: 100% !important;
  }
}

.swal2-popup {
  border-radius: 1.2vw;
  .swal2-confirm {
    background: #f5445e !important;
    border-radius: 1rem !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  .swal2-cancel {
    border-radius: 1rem !important;
    background: #fffcfc !important;
    border: 1px solid #000000 !important;
    color: #000 !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .searchcardbox {
    .artistscard {
      width: 45%;
      .image-section {
        width: 23vw;
        .img_card {
          width: 23vw;
        }
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .filter-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    .selectbox {
      width: 19.68vw;
      .selectboxwidth {
        width: 19.68vw;
        margin: 0;
      }
      .select-field {
        width: 19.68vw;
        border-radius: 0.4vw;
        height: 3.68vw;
      }
      .MuiSelect-select {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 22px;
        font-size: 1.6vw;
      }
      svg {
        color: #000;
        font-size: 2em;
      }
    }
  }
  .searchcardbox {
    .artistscard {
      .image-section {
        .img_card {
          height: 24vw;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .saved-addresses {
    padding: 0 20px;
    .saved-card {
      flex-direction: column;
      margin-bottom: 2.6vw;
      p {
        font-size: 4.2vw;
      }
      .card-text {
        font-size: 8.454vw !important;
      }
      h4 {
        font-size: 4.831vw !important;
      }
    }
    .activity {
      flex-direction: column;
      .address-details {
        p {
          font-size: 4.2vw;
          text-align: center;
        }
      }
      .darkbtn {
        width: 100%;
        height: 12.077vw;
      }
    }
    .details {
      .address-details {
        flex-direction: column;
        .delete-section {
          justify-content: space-evenly;
          width: 100%;
          column-gap: normal;
          padding-top: 12px;
          border-top: 1px solid rgba(0, 0, 0, 0.2);
        }
        .editbtn,
        .trashbtn,
        .markbtn {
          background: transparent;
          width: fit-content;
          min-width: inherit;
          max-width: inherit;
          padding: 0;
          p {
            display: none;
          }
        }
      }
    }
    .save-cards {
      .saved-card {
        display: flex;
        align-items: start;
        flex-direction: column;
        width: 100%;
      }
    }
  }
  .saved-addresses .saved-card .add-card{
    border-radius: 2.415vw;
  }
  .searchcardbox {
    .artistscard {
      .image-section {
        .img_card {
          height: 77vw;
        }
      }
    }
  }

  .Add-Address {
    .password {
      .delivryaddpayreview {
        .deladdfildes {
          .popup-field {
            .name-field,
            .surname-field,
            .address-field,
            .company-field,
            .email-field,
            .number-field,
            .town-field,
            .post-field,
            .country-field {
              width: 100%;
              max-width: none;
              min-width: none;
            }
          }
          .saveandcanbtn {
            flex-direction: column;
            row-gap: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1930px) and (max-width: 4000px) {
  .myaddress {
    .sidegridcolor {
      padding-left: 0 !important;
    }
    .searchcardbox {
      column-gap: 2vw;
      .artistscard {
        .image-section {
          .img_card {
            height: 13vw;
          }
        }
      }
    }
    .search_result_container {
      max-width: 70% !important;
      padding: 0;
      .above4Ksidebar {
        padding-left: 0 !important;
      }
      .above4K {
        max-width: 90% !important;
      }
    }
  }
  .Add-Address {
    .MuiPaper-root.MuiDialog-paper {
      max-width: 1250px !important;
      width: 100% !important;
    }
    .password {
      .delivryaddpayreview {
        .deladdfildes {
          .popup-field {
            label {
              font-size: 1.5rem;
            }
            input {
              height: 2.5rem;
            }
            .name-field,
            .surname-field,
            .address-field,
            .company-field,
            .email-field,
            .number-field {
              max-width: 590px;
            }
            .town-field,
            .post-field,
            .country-field {
              max-width: 388px;
            }
            .usecredit {
              span {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .Add-Address {
    .password {
      .cancel-submit-btn {
        flex-direction: column;
        row-gap: 15px;
        .purchase-btn {
          width: 100%;
          .whitebtn {
            width: 100%;
          }
          .darkbtn {
            width: 100%;
          }
        }
      }
    }
  }
}
