.sitemap {
    margin-bottom: 11.50vw;
    margin-top: 4.32vw;

    .outer-border {
        background: #FFFFFF;
        box-shadow: 0px 0.32vw 3.2vw 0.04vw rgba(0, 0, 0, 0.3);
        border-radius: 1.6vw;
        padding: 2vw 2.8vw 3.2vw;
    }

    .font30px {
        margin-top: 0vw;
        margin-bottom: 3.32vw;
    }

    .heading {
        color: #F5445E;
        text-align: left;
        margin-bottom: 2.24vw;
        margin-top: 0;
    }

    a {
        color: #000;
        text-decoration: none;

        :hover {
            color: #000;
            text-decoration: none;
        }
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            margin-bottom: 0.5vw;
        }
    }
}

@media only screen and (max-width: 600px) {
    .sitemap {
        margin-top: 13.04vw;

        .font30px {
            margin-bottom: 13.04vw;
        }

        .outer-border {
            box-shadow: none;
            border-radius: 0;
            padding: 0;
        }

        .grid-first {
            box-shadow: 0px 0.9661vw 9.66vw 0.120vw rgba(0, 0, 0, 0.3);
            border-radius: 3.6vw;
            padding: 5.797vw 8.212vw 11.111vw !important;
            margin-bottom: 5.314vw;
        }

        ul {
            li {
                margin-bottom: 3vw;
            }
        }
    }
}