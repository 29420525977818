.academy_new_screen {
  margin-bottom: 15vw;

  .line-remove {
    &::before {
      display: none;
    }
  }
  .line-remove.Mui-expanded {
    margin: 0;
  }

  .mainheadart {
    margin: 2.1vw 0;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded {
    box-shadow: 0px 4px 20px 0.5px #0000004d;
    border-radius: 1.6vw;
    padding: 1vw 3vw 1vw 2.5vw;

    .MuiAccordionSummary-content {
      margin: 5px 0;
    }
  }

  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
    margin-top: 10px;
  }

  .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(270deg);
    color: black;
    margin-top: -16px;

    svg {
      width: 1.4em;
      height: 1.4em;
    }
  }

  .overme {
    .MuiAccordionSummary-content {
      p {
        width: 450px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .box_design {
    box-shadow: 0px 4px 15px 0.5px #0000004d;
    border-radius: 1.6vw;
    padding: 2.3vw 4vw 2vw 2.5vw;
    min-height: 6.32vw;
    .unlock_course_btn_new {
      display: flex;
      align-items: center;
      gap: 24px;
      .darkbtn {
        min-height: 1.3vw !important;
        width: 11vw !important;
        font-size: 0.96vw !important;
        border-radius: 1.5vw !important;
        box-shadow: none;
        height: 1.35vw;
      }
    }
  }

  .lock_black {
    width: 1.74vw;
    height: auto;
    cursor: pointer;
  }

  .margin-right-5 {
    width: 1.8vw;
    height: auto;
  }

  .academy_new_btn {
    .darkbtn {
      min-height: 1.3vw !important;
      width: 11vw !important;
      font-size: 0.96vw !important;
      border-radius: 1.5vw !important;
      box-shadow: none;
      height: 1.35vw;
    }

    .checkbal {
      width: 1.75vw;
      height: 1.75vw;
    }
  }
}

.video-library-section {
  /* width */
  .font20pxtypo {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .button_play_pause {
    position: absolute;
    top: 40%;
    left: 45%;

    svg {
      color: #fff;
      cursor: pointer;
      width: 3vw;
      height: 3vw;
    }
  }
  .para_overflow_fixed::-webkit-scrollbar {
    width: 1.2vw;
    background: #afaeae;
    border-radius: 10vw;
  }

  /* Track */
  .para_overflow_fixed ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10vw;
  }

  /* Handle */
  .para_overflow_fixed::-webkit-scrollbar-thumb {
    background: #f5445e;
    border-radius: 10px;
  }
  .iframe_box {
    box-shadow: 0px 4px 40px 0.5px #0000004d;
    padding: 3vw;
    border-radius: 1.6vw;
    margin-bottom: 2vw;

    .video_sec_new {
      height: 30vw !important;
    }

    .react_play_inner {
      width: 100% !important;
      height: fit-content !important;
    }

    iframe {
      height: 29.52vw !important;
      width: 100%;
      body {
        width: 100% !important;
      }
    }

    .para_overflow_fixed {
      height: 44vw;
      overflow: auto;

      p {
        padding-right: 2vw;
      }
    }
  }
  .mark_complete{
    padding: 1.5vw 3.7vw;

    .mark_btn{
      padding: 0;
      text-transform:initial !important;
      font-size: 1.28vw !important;
      line-height: 1.6vw !important; 
      color: #000000 !important;
    }
  }
  .checkbox_new_design{
    svg{
      background-color: #80C86E;
      fill: none;
      border-radius: 5px;
    }
  }
  .checkbox_new_design.Mui-checked{
    svg{
      background-color: #80C86E;
      fill: #80C86E;
      border-radius: 5px;
    }
  }
  .second_box_data {
    padding: 3vw 1vw 3vw 3vw;
  }
  .video_title_text {
    margin-top: 1vw;
    margin-bottom: 0.5vw;
  }

  .next-previous-text {
    margin-bottom: 2vw;

    .darkbtn {
      min-height: 2.32vw !important;
      height: 2.32vw !important;
      box-shadow: none;
      font-size: 1.28vw !important;
      width: 13.84vw !important;
    }
  }

  .lesson_text {
    margin-bottom: 2vw;
  }

  .download_btn_section {
    .darkbtn {
      min-height: 2.32vw !important;
      height: 2.32vw !important;
      width: 13.84vw !important;
      background-color: #595959 !important;
      box-shadow: none;
      border-radius: 0.4vw !important;
      font-size: 1.28vw !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .academy_new_screen,
  .video-library-section {
    padding: 2vw 6vw;

    .mark_complete{
     
      .mark_btn{
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;  
        text-align: left !important;
        justify-content: flex-start;
      }
    }
    .checkbox_new_design{
      svg{
        background-color: #80C86E;
        fill: none;
        border-radius: 5px;
      }
    }

    .button_play_pause {
      position: absolute;
      top: 40%;
      left: 40%;

      svg {
        width: 8vw;
        height: 8vw;
      }
    }
    .overme {
      .MuiAccordionSummary-content {
        p {
          width: 250px;
        }
      }
    }
    .next-previous-text,
    .download_btn_section {
      .darkbtn {
        min-height: 7vw !important;
        height: 7vw !important;
        box-shadow: none;
        font-size: 3.864vw !important;
        width: 41.78vw !important;
        border-radius: 1.6vw !important;
      }
    }
    .next-previous-text {
      .darkbtn {
        border-radius: 3.6vw !important;
      }
    }

    .iframe_box {
      padding: 6vw;
      border-radius: 3.6vw;
      margin-bottom: 6vw;

      .video_sec_new {
        height: 54vw !important;
      }
      .react_play_inner {
        width: 100% !important;
      }

      iframe {
        height: 53vw !important;
      }
    }
   
    .next-previous-text {
      margin-bottom: 7vw;
    }

    .academy_new_btn {
      .checkbal {
        width: 1.5rem;
        height: 1.5rem;
      }

      .darkbtn {
        min-height: 7vw !important;
        height: 7vw !important;
        box-shadow: none;
        font-size: 3.64vw !important;
        width: 30.78vw !important;
        border-radius: 3.6vw !important;
      }
    }

    .box_design {
      border-radius: 3.6vw;
      padding: 6vw;
    }

    .lock_black {
      width: 1.5rem;
      height: 1.8rem;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded {
      border-radius: 3.6vw;
    }
  }
  .academy_new_screen .box_design .unlock_course_btn_new .darkbtn {
    min-height: 7vw !important;
    height: 7vw !important;
    box-shadow: none;
    font-size: 3.64vw !important;
    width: 30.78vw !important;
    border-radius: 3.6vw !important;
  }
}

@media screen and (min-width: 1900px) and (max-width: 6000px) {
  .academy_new_screen {
    .overme .MuiAccordionSummary-content p {
      width: 25vw;
    }
   
  }
}

@media only screen and (min-width: 200px) and (max-width: 330px) {
  .academy_new_screen,
  .video-library-section {
    .overme {
      .MuiAccordionSummary-content {
        p {
          width: fit-content;
        }
      }
    }
  }
}
