.pricing-tool-section {
  margin-top: 1.36vw;
  margin-bottom: 10vw;
  .MuiGrid-grid-xs-3{
    background-color: transparent;
  }
  .slide_data{
    .MuiSlider-mark .MuiSlider-markActive, .MuiSlider-mark{
      background-color: transparent;
    }
    .MuiSlider-track{
      border: 1px solid #80C86E;
      background-color: #80C86E;
      height: 5px;
    }
    .MuiSlider-thumb{
      background-color: #80C86E;
      color: #80C86E;
     
    }
   
  }
  .lastpara_text{
    padding: 0 5vw;
  }
  .MuiGrid-grid-xs-12.MuiGrid-grid-sm-3 {
    padding-left: 0;
    padding-top: 0;
  }
  .MuiSlider-root.MuiSlider-colorSecondary{
    padding: 0 0 6px;
    height: 6px;
  }
  .MuiSlider-rail {
    background-color: #D9D9D9;
  }
  .grid_property_hide{
    margin-top: 0px;
    width: calc(100% + 0px);
    margin-left: 0;

    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8{
      padding-left: 0px;
      padding-top: 0px;
    }
  }
  .btnlast-section{
    .darkbtn{
      width: 100% !important;
      margin-top: 1.5vw;
      font-size: 1.6vw !important;
      box-shadow: none;
      height: 3.36vw !important;
      font-weight: 400 !important;

     
    }
  }
  .colorhead{
    color: #3E3E3E;
    font-weight: 400 !important;
  }
  .colorheadblack{
    color: #000;
    font-weight: 400 !important;
  }
  .max-min-text{
    background-color: #D9D9D9;
    border-radius: 1.2vw;
   padding: 0.2vw ;
  text-align: center;
  font-size: 2.8vw;

  .MuiTypography-root.MuiTypography-h4 { 
    color: #3E3E3E;
  }
  
  }
  .profilesection {
    background: #ffffff;
    box-shadow: 0px 0.32vw 2.2vw 0.016vw rgba(0, 0, 0, 0.3);
    border-radius: 1.6vw;
    padding: 2.08vw 2.24vw;
    margin-bottom: 2.24vw;
    .nameemailsection {
      .greenbtn {
        background-color: transparent !important;
        padding: 0 !important;
      }
    }
 
    .nameemailsection {
      width: 100%;
        .font18px {
          font-size: 1.3vw !important;
          line-height: 1.68vw !important;
      }

      .selectbox .select-field, .selectbox .multi-select-field, .multiselect .select-field, .multiselect .multi-select-field {
        color: #3E3E3E;
        cursor: pointer;
        font-size: 1.6vw;
        line-height: 1.92vw;
        height: 3.36vw;
        border-radius: 0.4vw;
        font-weight: 400 !important;
        text-align: center;

        fieldset{
          border-color: #8C8989
        }
    }
    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{
      min-height: auto !important
    }
        .MuiTextField-root {
          width: 100%;
         
          .MuiOutlinedInput-root {
            border-color: #d9d9d9;
            border-radius: 1.2vw;
            color: #000;
            height: 3vw;
            input {
              color: #3E3E3E;
              text-align: center;
              padding-top: inherit;
              padding-bottom: inherit;
              font-size: 1.4vw;
              line-height: 1.72vw;
              font-weight: 400;
            }
            input::placeholder {
              color: #3E3E3E;
              opacity: 1; /* Firefox */
              font-weight: 400;
              font-family: 'Inter', sans-serif !important;
            }

            input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #3E3E3E;
              font-weight: 400;
            }

            input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #3E3E3E;
              font-weight: 400;
            }
          }
          .MuiOutlinedInput-notchedOutline{
            border-color: #8C8989;
          }
        }
        .darkbtn {
          width: 12.4vw !important;
          height: 2.73vw !important;
          border-radius: 1.2vw !important;
          font-size: 1.6vw !important;
          box-shadow: none;
        }
      }
   
  }


}


@media screen and (max-width: 900px) {
  .sharecard {
    width: 45vw !important;
  }
}

@media screen and (min-width: 1930px) {
  .pricing-tool-section {
    .profilesection {
      .nameemailsection {
        .inptbtn {
          .MuiTextField-root {
            width: 28vw;
          }
          .darkbtn {
            width: 10.4vw !important;
          }
        }
      }
    }
    .requestlinksection .darkbtn {
      width: 22.4vw !important;
    }
  }
}

@media screen and (min-width: 1290px) {
  .pricing-tool-section {
    .shareuri {
      .shareBtn {
        width: 43.4vw !important;
        height: 4vw !important;
        border-radius: 1.2vw !important;
        font-size: 1.6vw !important;
        box-shadow: none;
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .pricing-tool-section {
    .profilesection .nameemailsection .selectbox .select-field{
      font-size: 4.8vw;
      line-height: 7vw;
      height: 10.14vw;
      border-radius: 1.245vw;
      font-weight: 400 !important;
      text-align: center;
      margin-top: 2vw;
      margin-bottom: 2vw;
    }
    .max-min-text {
      border-radius: 3.6vw;;
      font-size: 8vw;
  }
  .btnlast-section .darkbtn {
      font-size: 4.83vw !important;
      box-shadow: none;
      height: 10vw !important;
      font-weight: 400 !important;
  }
.title_data{
  margin-bottom: 35px;
}
.title_subdata{
  margin-bottom: 10px;
}
 .MuiGrid-grid-xs-12.MuiGrid-grid-sm-3{
  margin-bottom: 0;
}
    .profile-checks {
      padding: 8.696vw 4.831vw !important;
      .profile-switch {
        h6 {
          font-size: 4.831vw;
        }
        .MuiFormGroup-root {
          .MuiFormControlLabel-root {
            margin-right: 0;
          }
        }
      }
      .check-profile {
        font-size: 4.831vw;
        margin-bottom: 20px;
      }
    }
    padding: 0 20px;
    .profilesection {
      border-radius: 4.8vw;
      padding: 6.2vw 4.1vw 6.2vw;
      margin-bottom: 6.7vw;
    }
    .profilesection {
      .profilepic {
        justify-content: space-between;
        margin-bottom: 6.28vw;
        align-items: flex-start;
        .img_card {
          width: 36.71vw;
          height: 36.473vw;
          border-radius: 4.8vw;
        }
        .changebtn {
          .darkbtn {
            width: 29.951vw !important;
            height: 12vw !important;
            border-radius: 3.6vw !important;
            font-size: 4.8vw !important;
            line-height: 5.8vw !important;
            margin-bottom: 14.4927vw;
          }
          .canclebtn {
            text-align: right;
          }
        }
      }
      .nameemailsection {
        .font18px {
          font-size: 4.347vw !important;
          line-height: 5.7971vw !important;
      }
        .inptbtn {
          margin-bottom: 3.14vw;
          align-items: center;
          .MuiTextField-root {
            width: 58.69vw;
            margin-right: 3vw;
            .MuiOutlinedInput-root {
              border-radius: 3.6vw;
              input {
                font-size: 4.8vw;
                padding-left: 4.34vw;
                padding-top: 3.1vw;
                padding-bottom: 3.1vw;
              }
            }
          }
          .darkbtn {
            width: 22.7vw !important;
            height: 12vw !important;
            border-radius: 3.6vw !important;
            font-size: 4.8vw !important;
            line-height: 5.8vw !important;
            box-shadow: none;
          }
        }
        .greenbtn {
          .darkbtn {
            background-color: #80c86e !important;
          }
        }
      }
    }

    .requestlinksection {
      align-items: flex-start;
      border-radius: 4.8vw;
      padding: 8.696vw 4.831vw !important;
      margin-bottom: 6.7632vw;
      flex-direction: column;
      align-items: center;
      .font20px {
        width: fit-content;
        margin-top: 0;
      }

      .darkbtn {
        width: 100% !important;
        height: 12vw !important;
        border-radius: 3.6vw !important;
        font-size: 4.8vw !important;
        line-height: 5.8vw !important;
      }
    }
    .artistsec_card {
      display: grid;
      .font20px {
        width: 100%;
        margin-top: 0;
      }
      .darkbtn {
        width: 100% !important;
        height: 12vw !important;
        border-radius: 3.6vw !important;
        font-size: 4.8vw !important;
      }
    }
    .shareuri {
      display: flex;
      flex-direction: column;
      gap: 6.039vw !important;
      padding: 8.696vw 4.831vw !important;
      .textfiledspan {
        .MuiTextField-root {
          .MuiInputBase-root {
            height: 11vw;
            input {
              font-size: 3.28vw;
            }
          }
        }
      }
      .shareBtn {
        max-width: 100% !important;
        width: 100% !important;
        height: 12vw !important;
        border-radius: 3.6vw !important;
        font-size: 4.8vw !important;
        line-height: 5.8vw !important;
      }
    }
    .skip{
      button{
        font-size: 4.831vw !important;
      }
      .darkbtn{
        width: 35vw;
        font-size: 4.831vw !important;
        height: 12vw;
        border-radius: 4.831vw !important;
      }
    }
     .my-account-new {
      margin-bottom: 3vw;
      button {
        min-width: 35.76vw !important;
        height: 8.971vw;
        font-size: 3.5vw !important;
        line-height: 2.34vw !important;
        border-radius: 20px !important;
     }
    }
    .input-upload-section{
      .upload_section, .upload_section_two{
        width: 100%;
 .MuiButtonBase-root.MuiButton-root.MuiButton-outlined{
  margin-bottom: 4.5vw;
 }
      }
      .darkbtn{
        min-width: 31.76vw !important;
        height: 7.971vw !important;
        font-size: 3.5vw !important;
        line-height: 2.34vw !important;
        border-radius: 15px !important;
      }
    } 
     .profilesection{
      .nameemailsection{
        .MuiTextField-root{
          .MuiOutlinedInput-root{
          height: 9.5vw;
          border-radius: 10px;
          input{
            font-size: 3.5vw;
            line-height: 4.5vw;
          }
          }
        } 
        
      } 
      .edit_section_new .darkbtn{
        min-width: 31.76vw !important;
        height: 7.971vw !important;
        font-size: 3.5vw !important;
        line-height: 2.34vw !important;
        border-radius: 10px !important;
      }
     }
  }

  .sharecard {
    width: 85vw !important;
    .shareInput {
      display: flex;
      flex-direction: column;
      input {
        width: 100% !important;
        margin: 1rem 0;
      }
      button {
        width: 100% !important;
      }
    }
  }
  .sharecard {
    .shareInput input {
      font-size: 2.6vw;
    }
    .share-button {
      width: 7vw;
      height: 7vw;
      svg {
        width: 7vw;
        height: 7vw;
      }
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1280px) {
  .sharecard .shareInput input {
    width: 100% !important;
    margin: 1rem 0;
    font-size: 1.3vw;
  }
  .pricing-tool-section .shareuri .shareBtn {
    max-width: 23.561vw;
    font-size: 1.6vw !important;
  }
}
