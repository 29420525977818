.print-pop {
  p {
    text-align: center;
  }
  .MuiPaper-root.MuiDialog-paper {
    width: 43.28vw !important;
    .pop-head {
      height: 4.56vw;
      background: rgb(236, 66, 91);
      .cross-icon {
        position: absolute;
        top: 1vw;
        right: 1vw;
        color: #fff;
        margin: 0;
        padding: 0;
        svg {
          font-size: 2.4vw;
        }
      }
    }
    .box-container {
      padding: 3.6vw 4vw;
      .print-box {
        tr {
          display: flex;
        }
        td {
          width: fit-content;
          display: flex;
        }
        .dispatch-order{
          width: 100%;
          p{
            display: flex;
            font-size: 1.2vw;
          }
        }
        .selectbox {
          width: 100%;
          margin-bottom: 1.2vw;
          .selectboxwidth {
            width: inherit;
          }
          .MuiFormControl-root {
            margin: 0;
            .MuiInputBase-root {
              font-size: 1.6vw;
              height: 4vw;
              .MuiSelect-select {
                padding: 0.8vw 1.6vw 0.8vw 1.6vw;
                display: flex;
                align-items: center;
              }
              fieldset {
                border-radius: 1.6vw;
              }
              svg {
                color: #000;
                font-size: 2.6vw;
              }
            }
          }
        }
        .textfiledspan {
          width: 100%;
          margin-bottom: 1.6vw;
          .MuiFormControl-root {
            width: inherit;
            .MuiInputBase-root {
              height: 4vw;
              border-radius: 1.6vw;
              font-size: 1.6vw;
              padding: 0 1.6vw;
              input{
                &::placeholder{
                  color: #3E3E3E !important;
                  font-family: 'Inter', sans-serif !important;
                }
              }
            }
          }
        }
        .greenbtn{
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .print-pop {
    .MuiPaper-root.MuiDialog-paper {
      width: 70vw !important;
      border-radius: 20px;
      min-width: 80%;
      .pop-head {
        height: 7vw;
        .cross-icon {
          top: 2.2vw;
          right: 3vw;
          svg {
            font-size: 3vw;
          }
        }
      }
      .box-container {
        padding: 2.5vw 3vw;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .print-pop {
    .MuiPaper-root.MuiDialog-paper {
      .pop-head {
        height: 10vw;
        border-radius: 20px 20px 0px 0px;
        .cross-icon {
          position: absolute;
          top: 2.8vw;
          right: 2.8vw;
          svg {
            font-size: 5vw;
          }
        }
      }
      .box-container {
        padding: 3.6vw 4vw;
        .print-box {
          .dispatch-order{
            p{
              font-size: 3.2vw;
            }
          }
          .selectbox {
            width: 100%;
            margin-bottom: 3.2vw;
            .MuiFormControl-root {
              .MuiInputBase-root {
                font-size: 3.8vw;
                height: 12vw;
                .MuiSelect-select {
                  padding: 0 5vw;
                }
                fieldset {
                  border-radius: 3.8vw;
                }
                svg {
                  font-size: 9vw;
                }
              }
            }
          }
          .textfiledspan {
            width: 100%;
            margin-bottom: 3.2vw;
            .MuiFormControl-root {
              width: inherit;
              .MuiInputBase-root {
                height: 12vw;
                border-radius: 3.8vw;
                font-size: 3.8vw;
                padding: 0 2.5vw;
                input{
                  &::placeholder{
                    color: #3E3E3E !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1930px) {
  .print-pop .MuiPaper-root.MuiDialog-paper .pop-head .cross-icon {
    top: 0.5vw;

    svg {
      font-size: 2vw;
    }
  }
}
