.discount-code {
  margin: 3.2vw 0;

  &-heading {
    font-family: 'Inter', sans-serif !important;

    font-size: 2vw !important;
    font-weight: 400 !important;
    line-height: 2.4vw !important;
    color: #000 !important;
  }

  .order-table {
    box-shadow: 0px 10px 40px 0.5px #0000004d;
    border-radius: 20px;
    margin: 1.6rem 0 2rem;

    .order-head {
      background: #939393;

      tr {
        th {
          color: #fff;
          font-size: 1.44vw;
          font-weight: 400;
          font-family: 'Inter', sans-serif !important;

          padding: 10px 16px;
          border-right: 1px solid #939393;
          border-left: 1px solid #939393;

          .graybtn {
            font-size: 1.28vw !important;
            width: 100% !important;
            height: 2.56vw !important;
            min-height: auto !important;
            padding: 0 !important;
            background: #d9d9d9 !important;
            color: #3e3e3e !important;
          }
        }
      }
    }

    .order-body {
      min-height: 100px;
      max-height: 100px;
      height: 100px;

      tr {
        td {
          border: none !important;
          width: 13.76vw;

          .error_text {
            position: absolute;
          }

          .discount-input {
            width: 100%;

            .MuiInputBase-root {
              border: 1px solid #595959;
              border-radius: 0.4vw;

              .MuiInputBase-input {
                height: 2.48vw !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                font-size: 1.12vw;
              }
            }
            .Mui-focused {
              border: none;
            }
          }

          .selectbox {
            .selectboxwidth {
              margin: 0;

              .MuiInputBase-root {
                border: 1px solid #595959;
                border-radius: 0.4vw;
                font-size: 1.12vw;
                line-height: 2.4vw;

                svg {
                  color: #000;
                  font-size: 1.9vw;
                }

                .MuiSelect-select {
                  height: 2.48vw !important;
                  padding-top: 0 !important;
                  padding-bottom: 0 !important;
                }
              }
              .Mui-focused {
                border: none;
              }
            }
          }

          .darkbtn,
          .greenbtn {
            width: 100%;
            box-shadow: none !important;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
            font-size: 1.28vw !important;
            line-height: 1.76vw !important;
          }

          .greenbtn {
            background-color: #80c86e !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .discount-code {
    margin: 5vw 2.5rem;

    &-heading {
      font-size: 25px !important;
      line-height: 30px !important;
    }

    .order-table {
      .order-head {
        tr {
          th {
            font-size: 4.235vw;

            .graybtn {
              font-size: 16px !important;
              width: 100% !important;
              height: 32px !important;
              border-radius: 50px !important;
            }
          }
        }
      }

      .order-body {
        tr {
          td {
            width: 100%;
            min-width: 200px;

            .discount-input {
              .MuiInputBase-root {
                border-radius: 1.8vw;
              }
            }

            .selectbox {
              .selectboxwidth {
                .MuiInputBase-root {
                  border-radius: 1.8vw;
                  .MuiSelect-select {
                    height: 6.998vw !important;
                    font-size: 3.16vw !important;
                    line-height: 5.9vw !important;
                  }

                  svg {
                    font-size: 4vw;
                  }
                }
              }
            }

            .discount-input {
              .MuiInputBase-root {
                .MuiInputBase-input {
                  height: 6.998vw !important;
                  font-size: 3.16vw !important;
                  line-height: 5.9vw !important;
                }
              }
            }

            .darkbtn,
            .greenbtn {
              font-size: 2.833vw !important;
              line-height: 4.002vw !important;
            }
          }
        }
      }
    }
  }
}
