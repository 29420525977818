.profilecardnew{
    .image_section:hover{
        position: relative;
    }
    .image_section:hover .check_icon_heart{
     position: absolute;
     display: block;
     top: 1vw !important;
    width: 2.24vw !important;
    height: 2.24vw !important;
    z-index: 999 !important;
    left: 1.5vw !important;
    }
      .image_section:hover .profile_data {
        display: none;
      }
      .image_section:hover .profilebtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
       

        .graybtn{
            letter-spacing: 0px !important;
            min-height: 2.32vw;
            font-size: 0.96vw !important;
            line-height: 1.15vw !important;
            text-transform: inherit !important;
            background: transparent !important;
            border: 1px solid #000;
            color: #000 !important;
           padding: 0.8vw 1.165vw !important;
           min-width: 8.08vw !important;
          width: 8.08vw !important;
          height: 2.8vw !important;
          border-radius: 2vw !important;
        }
        .darkbtn{
            min-height: 2.32vw;
            font-size: 0.96vw !important;
            line-height: 1.15vw !important;
            box-shadow: none;
            padding: 0.8vw 1.165vw !important; 
            min-width: 8.08vw !important;
            width: 8.08vw !important;
            height: 2.8vw !important;
            border-radius: 2vw !important;
        }
      }
      .check_icon_heart{
        display: none;
      }
      .profilebtn {
        display: none;
        width: 90%;
        margin: 2vw auto 0;
      }
}
@media only screen and (max-width: 600px) {
  .profilecardnew{
.image_section:hover{
  .check_icon_heart{     
display: none;
  }
  .profilebtn{
      margin-top: 8vw;
      margin-bottom: 2.5vw;
      display: none;
      .darkbtn, .graybtn{
          min-width: 29.39vw !important;
          width: 29.39vw !important;
          font-size: 2.898vw !important;
          line-height: 3.5vw !important;
          min-height: 9vw !important;
          height: 9vw !important;
          border-radius: 40px !important;
          
      }
  }
 .profile_data{
  display: block;
 }
}
}
}

@media only screen and (min-width: 1930px) {
  .profilecardnew{
    .image_section:hover .profilebtn{
      width: 94%;
      .darkbtn, .graybtn{
          min-width: 6.4vw !important;
          width: 6.4vw !important;
          padding: 0 1vw !important;
      }
  }
  }
}