.faqpage {
    padding: 8vw 0;
    p{
        color: #000;
    }

    .mainheadfaq {
        margin-top: 0;
        margin-bottom: 3.92vw;
    }

    .MuiPaper-root.MuiAccordion-root:before {

        height: 0px;
    }

    .allsubhead {
        margin-top: 0;
        margin-bottom: 3.920vw;
        color: #000;
    }

    .faqaccord {
        background: #FFFFFF;
        box-shadow: 0px 0.32vw 3.2vw 0.04px rgba(0, 0, 0, 0.3);
        border-radius: 1.6vw !important;
        margin-bottom: 1.92vw;
        padding: 0.8vw 2.4vw;
    }

    .generalfaq,
    .forartistsfaq {
        margin-bottom: 6vw;
    }

    .MuiAccordionDetails-root {
        margin-bottom: 1.6vw;
    }

    .MuiAccordionSummary-expandIconWrapper {
        svg {
            color: #000;
            font-size: 2.2vw;
        }
    }
}

@media only screen and (max-width: 600px) {
    .faqpage {
        .all-screen-container, .css-1oqqzyl-MuiContainer-root {
            max-width: 95% !important;
        }
        .font25px {
            font-size: 5vw !important;
            line-height: 6.2vw !important;
        }
        .mainheadfaq {
            margin-bottom: 11.83vw;
            margin-top: 9.4202vw;
        }

        .allsubhead {
            margin-bottom: 11.83vw;
        }

        .faqaccord {
            border-radius: 4.8vw !important;
            margin-bottom: 5.072vw;
            padding: 5vw 1vw;
        }

     .MuiAccordionSummary-expandIconWrapper svg {
            color: #000;
            font-size: 8vw;
        }
        .forartistsfaq{
            .allsubhead{
                margin-top: 12vw;
            }

        }
        .forbuyersfaq{
            .allsubhead{
                margin-top: 15vw;
            }
        }
    }
}