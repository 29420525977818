//Refundpopup
.refund-popup {
  width: 100%;
  margin: 1vw 0;
  .darkbtn {
    width: 100%;
    box-shadow: none;
    height: 4vw;
  }
}
.refund-popup-box {
  width: 100%;
  .main-title {
    font-size: 1.6vw;
  }
  .pop-content {
    .refund-form {
      p {
        font-size: 1.2vw;
        margin-bottom: 1vw;
        color: #000;
      }
      .selectbox {
        width: 100%;
        margin-bottom: 1vw;
        .selectboxwidth {
          width: inherit;
          margin: 0;
        }
        .MuiFormControl-root {
          .MuiInputBase-root {
            font-size: 1.5rem;
            height: 3.8vw;
            .MuiSelect-select {
              padding: 0 1.2vw;
            }
            fieldset {
              border-radius: 1.6vw !important;
            }
            svg {
              font-size: 2vw;
              color: #000;
            }
          }
        }
      }
      .textfiledspan {
        width: 100%;
        margin-bottom: 1vw;
        .MuiFormControl-root {
          width: 100%;
          .MuiInputBase-root {
            height: 4vw;
            input {
              padding: 0 1.6vw;
              font-size: 1.6vw;
            }
            fieldset {
              border-radius: 1.6vw !important;
            }
          }
        }
      }
      .textareabox {
        border: 1px solid #d9d9d9;
        border-radius: 1.6vw;
        padding: 0.8vw 1.6vw;
        margin-bottom: 1.6vw;
        background: #fffcfc;
        .MuiFormControl-root {
          width: 100%;
          .MuiInputBase-root {
            font-size: 1.2vw;
          }
        }
      }
      .btns {
        display: flex;
        gap: 1.6vw;
        .darkbtn,
        .graybtn {
          width: 100%;
          box-shadow: none;
        }
      }
    }
  }
}

//DisputeCard
.raise-dispute-detials {
  width: 100%;
  .artlovertext {
    font-size: 1.2vw;
  }
  .raise-dispute-headings {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 0.8vw;
    h3 {
      font-size: 1.2vw;
      color: #000;
    }
    h4 {
      font-size: 1.2vw;
      color: #3e3e3e;
    }
    .marked {
      font-size: 1.2vw;
    }
  }
  .dispute-btn {
    .darkbtn {
      width: 100%;
      box-shadow: none;
      height: 4vw;
    }
  }
}

//CancelCard
.cancel-card {
  h2 {
    font-size: 1.6vw;
    font-weight: 500;
  }
  p {
    font-size: 1.2vw;
    color: #000;
    font-weight: 600 !important;
  }
  .canceldata {
    color: #3e3e3e;
    font-weight: normal !important;
  }
}

//RefunCard
.refun-card {
  h2 {
    font-size: 1.6vw;
    font-weight: 500;
  }
  p {
    font-size: 1.2vw;
    color: #000;
  }
  .canceldata {
    color: #3e3e3e;
  }
}

@media screen and (max-width: 600px) {
  //Refundpopup
  .raise-dispute-detials {
    .artlovertext {
      font-size: 3.623vw;
    }
    .raise-dispute-headings {
      margin-top: 2.899vw;
      flex-direction: column;
      align-items: start;
      h3,
      h4 {
        font-size: 4.8vw;
      }
      .marked {
        font-size: 3.623vw;
      }
    }
    .dispute-btn {
      .darkbtn {
        width: 100%;
        box-shadow: none;
        height: 12vw;
        font-size: 3.8vw !important;
        border-radius: 3.8vw !important;
      }
    }
  }

  //Refundpopup
  .refund-popup {
    margin: 2.5vw 0;
    .darkbtn {
      width: 100%;
      box-shadow: none;
      height: 12vw;
      font-size: 3.8vw !important;
      border-radius: 3.8vw !important;
    }
  }
  .refund-popup-box {
    width: 100%;
    .main-title {
      font-size: 3.8vw;
      text-align: center;
    }
    .pop-content {
      .refund-form {
        p {
          font-size: 3.2vw;
          margin-bottom: 3vw;
        }
        .selectbox {
          margin-bottom: 3vw;
          .MuiFormControl-root {
            .MuiInputBase-root {
              font-size: 3.5vw;
              height: 10vw;
              line-height: 2;
              .MuiSelect-select {
                padding: 0 3.5vw;
              }
              fieldset {
                border-radius: 3.6vw !important;
              }
              svg {
                font-size: 5vw;
              }
            }
          }
        }

        .textfiledspan {
          margin-bottom: 3vw;
          .MuiFormControl-root {
            .MuiInputBase-root {
              height: 10vw;
              input {
                padding: 0 3.5vw;
                font-size: 3.6vw;
              }
              fieldset {
                border-radius: 3.6vw !important;
              }
            }
          }
        }

        .textareabox {
          border-radius: 3.6vw;
          padding: 1.8vw 3.6vw;
          margin-bottom: 3.6vw;
          .MuiFormControl-root {
            .MuiInputBase-root {
              font-size: 3.2vw;
            }
          }
        }
        .btns {
          display: flex;
          flex-direction: column;
          gap: 1.6vw;
          .darkbtn,
          .graybtn {
            width: 100%;
            box-shadow: none;
            height: 8vw;
            border-radius: 3.6vw !important;
            font-size: 3.6vw !important;
          }
        }
      }
    }
  }

  //CancelCard
  .cancel-card {
    h2 {
      font-size: 4.6vw;
    }
    p {
      font-size: 4.2vw;
    }
  }

  //RefunCard
  .refun-card {
    h2 {
      font-size: 4.6vw;
    }
    p {
      font-size: 4.2vw;
      color: #000;
    }
  }
}
