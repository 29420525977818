.notifications {
  background: #ffffff;
  box-shadow: 0px 0.32vw 3.2vw 0.04vw rgba(0, 0, 0, 0.3);
  border-radius: 1.6vw;
  display: block;
  padding-bottom: 1.8vw;
  margin-bottom: 5.04vw;
  margin-top: 5.6vw;
  .notiheading {
    padding: 1.28vw 4.4vw 0.8vw 4.4vw;
    background-color: #595959;
    border-top-right-radius: 1.6vw;
    border-top-left-radius: 1.6vw;
    color: #fff;
  }
  .managepad {
    padding-left: 1.8vw;
    padding-right: 1.6vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .showmorebtn {
      font-family: 'Inter', sans-serif !important;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2vw;
      line-height: 1.002vw;
      text-align: center;
      color: #f5445e;
      background: #fff;
      border-radius: 0.9vw;
      padding: 0.9vw 1.165vw;
      letter-spacing: 0px;
      min-height: 2.864vw;
      border: 2px solid #f5445e;
      margin-top: 2vw;
      cursor: pointer;
    }
    .empty-notify-box {
      position: relative;
      height: inherit;
      width: inherit;
      direction: ltr;
      padding-top: 5px;
      .empty-parent {
        position: absolute;
        top: 45%;
        left: 45%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        padding-top: 12px;
        img {
          margin: 0px auto;
          width: 8vw;
          height: auto;
        }
        h3 {
          text-align: center;
          font-size: 1.6vw;
        }
      }
    }
    .paginationselectbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-left: 2vw;
      .MuiPagination-root {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        ul {
          li {
            width: 40px;
            height: 40px;
            margin-right: 12px;
            button {
              width: 100%;
              height: 100%;
              font-size: 1.5rem;
              border-radius: 10px;
              svg {
                color: #000;
                font-size: 3.5rem;
              }
            }
          }
        }
      }
      .selectbox {
        width: 19.68vw;
        .selectboxwidth {
          width: fit-content;
        }
        .MuiFormControl-root {
          .MuiInputBase-root {
            font-size: 1.5rem;
            height: 2.68vw;
            .MuiSelect-select {
              padding: 0.8vw 2.56vw 0.8vw 0.8vw;
            }
            fieldset {
              border-radius: 2.4vw;
            }
          }
        }
      }
    }
  }
  /* width */
  .notifiarea::-webkit-scrollbar {
    width: 1.2vw;
    background: #afaeae;
    border-radius: 10vw;
  }

  /* Track */
  .notifiarea ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10vw;
  }

  /* Handle */
  .notifiarea::-webkit-scrollbar-thumb {
    background: #f5445e;
    border-radius: 10px;
  }
  .notifiarea {
    height: 17.12vw;
    overflow: auto;
    margin-top: 4.64vw;
    padding-left: 2vw;
    direction: rtl;
    width: inherit;

    .notificationbar {
      direction: ltr;
      display: flex;
      align-items: center;
      margin-bottom: 1vw;
      justify-content: space-evenly;
      div {
        display: inline-block;
      }
      .nottext {
        width: 17.6vw;
      }
      .nottime {
        width: 7.5vw;
        margin-right: 1vw;
      }
      .notdate {
        width: 10vw;
      }
      .noticon {
        margin-right: 1.5vw;
        display: flex;
        .img_card {
          width: 2.4vw;
          height: 2.4vw;
          border-radius: 0.8vw;
        }
      }
      .notbutton {
        .darkbtn {
          width: 16.24vw !important;
          height: 3.36vw !important;
          font-size: 1.6vw !important;
          line-height: 1.85vw !important;
          box-shadow: none;
          border-radius: 1.2vw;
        }
      }
    }
  }
}

@media only screen and (min-width: 1930px) {
  .notifications {
    .font20px {
      font-size: 1.1vw;
    }
    .notifiarea {
      .notificationbar .notbutton .darkbtn {
        width: 12.24vw !important;
        height: 3.1vw !important;
        font-size: 1.2vw !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .notifications {
    margin: 13vw 5.2vw 20vw;
    border-radius: 4.8vw;
    .notiheading {
      border-top-right-radius: 4.8vw;
      border-top-left-radius: 4.8vw;
      padding: 3.864vw 9vw 2.415vw;
    }
    .managepad {
      padding-left: 3vw;
      padding-right: 3vw;
      .empty-notify-box {
        .empty-parent {
          img {
            width: 30vw;
          }
          h3 {
            font-size: 4.831vw;
          }
        }
      }
      .showmorebtn {
        font-size: 3.2vw;
        line-height: 4.002vw;
        border-radius: 1.9vw;
        padding: 1.5vw 3.5vw;
        border: 1px solid #f5445e;
        margin: 4vw 0;
      }

      .paginationselectbox {
        flex-direction: column-reverse;
        .selectbox {
          width: fit-content;
          .selectboxwidth {
            width: 100%;
          }
          .MuiFormControl-root {
            .MuiInputBase-root {
              font-size: 1.6vw;
              height: 12.077vw;
              .MuiSelect-select {
                box-sizing: border-box;
                font-size: 1.5rem;
                padding: 0 32px 0 15px;
              }
            }
          }
        }
        .MuiPagination-root {
          ul {
            row-gap: 12px;
            li {
              button {
                width: 40px;
                height: 40px;
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
    .notifiarea::-webkit-scrollbar {
      width: 2.2vw;
    }
    .notifiarea {
      height: 80.371vw;
      overflow: auto;
      margin-top: 4.64vw;
      padding-left: 5vw;
      .notificationbar {
        margin-bottom: 4.3vw;

        .nottext {
          width: 60%;
        }
        .nottime,
        .notdate,
        .noticon {
          display: none !important;
        }
        .notbutton .darkbtn {
          width: 29.24vw !important;
          height: 9.42vw !important;
          font-size: 3vw !important;
          line-height: 5vw !important;
          box-shadow: none;
          border-radius: 3.6vw !important;
          border-radius: 1.2vw;
          margin-left: 2vw;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .notifications .managepad .paginationselectbox .selectbox .MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
    font-size: 6vw;
  }
}
