.sell-dashboard {
  .mainheadart {
    margin-top: 4.48vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.56vw 1.92vw;
    .portfolio_section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    .darkbtn{
      font-size: 0.96vw !important;
      line-height: 1.15vw !important;
      background-color: #afaeae !important;
      min-width: 13.12vw !important;
      box-shadow: none !important;
      color: #000000 !important;
      padding: inherit !important;

    }
  }
  .hello_text {
    margin: 0;
  }
  .color000 {
    color: #000000;
  }

  .flex-row {
    display: flex;
    align-items: center;
    gap: 3%;
  }

  .progress_data {
    display: inline-block;
    padding: 1vw 2vw;
    background: #FFF;
    box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3);
    min-width: 13vw;
    border-radius: 0.8vw;
    max-width: 30.333%;
    width: 100%;
  }
  .glance_text {
    margin-bottom: 1vw;
  }
  .snapshot_para {
    margin-bottom: 1.5vw;
  }
  .box_side {
    background-color: #e2e2e2;
    height: 120px;
    width: 100%;
  }
  .special-offer {
    justify-content: space-between;
    align-items: center;
    border-radius: 1.6vw !important;

    .offer-image {
      display: flex;
      gap: 40px;
      align-items: center;

      img {
        width: 87px;
        height: 87px;
      }
    }

    .darkbtn {
      min-height: 3.36vw;
      height: fit-content;
      box-shadow: none;
      border-radius: 1.2vw !important;
      font-size: 1.4vw !important;
      padding: 0.9vw 2.88vw !important;
    }
  }

  .step-bar {
    flex-direction: column;
    gap: 0 !important;

    .MuiBox-root {
      width: 100% !important;
    }

    .set-list {
      display: flex;
      row-gap: 0.88vw;
      margin-top: 46px;
      padding: 0 5.76vw;
      height: 9.68vw;
      flex-flow: column wrap;

      .check-list {
        display: flex;
        column-gap: 16px;
        align-items: center;

        img {
          width: 32px;
          height: 32px;
          object-fit: contain;
        }

        p {
          font-size: 1.25rem;
        }
      }
    }

    .stepbtn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 41px;

      .greenbtn {
        border-radius: 1.2vw !important;
      }
    }
  }

  .seller-graph {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.08vw;
    align-items: center;

    h5 {
      font-size: 2vw;
      color: #000;
    }

    .selectbox {
      width: 13.04vw;

      .MuiFormControl-root {
        background: #fffcfc;

        .MuiInputBase-root {
          font-size: 1.28vw;

          .MuiSelect-select {
            padding: 0.72vw 2.88vw 0.72vw 1.52vw;
            display: flex;
            align-items: center;
          }

          fieldset {
            border-radius: 0.4vw;
          }

          svg {
            color: #000;
            font-size: 3vw;
            right: 12px;
          }
        }
      }
    }
  }

  .chart-card {
    div {
      width: inherit !important;

      .apexcharts-canvas {
        width: 100% !important;
      }

      .apexcharts-tooltip,
      .apexcharts-xaxistooltip {
        max-width: 260px;
      }
    }
  }

  .artcardarea {
    display: block;
    width: 100%;
    margin-bottom: 5.2vw;
    overflow: hidden;

    .slick-slider {
      .slick-arrow {
        display: none !important;
      }
    }

    .slick-track {
      display: flex;
      column-gap: 2vw;
    }

    .profilecard {
      width: 19.76vw;
      margin: 0 auto;
      vertical-align: top;
      display: inline-block;

      .img_card {
        width: 19.76vw;
        height: 19.76vw;
        margin-left: 0;
        border-radius: 15px;
      }

      .profile_data {
        display: block;
        display: none;

        .profilenameprice {
          margin-top: 1.68vw !important;
          display: flex;
          justify-content: space-between;

          .profilename,
          .profileprice {
            margin: 0 0 0.5vw;
          }
        }
      }

      .titlepriceartcard {
        width: 19.75vw;
        margin-top: 1.6vw;
        display: flex;
        justify-content: space-between;

        p {
          margin: 0;
        }
      }

      .widset {
        width: 17.5vw;
        margin: 0 0 0.2vw;
      }
    }

    .profile_data {
      display: none;
    }

    .profilecardnew .profilebtn {
      display: none;
    }

    .btncenter {
      text-align: center;
      margin-top: 2.5vw;

      .darkbtn {
        width: 27.84vw !important;
        height: 4vw !important;
        border-radius: 1.2vw !important;
        font-size: 1.6vw !important;
        line-height: 1.85vw !important;
        box-shadow: none;
        margin: 0 auto;
      }
    }
  }
}
.ul_inner_tooltip{
  margin: 0;
  margin-bottom: 10px;
  padding-left: 13px;
}
@media screen and (max-width: 1250px) {
  .sell-dashboard {
    .step-bar {
      padding: 41px 16px;

      .set-list {
        row-gap: 0.88vw;
        padding: 0 5.76vw;
        height: 121px;

        .check-list {
          column-gap: 16px;

          p {
            font-size: 1.6vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .sell-dashboard {
    padding: 0 26px;

    .mainheadart {
      display: block;
      padding:9vw  3.76vw;

      .portfolio_section{
        margin-top: 2vw ;
        justify-content: space-between;

        .darkbtn {
          font-size: 2.8vw !important;
          line-height: 3.2vw !important;
          background-color: #afaeae !important;
          min-width: 39.613vw !important;
          min-height: 7.280vw;
        }
      }
  
    }
    .flex-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3%;
      .progress_data {
        display: inline-block;
        padding: 1vw 2vw;
        background: #FFF;
        box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3);
        min-width: 100%;
        width: 100%;
      }
    }

    .artcardarea {
      .profilecard {
        width: 100%;

        .img_card {
          width: 100%;
          height: 45.169vw;
        }

        .widset {
          width: 100%;
        }
      }

      .slick-track {
        column-gap: 4vw;
      }
    }

    .step-bar {
      .set-list {
        row-gap: 11px;
        padding: 0 0 0 12px;
        height: auto;

        .check-list {
          column-gap: 16px;

          p {
            font-size: 1.25rem;
          }
        }
      }

      .stepbtn {
        .greenbtn {
          border-radius: 3.623vw !important;
        }
      }
    }

    .notifications {
      margin: 0;
      margin-bottom: 5vw;
    }

    .chart-card {
      overflow: hidden;
    }

    .seller-graph {
      h5 {
        font-size: 4.831vw;
      }

      .selectbox {
        width: 28.986vw;

        .MuiFormControl-root {
          .MuiInputBase-root {
            font-size: 4.831vw;

            .MuiSelect-select {
              padding: 4.106vw 0 4.106vw 2.415vw;
              font-size: 0.9rem;
              box-sizing: border-box;
            }

            svg {
              font-size: 6vw;
            }

            fieldset {
              border-radius: 2.415vw;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 220px) and (max-width: 675px) {
  .sell-dashboard {
    .special-offer {
      flex-direction: column;
      border-radius: 4.83vw !important;

      .darkbtn {
        width: 100% !important;
        font-size: 1rem !important;
        line-height: 0.8rem !important;
        padding: 1.1rem !important;
        border-radius: 15px !important;
      }

      .offer-image {
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        img {
          width: 60px;
          height: 60px;
        }

        .font20px {
          font-size: 1rem;
          line-height: 1.5rem;
        }
        .color000 {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (min-width: 675px) and (max-width: 781px) {
  .sell-dashboard {
    .special-offer {
      .darkbtn {
        font-size: 1rem !important;
        padding: 1.2vw 3vw !important;
        line-height: 1rem !important;
      }

      .offer-image {
        .font20px {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .sell-dashboard .seller-graph .selectbox .MuiFormControl-root .MuiInputBase-root {
    font-size: 1.5vw;
    line-height: inherit;
  }
}
