.artworkscollection {
  margin-top: 4.48vw;
  .artrightsection {
    .skip-section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 42px;
      .underline {
        color: #f5445e;
        text-decoration: underline;
        background: transparent;
        outline: none;
        border: none;
        font-size: 20px;
        font-family: 'Varela Round';
      }
      .darkbtn {
        box-shadow: none;
        width: 242px;
        height: 50px;
        border-radius: 15px !important;
        font-size: 20px !important;
        color: #fffcfc !important;
      }
    }
    .topsearchdiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 47px;
      .search-container {
        width: 32.16vw;
        position: relative;
        .MuiFormControl-root {
          width: 100%;
          .MuiInputBase-root {
            background: #d9d9d9;
            border-radius: 40px;
            height: 4vw;
            input {
              padding: 17px 64px 17px 26px;
              font-size: 1.28vw;
              &::placeholder {
                color: #000;
                opacity: 1;
                font-family: 'Inter', sans-serif !important;
              }
            }
            fieldset {
              border: 0;
              border-color: transparent;
            }
          }
        }
        .search-icon {
          position: absolute;
          top: 60%;
          right: 20px;
          transform: translateY(-50%);
          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
    .btn-area {
      display: flex;
      justify-content: center;
      width: 100%;
      .btn-group {
        display: flex;
        justify-content: space-around;
        column-gap: 23px;
        .darkbtn {
          width: 11.44vw;
          font-size: 1.6vw !important;
          box-shadow: none;
          border-radius: 1.2vw !important;
          color: #fffcfc !important;
          height: 3.36vw;
        }
        .graybtn {
          width: 11.44vw;
          font-size: 1.6vw !important;
          border-radius: 1.2vw !important;
          color: #fffcfc !important;
          height: 3.36vw;
        }
      }
    }
    .artcardarea {
      display: flex;
      column-gap: 1.4vw;
      flex-wrap: wrap;
      row-gap: 41px;
      margin: 3.4rem 0 2rem;
      .artistscard {
        .image-section {
          width: 19.68vw;
          .imgskeleton {
            width: 19.68vw;
            height: 19.04vw;
          }
        }
      }
      .add-artwork {
        display: flex;
        width: 19.68vw;
        height: 19.04vw;
        border-radius: 20px;
        overflow: hidden;
        background: #d9d9d9;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        row-gap: 2.32vw;
        font-size: 1.6vw;
        color: black;
        .art-img {
          background-image: url('../../assets/images/icons/add-file.webp');
          width: 82px;
          height: 82px;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .art-text {
          position: relative;
          &::after {
            content: 'Add New';
            font-size: 1.6vw;
            color: #000;
          }
        }
      }
      .add-artwork-hover:hover {
        background: #f5445e !important;
        .art-img {
          background-image: url('../../assets/images/icons/premium-badge.svg');
          width: 82px;
          height: 82px;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .art-text-hover {
          position: relative;
          &::after {
            content: 'Upgarde';
            font-size: 1.6vw;
            color: #fff;
          }
        }
      }
      .blogcard {
        width: 19.68vw;
        .image_section {
          position: relative;
          .MuiCheckbox-root {
            position: absolute;
            z-index: 10;
            top: 15px;
            left: 15px;
            width: 30px;
            height: 30px;
            padding: 0;
            color: #afaeae;
            border-radius: 10px;
            svg {
              background: #afaeae;
              width: 30px;
              height: 30px;
              padding: 0;
              border-radius: 10px;
            }
          }
          .Mui-checked {
            color: #f5445e !important;
            .MuiTouchRipple-root {
              border: 5px solid;
            }
            svg {
              background: #fff;
            }
          }
          .img_card {
            display: flex;
            width: 19.68vw;
            height: 19.04vw;
            border-radius: 1.6vw;
            overflow: hidden;
            background: #d9d9d9;
            img {
              height: auto !important;
              object-fit: cover;
            }
            .ellips_icon {
              width: 2.8vw;
              position: absolute;
              z-index: 12;
              right: 2vw;
              bottom: 2vw;
            }
          }
          .blogtitledate {
            display: flex;
            flex-direction: column;
            row-gap: 14px;
            margin-top: 18px;
            align-items: baseline;
            .pricing,
            .quantity {
              display: flex;
              justify-content: center;
              column-gap: 22px;
              color: black;
              padding: 0 1.28vw;
            }
            p {
              margin: 0;
              padding: 0;
              font-size: 1.6vw !important;
            }
            .darkbtn,.whitebtn {
              width: 100%;
              height: 42px;
              border-radius: 10px !important;
              font-size: 1.2vw !important;
              box-shadow: none;
            }
            .whitebtn{
              border:2px solid #f5445e;
            }

          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .artworkscollection {
    padding: 0 20px;
    .artrightsection {
      .topsearchdiv {
        .search-container {
          width: 82.367vw;
          .MuiFormControl-root {
            .MuiInputBase-root {
              height: 12.077vw;
              input {
                font-size: 3.865vw;
                padding: 17px 64px 17px 26px;
              }
            }
          }
          .search-icon {
            top: 50%;
            width: 5.797vw;
            height: 5.797vw;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .btn-area {
        display: flex;
        justify-content: center;
        width: 100%;
        .btn-group {
          column-gap: 3.348vw;
          flex-wrap: wrap;
          row-gap: 4.348vw;
          .darkbtn {
            width: 27.536vw;
            font-size: 3.865vw !important;
            height: 7.971vw;
            border-radius: 3.623vw !important;
          }
          .graybtn {
            width: 27.536vw;
            font-size: 3.865vw !important;
            height: 7.971vw;
            border-radius: 3.623vw !important;
          }
        }
      }
      .artcardarea {
        flex-direction: column;
        align-items: center;
        height: auto;
        .add-artwork {
          width: 73.188vw;
          height: 70.531vw;
          font-size: 4.831vw;
          row-gap: 5.32vw;
          .art-text {
            &::after {
              content: 'Add New';
              font-size: 4.8vw;
            }
          }
        }
        .add-artwork-hover {
          background: #f5445e !important;
          .art-img {
            background-image: url('../../assets/images/icons/premium-badge.svg');
            width: 82px;
            height: 82px;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .art-text-hover {
            position: relative;
            &::after {
              content: 'Upgarde';
              font-size: 4.8vw;
              color: #fff;
            }
          }
        }
        .blogcard {
          width: 73.188vw;
          height: 92.995vw;
          .image_section {
            width: 73.188vw;
            height: 70.531vw;
            .img_card {
              width: 100%;
              height: 70.531vw;
              border-radius: 4.831vw;
              .ellips_icon {
                width: 8.5vw;
                right: 2vw;
                bottom: 22vw;
              }
              .darkbtn,.whitebtn {
                position: absolute;
                width: 65.217vw;
                left: 50%;
                bottom: 15px;
                transform: translateX(-50%);
                height: 52px;
                box-shadow: none;
                border-radius: 15px !important;
                font-size: 20px !important;
              }
              .whitebtn{
                border:2px solid;
                background-color: #FFF;
              }
            }
            .blogtitledate {
              .pricing,
              .quantity {
                column-gap: 35px;
              }
              p {
                font-size: 20px !important;
              }
              .darkbtn {
                display: none;
              }
              .whitebtn{
                font-size: 4.8vw !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1930px) and (max-width: 6000px) {
  .artworkscollection {
    .artrightsection {
      .artcardarea {
        .add-artwork {
          border-radius: 1.6vw;
        }
      }
      .topsearchdiv {
        .search-container {
          .MuiFormControl-root .MuiInputBase-root {
            border-radius: 3.2vw;
            input {
              padding: 1.28vw 2vw 1.28vw 2.08vw;
            }
          }
          .search-icon {
            top: 55%;
            img {
              height: 1.8vw;
              width: 1.8vw;
              margin-right: 0.5vw;
            }
          }
        }
      }
    }
  }
}
