.invoice {
  .orderinvoice {
    padding-bottom: 130px;
    padding-top: 130px;
    .btn-section {
      display: flex;
      column-gap: 20px;
      .darkbtn,
      .whitebtn {
        box-shadow: none;
        font-size: 20px !important;
        padding: 0 24px;
        border-radius: 15px !important;
        height: 50px;
      }
      .whitebtn {
        border: 2px solid #ec425b;
      }
    }
    .salesinvoice {
      padding: 0 20px;
      .sales-name-section {
        border-bottom: 2px solid #ccc;
        display: flex;
        justify-content: space-between;
        margin: 30px 0 20px;
        padding-bottom: 20px;
        .sales-name {
          color: #333;
          font-size: 35px;
          line-height: 40px;
        }
      }
      .sub-heading {
        font-size: 30px;
        line-height: 40px;
      }
      p {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
  .order-summary-heading {
    background: #ec425b;
    color: #fff;
    font-size: 30px;
    padding: 20px 25px;
  }
  .MuiTableContainer-root {
    margin-top: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }
  .sellerfooter {
    height: 156px;
    padding-top: 40px;
    .footer-section {
      align-items: center;
      display: flex;
      justify-content: space-between;
      img {
        width: 250px;
        height: auto;
      }
    }
    .link-section,
    .icon-section {
      display: flex;
      .privacy-link {
        color: #2f2b23;
        cursor: pointer;
        font-size: 20px;
        margin-left: 32px;
        padding-left: 14px;
        text-decoration: none;
        &:hover {
          color: #ec425b;
        }
      }
    }
    .icon-section {
      .icon-img {
        cursor: pointer;
        height: 22px;
        margin-left: 28px;
        width: 22px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .invoice {
    .order-summary-heading {
      width: 700px;
    }
    .sellerfooter {
      height: auto;
      .footer-section {
        flex-direction: column;
        .link-section {
          .privacy-link {
            margin: 0;
            padding: 0;
          }
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .invoice {
    .orderinvoice {
      padding-bottom: 50px;
      padding-top: 50px;
      .btn-section {
        column-gap: 20px;
        .darkbtn,
        .whitebtn {
          font-size: 16px !important;
          padding: 0 24px;
          width: 100%;
        }
      }
      .salesinvoice {
        padding: 0;
        .sales-name-section {
          .sales-name {
            font-size: 20px;
            line-height: 28px;
          }
        }
        .sub-heading {
          font-size: 20px;
          line-height: 28px;
        }
        p {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .order-summary-heading {
      font-size: 20px;
      padding: 15px 20px;
    }
    .sellerfooter {
      .footer-section {
        img {
          width: 150px;
          height: auto;
          margin-bottom: 15px;
        }
        .link-section {
          .privacy-link {
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
      }
      .icon-section {
        gap: 20px;
        .icon-img {
          cursor: pointer;
          height: 22px;
          margin-left: 0;
          width: 22px;
        }
      }
    }
  }
}
