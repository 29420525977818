.searchcardbox {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2.604vw;
  column-gap: 2.604vw;
  margin-bottom: 5.5rem;
  margin-top: 4.48vw;
}

@media screen and (max-width: 700px) {
  .searchcardbox {
    padding: 0 20px;
    row-gap: 2.25rem;
  }
}
