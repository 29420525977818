.statement_dashboard {
  .artist_statement_text {
    margin-bottom: 2vw;
    margin-top: 3vw;
  }
  .darkbtn,
  .graybtn {
    min-width: 12.61vw !important;
    min-height: 2.9vw;
    font-size: 0.9vw !important;
    line-height: 1.2vw !important;
    box-shadow: none;
  }
  .edit_disable{
    pointer-events: none;
    svg{
      color: #000 ;
    }
  }
  .ql-container.ql-snow {
    border: 1px solid #ccc;
    min-height: 200px;
  }
  .text-editor {
    margin-bottom: 2vw;
    width: 100%;
  }

  .textareabox {
    border: 1px solid #c9c3c3;
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 1vw;
    height: 8.5vw;
    width: 100%;
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputMultiline {
      height: 7vw !important;
      overflow: auto;
      padding-right: 5px;
    }
    ::-webkit-scrollbar {
      background: #b3aeae;
      border-radius: 10px;
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #857d7d;
    }
    .MuiFormControl-root.MuiTextField-root,
    .MuiInputBase-input.MuiInput-input {
      width: 100%;
    }
  }
  .error_textareabox {
    margin-bottom: 1.5vw;
  }
  .button_section {
    padding: 1.5vw;
    margin-bottom: 2vw;
    display: block;

    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }
  }
  .button_section_new {
    padding: 1.5vw;
    margin-bottom: 2vw;
  }
  .short_text {
    margin-bottom: 1vw;
  }
  .studio_link_text{
    font-weight: 600 !important;
  }
}

.cv-builder {
  .artist_statement_text {
    margin: 0;
  }
  .cv_btn_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vw;
    margin-top: 3vw;
  }
  .preview_btn_sec {
    width: fit-content;
    margin-bottom: 0;
  }
  .selectbox {
    min-width: 18vw;
    .MuiFormControl-root.selectboxwidth {
      margin: 0;
      .MuiSelect-select.MuiInputBase-input {
        padding: 8.5px 14px;
      }
    }
  }
  .selectbox_new{

width: 15vw; 
 }
  .MuiFormControl-root.MuiFormControl-marginNormal {
    min-width: 15vw;
    margin-bottom: 0;
    .MuiInputBase-input.MuiOutlinedInput-input {
      padding: 8.5px 14px;
    }
  }
  .fulltime_text {
    margin-bottom: 0.5vw;
  }
  .textfiledspan {
    width: 17vw;
    .MuiFormControl-root {
      width: 100%;
      .MuiInputBase-root.MuiOutlinedInput-root{


        input{
          padding: 8.5px 14px;
        }
      }
    }
  }

  .textareabox {
    border: 1px solid #c9c3c3;
    padding: 5px 10px;
    border-radius: 0.4vw;
    height: 18vw;
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputMultiline {
      height: 17vw !important;
      overflow: auto;
      padding-right: 5px;
    }
    ::-webkit-scrollbar {
      background: #b3aeae;
      border-radius: 10px;
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #857d7d;
    }
    .MuiFormControl-root.MuiTextField-root,
    .MuiInputBase-input.MuiInput-input {
      width: 100%;
    }
  }
  .box_field_width {
    min-width: 220px;
    margin-left: 0;
  }
}
.cv_builder_table {
  .MuiTableRow-root {
    vertical-align: top;
  }
  .MuiTable-root {
    min-width: 600px;
  }
  box-shadow: none !important;
  background-color: transparent !important;

  .MuiTableCell-root {
    padding: 5px 8px;
    font-size: 0.75rem;
    font-family: 'Inter', sans-serif !important;

    border-bottom: none;
  }
  .table_edit_btn, .table_save_btn,  .greenbtn {
    background-color: #EC425B !important;
    min-width: 6.16vw !important;
    min-height: 2.16vw;
    padding: 6px 8px !important;
    border-radius: 0.8vw !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font-size: 0.875rem !important;
    line-height: 1.75rem !important;
    font-family: 'Inter', sans-serif !important;

  }
  .greenbtn {
    background-color: #80C86E !important ;
  }
  .table_add_btn {
    background-color: #EC425B !important;
    min-width: 10vw !important;
    border-radius: 0.8vw !important;
    min-height: 2.16vw;
    padding: 8px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;

  }
}

@media screen and (max-width: 600px) {
  .cv_builder_new_table{
    overflow: auto;
    .MuiInputBase-root {
      min-width: 42.99vw;
      width: 42.99vw;
  }
  .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-spacing-xs-1 {
    flex-wrap: nowrap;
  }
  button, .darkbtn, .graybtn{
    min-width: 42vw !important;
    height: 9vw;
    border-radius: 0.8vw !important;
    padding: 16px 11px !important;
    font-size: 3.86vw !important;
    line-height: 4vw !important;
    border-radius: 2.415vw !important;

  }
  } 
  .statement_dashboard {
    padding: 0 20px;
    .card-container {
      padding: 1.25rem 1rem !important;
    }
    .button_section_new {
      padding: 3.5vw;
      margin-bottom: 5vw;
    }
    .textareabox {
      height: 32vw;
    }
    .artist_statement_text {
      margin-bottom: 4vw;
      margin-top: 6vw;
    }
    .textareabox {
      .MuiFormControl-root.MuiTextField-root,
      .MuiInputBase-input.MuiInput-input {
        font-size: 2.898vw;
        line-height: 3.5vw;
      }
    }
    .text-editor {
      margin-bottom: 5vw;
    }
    .darkbtn,
    .graybtn {
      font-size: 4.347vw !important;
      line-height: 5.7971vw !important;
      min-height: 10.9vw;
      width: 100%;
      border-radius: 2.415vw !important;
    }
    .font12px {
      font-size: 4vw;
      line-height: 5.7971vw;
      margin-bottom: 2vw;
    }
    .font20px {
      font-size: 6.0386vw;
    }
    .short_text {
      margin-bottom: 5vw;
      margin-top: 5vw;
    }
    .button_section {
      padding: 3.5vw;
      margin-bottom: 4vw;
    }
    .short_text {
      margin-bottom: 2.5vw;
    }
  }
  .cv-builder {
    .cv_btn_section {
      display: block;
      margin-bottom: 5vw;
      margin-top: 6vw;
    }
    .artist_statement_text {
      margin: 0;
      margin-bottom: 5vw;
    }
    .preview_btn_sec {
      width: 100%;
      margin-bottom: 0;
    }
    .darkbtn,
    .graybtn {
      font-size: 4.347vw !important;
      line-height: 5.7971vw !important;
      min-height: 10.9vw;
      width: 100%;
      border-radius: 2.415vw !important;
    }
    .font12px {
      font-size: 4.347vw;
      line-height: 5.7971vw;
      margin-bottom: 2vw;
    }
    .short_text {
      margin-bottom: 5vw;
      margin-top: 5vw;
    }
    .font20px {
      font-size: 6.0386vw;
    }
    .textareabox {
      height: 55vw;
      .MuiInputBase-input.MuiInput-input.MuiInputBase-inputMultiline {
        height: 50vw !important;
      }
      .MuiFormControl-root.MuiTextField-root,
      .MuiInputBase-input.MuiInput-input {
        font-size: 4.347vw;
        line-height: 5.7971vw;
      }
    }
    .textfiledspan {
     min-width: 32vw;
     width: 100%;
    }
 .selectbox_new {
      width: 25vw;
  }
  }
  .cv_builder_table {
    .table_edit_btn,
    .table_add_btn, .table_save_btn {
      min-width: 6.16vw !important;
      min-height: 2.16vw;
      width: 18.59vw;
      font-size: 2.8vw !important;
      line-height: 3.9vw !important;
      border-radius: 2.415vw !important;
    }
    .table_add_btn {
      width: 30.193vw;
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .statement_dashboard {
    .font20px {
      font-size: 2.5vw;
      line-height: 3.5vw;
    }
    .font12px {
      font-size: 1.5vw;
      line-height: 2vw;
    }
    .darkbtn {
      font-size: 1.2vw !important;
      line-height: 1.6vw !important;
    }
  }
  .cv_builder_table {
    .table_edit_btn,
    .table_add_btn {
      font-size: 1.2vw !important;
      line-height: 1.6vw !important;
    }
  }
}

@media screen and (min-width: 901px) and (max-width: 1150px) {
  .cv-builder {
    .selectbox {
      min-width: 17vw;
    }
    .MuiFormControl-root.MuiFormControl-marginNormal {
      min-width: 17vw;
    }
    .box_field_width {
      min-width: 17vw;
    }
  }
}
