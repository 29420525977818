.retrun-policy {

  h2,
  h4 {
    font-size: 1.6vw;
    margin-bottom: 1.6vw;
    color: #000;
  }

  p {
    font-size: 1.2vw;
    margin-bottom: 1.2vw;
  }

  ul,
  ol {
    li {
      font-size: 1.2vw;
      color: #3b3b3b;
    }
  }

  p {
    color: #3b3b3b;
  }

  .first-section {
    margin: 4vw 0;
    text-align: left;

    .darkbtn {
      text-transform: none !important;
      padding: 0.9vw 2.165vw !important;
      min-height: 4vw;
      font-weight: 400 !important;
      border-radius: 10vw !important;
      box-shadow: none;
    }
  }

  .dispute-resolution {
    margin-bottom: 5vw;
  }

 
}

@media screen and (max-width: 600px) {
  .retrun-policy {
    h2 {
      font-size: 4.8vw;
      margin-bottom: 3.5vw;
    }

    h4,
    p {
      font-size: 3.2vw;
      margin-bottom: 3.2vw;
    }

    ul,
    ol {
      li {
        font-size: 3.2vw;
      }
    }

    .first-section {
      h2{
        font-size: 5.5vw;
      }
      p{
        font-size: 4.5vw;
      }
      .darkbtn {
        padding: 0.9vw 4.165vw !important;
        min-height: 8vw;
        border-radius: 14vw !important;
        font-size: 5.833vw !important;
        line-height: 8.002vw !important;
      }
    }

    .dispute-resolution {
      margin-bottom: 9vw;
    }
  }
}