.support-contact {
  margin-top: 4.48vw;
  .loginpage {
    .maintitle {
      text-align: center;
      font-size: 3.2vw;
      line-height: 3.84vw;
      margin-bottom: 1.5vw;
    }
    .card-container {
      margin-bottom: 5rem;
      padding: 0 6.72vw !important;
    }
    .login_box {
      margin-top: 0;
      padding: 0;
      background: transparent;
      width: 100%;
      text-align: center;
      .contactfiled {
        margin-top: 3vw;
        display: flex;
        width: 45.68vw;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        .textfiledspan,
        .textareabox {
          .MuiTextField-root {
            width: 32.48vw;
            .MuiOutlinedInput-root {
              border-radius: 0.4vw;
              background: #fffcfc;
              border: 1px solid #d9d9d9;
              height: 4vw;
            }
          }
          input {
            height: 1.6vw;
            font-size: 1.5vw;
            &::placeholder {
              color: #d9d9d9;
              font-size: 1.5vw;
              font-family: 'Inter', sans-serif !important;
            }
          }
        }
        .selectbox {
          .selectboxwidth {
            margin: 0.2vw 0 1vw;
            .select-field {
              height: 4vw;
              .MuiSelect-select {
                font-size: 1.5vw;
                line-height: 4vw;
              }
            }
            fieldset {
              border-radius: 0.4vw;
            }
            svg {
              font-size: 2.5rem;
              color: #000;
            }
          }
        }
      }
      .toptext {
        text-align: center;
      }
      h2 {
        margin-top: 3.92vw;
        font-size: 2.4vw;
        margin-bottom: 2.64vw;
      }
      h3 {
        font-size: 2vw;
      }
      p {
        width: 45.68vw;
        margin: 0 auto;
        text-align: justify !important;
      }

      .darkbtn {
        width: 12.16vw !important;
        height: 4vw !important;
        font-size: 1.6vw !important;
        border-radius: 1.2vw !important;
        box-shadow: none;
      }

      .textareabox {
        .MuiTextField-root {
          width: 100%;
        }
        .MuiInput-underline {
          border: 1px solid #c4c4c4;
          border-radius: 0.4vw;
          min-height: 151px;
          padding: 1.5vw 1.5vw;
          font-size: 1.5vw;
        }
        .MuiInput-underline:before {
          content: none;
        }
      }
    }
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #d9d9d9 !important;
    font-size: 1.6vw !important;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #d9d9d9 !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #d9d9d9 !important;
  }
}
.support-contact {
  .all-screen-container,
  .css-1oqqzyl-MuiContainer-root {
    max-width: 100% !important;
    padding: 0;
  }
  .loginpage {
    .card-container {
      padding: 0 4vw !important;
      background: #ffffff;
      box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3);
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
    .login_box {
      p {
        width: 100%;
        text-align: center !important;
        margin-bottom: 3vw;
      }
      .contactfiled {
        margin-top: 3vw;
        width: 100%;
        text-align: left;
        flex-direction: column;
        .textareabox,
        .textfiledspan {
          margin-bottom: 1vw;
          margin-top: 0.2vw;
          .MuiTextField-root {
            width: 100%;
          }
        }
        .submitbtn {
          text-align: center;
          .darkbtn {
            box-shadow: none;
            font-size: 1.6vw !important;
            height: 4vw !important;
            width: 27.84vw !important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1280px) {
  .support-contact {
    .loginpage {
      .login_box {
        .contactfiled {
          .selectbox {
            .selectboxwidth {
              svg {
                font-size: 2.5vw;
              }
              .select-field {
                height: 4.2vw;
                .MuiSelect-select {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .support-contact {
    .loginpage {
      padding: 0 24px;
      .card-container {
        padding-top: 4vw !important;
        padding-bottom: 5vw !important;
      }
      .maintitle {
        text-align: center;
        font-size: 8.66vw;
        line-height: 11.59vw;
        margin-bottom: 1.5vw;
      }
      .css-1oqqzyl-MuiContainer-root {
        max-width: 361px !important;
        min-width: 280px !important;
        width: 100%;
      }
      .login_box {
        h2 {
          margin-top: 8.92vw;
          font-size: 6.4vw;
        }
        h3 {
          font-size: 5.8vw;
        }
        p {
          font-size: 4.831vw;
          width: 100%;
        }
        .textareabox {
          textarea{
            height: 286px !important;
            line-height: 4.5vw;
            font-size: 3.6vw !important;
          }
          .MuiInput-underline {
            border-radius: 1.245vw;
            padding: 1rem;
            align-items: normal;
            min-height: 286px;
          }
        }
        .submitbtn {
          .darkbtn {
            width: 100% !important;
            height: auto !important;
            font-size: 1.5rem !important;
            padding: 20px 54px !important;
          }
        }
        .contactfiled {
          margin-top: 5.942vw;
          flex-direction: column;
          width: 100%;
          .textfiledspan {
            margin-bottom: 1.2rem;
            margin-top: 0.3rem;
            .MuiFormControl-root {
              width: 100%;
              .MuiInputBase-root {
                height: 12.077vw;
              }
            }
            input {
              line-height: 4vw;
              font-size: 4vw;
              height: 4.8vw;
            }
            .textareabox input {
              line-height: 4vw;
              font-size: 4vw;
            }
            .MuiTextField-root {
              .MuiOutlinedInput-root {
                border-radius: 1.245vw;
              }
            }
          }
          .darkbtn {
            width: 100% !important;
            height: 12.077vw !important;
            font-size: 4.831vw !important;
          }
          .selectbox {
            margin-bottom: 1.2rem;
            margin-top: 0.3rem;
            .selectboxwidth {
              fieldset {
                border-radius: 1.245vw;
              }
              .select-field {
                height: 13vw;
                .MuiInputBase-input {
                  padding: 10px;
                  font-size: 4vw;
                  line-height: 6vw;
                }
              }
            }
          }
        }
      }
    }
    ::placeholder {
      font-size: 3.623vw !important;
    }
  }
  .support-contact {
    .loginpage {
      .login_box {
        padding: 0;
        .submitbtn {
          width: 100%;
          margin: 12vw auto 0;
        }
      }
      .card-container {
        box-shadow: none;
      }
    }
  }
  .support-contact .loginpage .login_box .contactfiled .submitbtn .darkbtn {
    border-radius: 0.9rem !important;
    font-size: 1.5rem !important;
    height: auto !important;
    padding: 20px 54px !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 1930px) {
  .support-contact {
    .loginpage {
      .login_box {
        .contactfiled {
          .textfiledspan input {
            height: 2.5vw;
            padding-left: 1vw;
          }
          .textareabox input {
            height: 2.5vw;
            padding-left: 1vw;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1900px) and (max-width: 4000px) {
  .support-contact {
    .loginpage {
      .login_box {
        .contactfiled {
          .textfiledspan {
            .MuiTextField-root {
              .MuiOutlinedInput-root {
                font-size: 1.2vw;
              }
            }
          }
          .selectbox {
            .selectboxwidth {
              .select-field {
                .MuiSelect-select {
                  font-size: 1.2vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
