.main-head {
  width: 100%;
  padding: 0.5vw 3.24vw !important;
  height: 4vw;
}
.MuiAccordion-rounded.Mui-expanded {
  .MuiAccordionDetails-root.subheading {
    a:hover {
      text-decoration: underline !important;
    }
  }
}
.subheading {
  display: flex;
  padding: 0.5vw 2.24vw 0 4.5vw !important;
  align-items: center;
  height: 3vw;
}

.new-sub-link {
  width: 100%;

  text-decoration: underline !important;
}

@media screen and (max-width: 900px) {
  .main-head {
    padding: 5vw 8.24vw !important;
    height: 60px;
  }

  .subheading {
    padding: 5vw 8.24vw 5vw 11vw !important;
    height: 60px;
  }
  .new-sub-link {
    border-radius: 10px;
  }
}
