.message-section {
  .card-container {
    grid-template-columns: auto 2fr;
    gap: 25px;
    display: grid;
    .scroll-dir {
      direction: rtl;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 1.2vw;
        background: #AFAEAE;
        border-radius: 25vw;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 25vw;
      }
      &::-webkit-scrollbar-thumb {
        background: #F5445E;
        border-radius: 25vw;
      }
      .message-preview {
        width: fit-content;
        height: auto;
        margin-left: 12px;
        direction: ltr;
      }
      .empty-container {
        width: fit-content;
        margin-left: 12px;
        direction: ltr;
        display: flex;
        height: 100%;
        .empty-channel {
          display: flex;
          width: clamp(100px, 17vw, 320px);
          height: auto;
          flex-direction: column;
          gap: 50px;
          margin-top: 1.5rem;
          justify-content: center;
          h3 {
            font-size: 1.5rem;
            text-align: center;
          }
          .darkbtn {
            font-size: clamp(0.7rem, 1vw, 1.5rem) !important;
            box-shadow: none;
            border-radius: 1rem !important;
            padding: 1.5rem 0 !important;
          }
        }
      }
    }
  }
  .message_gray_background{
    min-width: 24.44vw;
    padding: 1.6vw;
    box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3);
    border-radius: 0.8vw;
  }
  .message_flex_section{
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }
  .searchbar_message{
    ::placeholder{
      color: #000000E5;
      opacity: 1;
      font-family: 'Inter', sans-serif !important;
    }
  }
  .search_bar_section{
    margin-bottom: 1.04vw;
    padding: 0.64vw 1.36vw;
    box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3);
    border-radius: 0.8vw;
    .MuiFormControl-root {
      background-color: #E2E2E299;
      border-radius: 50px;
.MuiInputBase-root{
  height: 3.04vw;
}
      fieldset{
        border-width: 0;
      }
    }
  }
  .send_mesage-side{
    width: 100%;
  }
  .Empty-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-shadow: inset 8px 0 9px -10px rgba(0, 0, 0, 0.4);
    flex-direction: column;
    gap: 45px;
    img {
      width: 100%;
      max-width: clamp(87px, 11vw, 120px);
      max-height: clamp(87px, 11vw, 120px);
    }
    h3 {
      text-align: center;
    }
  }
  .message-card-id {
    grid-template-columns: auto;
    .full-width {
      width: 100%;
      max-width: inherit;
      min-width: inherit;
    }
    .scroll-dir {
      .messagesbox {
        .message-preview-box {
          background: #FFFFFF;
          border: 0.2px solid #000000;
          border-radius: 15px;
          padding: 25px 21px;
          position: relative;
          direction: initial;
          margin-left: 25px;
          margin-bottom: 25px;
        }
      }
    }
    .form-container {
      width: 100%;
      .id-form {
        display: flex;
        flex-direction: column;
        gap: 26px;
        textarea {
          border-radius: 15px;
          height: 200px;
          margin-top: 20px;
          padding: 21px;
        }
        .darkbtn {
          padding: 13px 0 20px !important;
          border-radius: 15px !important;
          box-shadow: none !important;
        }
        .whitebtn {
          padding: 13px 0 20px !important;
          border-radius: 15px !important;
          box-shadow: none !important;
          font-size: 1rem !important;
        }
      }
    }
  }
}
.allmessage {
  margin-top: 4.48vw;
}
@media only screen and (max-width: 600px) {
  .allmessage {
    padding: 0 20px;
    overflow-x: hidden;
    .all-screen-container,
    .css-1oqqzyl-MuiContainer-root {
      max-width: 100% !important;
    }
    .searchbtninput {
      margin-bottom: 10px;
      .font25px {
        font-size: 30px !important;
        line-height: 40px !important;
        margin-bottom: 6.52vw;
      }
    }
  }
  .allmessage {
    .text-area {
      resize: none;
      border: 1px solid #D9D9D9;
      &::placeholder {
        color: #000;
        font-size: 20px;
        font-family: 'Inter', sans-serif !important;
      }
    }
  }
  .message-section {
    .card-container.message-card-id{
      padding: 1.25rem 1rem !important;
     
    }
    .card-container.message-card-id.guest_card_id{
      height: 620px !important;
    }
   .card-container.message-card-id.normal_card_id{
    height: 820px !important;
   }
    .send_mesage-side{
      margin-top: 10vw;
    }
    .message_gray_background{
      padding: 4vw;
    }
    .message_flex_section{
      display: block;
      margin-bottom: 20px;
    }
     .search_bar_section {
      margin-bottom: 13px;
      padding: 8px 10px;
      box-shadow: none;
      .MuiFormControl-root .MuiInputBase-root {
        height: 38px;
    }
  }
    .Empty-msg {
      display: none;
    }
    .card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 3.5rem;
      .scroll-dir {
        .empty-container {
          margin: 0;
          .empty-channel {
            width: fit-content;
            .darkbtn {
              font-size: 1.5rem !important;
              box-shadow: none;
              border-radius: 1rem !important;
            }
          }
        }
      }
      .message-card {
        direction: ltr;
        width: 100%;
        max-width: inherit;
        min-width: inherit;
      }
      .messagesbox {
        margin-left: 12px;
        direction: ltr;
        gap: 12px;
        display: flex;
        flex-direction: column;
        height: 252px;
        .msg_avatar{
          display: flex;
          gap: 10px;
        }
        .message-preview-box {
          background: #FFFFFF;
          border: 0.2px solid #000000;
          border-radius: 15px;
          padding: 25px 21px;
          position: relative;
          .date-text {
            font-size: 5vw;
            line-height: 6vw;
            margin: 0;
          }
        }
      }
      .mobileForm {
        width: 100%;
        .form-container {
          width: 100%;
          .form-box {
            display: flex;
            flex-direction: column;
            gap: 12px;
            textarea {
              border-radius: 12px;
              padding: 12px;
            }
            .darkbtn {
              padding: 13px 0 20px !important;
              border-radius: 15px !important;
              box-shadow: none !important;
              font-size: 1.25rem !important;
              border-radius: 0 !important;
            }
            .whitebtn {
              padding: 20px 0 !important;
              border-radius: 15px !important;
              box-shadow: none !important;
              font-size: 1.25rem !important;
              border: 1px solid #F5445E;
              color: #F5445E;
              border-radius: 0 !important;
            }
          }
        }
      }
      .scroll-dir {
        display: flex;
        flex-direction: column;
        width: 100%;
        .message-preview {
          width: 100%;
          padding-left: 12px;
          .message-card {
            direction: ltr;
            width: 100%;
            max-width: inherit;
            min-width: inherit;
          }
        }
      }
    }
    .message-card-id {
      .full-width {
        width: 100%;
        max-width: inherit;
        min-width: inherit;
      }
      .scroll-dir {
        .messagesbox {
          .message-preview-box {
            .message-text{
              font-size: 5vw;
              line-height: 6vw;
              margin: 0;
            }
            margin-left: 0;
            width: 100%;
           
          }
        }
      }
      .form-container {
        width: 100%;
        .id-form {
          display: flex;
          flex-direction: column;
          gap: 26px;
          textarea {
            height: 266px;
            padding: 21px;
          
          }
          .darkbtn, .graybtn {
            padding: 18px 0 18px !important;
            border-radius: 15px !important;
            box-shadow: none !important;
            font-size: 1rem !important;
            text-transform: capitalize !important;
          }
          .whitebtn {
            padding: 18px 0 18px !important;
            border-radius: 15px !important;
            box-shadow: none !important;
            font-size: 1.25rem !important;
            border: 1px solid #F5445E;
            color: #F5445E;
            text-transform: capitalize !important;
          }
        }
      }
    }
  }
  .message-section {
    .card-container {
      .scroll-dir {
        &::-webkit-scrollbar {
          width: 15px;
          border-radius: 5rem;
        }
        &::-webkit-scrollbar-track {
          border-radius: 5rem;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5rem;
        }
      }
    }
  }
  .dashboard {
    .MuiContainer-root {
      .MuiGrid-container {
        .MuiGrid-grid-xs-8 {
          .MuiContainer-maxWidthLg {
            max-width: 100% !important;
            padding: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .message-section .card-container .messagesbox{
    height: 155px;
  }
}
@media screen and (min-width: 601px) and (max-width: 767px) {
  .message-section{
    .card-container{
      .messagesbox {
        margin-left: 12px;
        direction: ltr;
        gap: 12px;
        display: flex;
        flex-direction: column;
        height: 252px;
        .msg_avatar {
          display: flex;
          gap: 10px;
      }
      }
      .scroll-dir .messagesbox .message-preview-box {
        margin-left: 0;
        width: 100%;
    }
     .form-container .id-form .whitebtn{
      padding: 18px 0 18px !important;
        border-radius: 0px !important;
        box-shadow: none !important;
        font-size: 1.25rem !important;
        border: 1px solid #F5445E;
        color: #F5445E;
        text-transform: capitalize !important;
        border-radius: 0 !important;
    
     }
     .form-container .id-form .graybtn{
      padding: 18px 0 18px !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      font-size: 1.25rem !important;
     }
    }
  } 
  

}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .message-section {
    .card-container {
      padding: 1.25rem 1rem !important;
      .scroll-dir {
        .message-preview{
          margin-left: 0px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1930px) {
  .allmessage {
    .search_result_container {
      max-width: 70% !important;
      padding: 0;
      .above4Ksidebar {
        padding-left: 0 !important;
      }
      .above4K {
        max-width: 90% !important;
      }
    }
  }
}