.addartworkpage {
  .addartworkdescription {
    .textfiledspan,
    .selectbox {
      .MuiFormControl-root {
        label {
          font-size: 1.6vw;
          color: rgba(0, 0, 0, 0.6);
          span {
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
    .textareabox {
      label {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  legend {
    font-size: 1.2vw;
  }
}

.addartworkpage {
  .addartworksize {
    .selectbox,
    .textfiledspan {
      label {
        font-size: 1.6vw;
        color: rgba(0, 0, 0, 0.6);
        span {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .size {
      .textfiledspan {
        label {
          font-size: 1.6vw;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

.addartworkpage .extra-tag {
  .selectbox {
    label {
      font-size: 1.6vw;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

@media screen and (max-width: 600px) {
  .addartworkpage {
    .addartworkdescription {
      .textareabox {
        .MuiFormControl-root {
          label {
            font-size: 4.8vw !important;
          }
        }
      }
      #artworkDesc {
        .text-editor {
          .quill {
            .ql-container {
              .ql-editor {
                p {
                  font-size: 4.8vw !important;
                }
              }
            }
          }
        }
      }
      .textfiledspan,
      .selectbox {
        .MuiFormControl-root {
          label {
            font-size: 4.8vw;
          }
        }
      }
    }
    legend {
      font-size: 3.5vw;
    }
  }

  .addartworkpage {
    .addartworksize {
      .selectbox,
      .textfiledspan {
        label {
          font-size: 4.8vw;
        }
      }
      .size {
        .textfiledspan {
          label {
            font-size: 4.8vw;
          }
        }
      }
    }
  }

  .addartworkpage .extra-tag {
    .selectbox {
      label {
        font-size: 4.8vw;
      }
    }
  }
}
