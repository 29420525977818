.header {
  // box-shadow: 0px 1.6vw 1.6vw 0.04vw rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .102);
  z-index: 50;
  position: relative;
  .logosection {
    padding: 0 2vw;
    
    .mainsection {
      display: flex;
      align-items: center;
      column-gap: 1.92vw;
      justify-content: space-around;
      .signbtnright{
        padding: 1vw 0;
      }
    }
  }



}

.floating-search-container {
  input {
    &::placeholder {
      font-size: 18px !important;
      font-family: 'Inter', sans-serif !important;
    }
  }
  .graybtn {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    font-size: 16px !important;
    width: 100% !important;
    padding: 15px 25px !important;
    max-width: fit-content !important;
    min-width: 68px !important;

    min-height: auto !important;
    border-radius: 40px !important;
    height: fit-content !important;
    color: #fff !important;
    background-color: #afaeae !important;
  }
}
.search_input_hover {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2vw;
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}
.headerscroll{
  z-index: 999;
  position: relative;
  position: fixed!important;
  width: 100%;
  left: 0;
  top: 0px;
  z-index: 98;
  background: #fff;
  opacity: .97;
 -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
  -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
   box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
}

.subnav-content {
  position: absolute;
  display: none;
  align-items: center;
  top: 100%;
  background: #fff;
  width: 100vw;
  left: 0;
  box-shadow: 0px 1.6vw 1.6vw 0.04vw rgba(0, 0, 0, 0.05);
  z-index: 1;
  margin: 0;
  padding: 0 3.2vw;
  column-gap: 4vw;
}

.subnav-content {
  .mainsubmenu {
    display: flex;
  }
}

.subnav:hover .subnav-content {
  display: flex;
}

.subnav-content li {
  color: rgb(0, 0, 0);
  text-decoration: none;
  list-style-type: none;
}
.subnav-content a:hover {
  color: #f5445e;
}
.mainmenu {
  margin: 0 6.16vw 0 0;
  .subnav {
    .subnav-content {
      .subli {
        display: inline-block;
        margin-right: 2.5vw;
        white-space: nowrap;
        .sublinav {
          .MuiGrid-root {
            .mainsubmenu1,
            .mainsubmenu2 {
              .mainsubmenu {
                flex-direction: column;
                row-gap: 12px;
              }
            }
            .mainsubmenu3,
            .mainsubmenu4 {
              .mainsubmenu {
                flex-wrap: wrap;
                row-gap: 12px;
                li {
                  padding-top: 12px;
                }
              }
            }
            ul {
              padding-bottom: 22px;
            }
          }
        }
      }
    }
  }
}
.accountmenu-text {
  color: #000000;
}

.topbarimg {
  width: 100%;
  height: 2.43vw;
  cursor: 'pointer';
}

.logosection {
  padding: 1.6vw 2vw;

  .MuiGrid-container {
    // align-items: center;
  }

  .mainlogodiv {
    width: 10.64vw;
    height: 2.56vw;
    cursor: pointer;
    .mainlogo {
      width: 100%;
      height: auto;
    }
  }

  .carticontop img {
    width: 2.24vw;
    height: 2.24vw;
  }

  // .topsearchdiv {
  //   .searciconimg {
  //     position: absolute;
  //     right: 1rem;
  //     font-size: 1.5vw;
  //     padding: 0.25vw;
  //     width: 2vw;
  //     height: 2vw;
  //     top: 50%;
  //     transform: translateY(-50%);
  //   }
  // }
  .topsearchdiv {
    .searciconimg {
      position: absolute;
      // right: 1vw;
      // right: 1rem;

      font-size: 1.5vw;
      padding: 0.25vw;
      width: 2vw;
      height: 2vw;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .searchiconimgnew {
      left: -0.5vw;
    }
  }
  .topsearchbar {
    width: 22.64vw;

    .MuiOutlinedInput-root {
      border: none;
      border-radius: 0.8vw;
      background: #d9d9d9;
      height: 3.2vw;
    }

    .MuiInputBase-input {
      height: 100%;
      padding: 0.88vw 1.56vw;
      font-size: 1.12vw;
      line-height: 1.28vw;
      color: #000;
      font-family: 'Inter', sans-serif !important;

      width: 80%;
    }
    fieldset {
      border: none;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #3e3e3e;
      opacity: 1; /* Firefox */
      font-family: 'Inter', sans-serif !important;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #3e3e3e;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #3e3e3e;
    }
  }

  .loginbtn {
    display: flex;
    font-size: 1.12vw;
    min-height: 3.3vw;
    width: 14vw;
    text-align: center;
    height: 3.3vw !important;
    border-radius: 1.2vw;
    margin: 0 auto;
    color: #f8f8f8;
    background-color: #f5445e;
    font-family: 'Inter', sans-serif !important;

    text-decoration: none;
    align-items: center;
    text-transform: inherit;
    justify-content: center;
    .MuiSvgIcon-root {
      font-size: 2.8vw;
    }
  }
  .loginbtn:hover {
    background-color: #f5445e !important;
  }
  .logedinbtn {
    justify-content: space-evenly;
    .circle-avatar {
      position: relative;
      width: 2.2vw;
      height: 2.2vw;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .carticontop {
    a {
      color: #fff;
      display: flex;
      align-items: center;

      .MuiSvgIcon-root {
        font-size: 2.5vw;
        background: #595959;
        padding: 0.7vw;
        width: 3.2vw;
        height: 3.2vw;
        border-radius: 50%;
      }
      .valuecart {
        color: #000;
        font-size: 1.4vw;
        margin-left: 0.5vw;
      }
    }
  }
  .alignmentcart {
    display: flex;
    align-items: center;
    padding-left: 0px !important;
  }
}
ul.mainsubmenu.liset li {
  width: 49%;
  display: inline-block;
}
ul.mainsubmenu.liset {
  line-height: initial;
}

.subli {
  padding-bottom: 1.5vw;
  .sublinav {
    position: absolute;
    padding: 0 3.2vw;
    display: none;
    align-items: center;
    top: 100%;
    background: #fff;
    width: 100vw;
    left: 0;
    box-shadow: 0px 1.6vw 1.6vw 0.04vw rgba(0, 0, 0, 0.05);
    z-index: 1;
    margin: 0;
    column-gap: 6vw;
    .mainsubmenu {
      a:hover {
        color: #f5445e;
      }
    }
  }
}

.subli {
  &:hover {
    .sublinav {
      display: flex;
    }
    .subachor {
      color: #f5445e;
      border-bottom: 4px solid #f5445e;
    }
  }
}

.mobileshow {
  display: none;
}

.header {
  .MuiGrid-grid-xs-3{
    background-color: transparent;
  }
  .basket-icon .card-img {
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
    display: inline-block;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .create-account {
    width: 2.96vw;
    height: 2.96vw;
  }

  .account-text {
    font-weight: 600 !important;
    color: #000000;
    text-align: start;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif !important;


     .font10px{
      font-family: 'Inter', sans-serif !important;

      text-transform: initial;
      letter-spacing: 0;
     }
  }

  .basket-icon {
    &:hover .cart-menu-inner {
      display: block;
    }
  }
.item-center-text{
  align-items: center;
}
.cart-menu-inner{
  background-color: transparent;
  padding-top: 1.7vw;
  position: absolute;
  width: 24.24vw;
  left: 19vw;
  display: none;
}
  .cart-menu {
    // position: absolute;
    // left: 21vw;
    // top: 7.2vw;
    // display: none;
    // width: 24.24vw;
    background: #FFFFFF;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 0.8vw;
    opacity: 1;
    z-index: 9999;
    padding: 1.5vw 1vw 1.2vw;
    box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3019607843);

    &::before {

      content: "";
      position: absolute;
      top: -1.4vw;
      // left: 50%;
      left: 34%;
      margin-left: -5px;
      border-width: 1.6vw;
      border-style: solid;
      transform: rotate(180deg);
      border-color: #FFFFFF transparent transparent transparent;
      display: block;
      margin-left: 0;
      z-index: 199999999999999;
    }
  }

  .buy-art-section {
    &:hover .buy-art-inner {
      display: block;
    }
  }
  .buy-art-inner{
    background-color: transparent;
    padding-top: 1.7vw;
    position: absolute;
    width: 80%;
    left: 10vw;
    display: none;
  }
  .buy-art-menu {
    // position: absolute;
    // left: 12vw;
    // top: 7.2vw;

    // display: none;
    // width: 80%;
    background: #EAEAEA;
    // box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 0.8vw;
    opacity: 1;
    z-index: 9999;
    box-shadow: 0px 4px 40px 0.5px #0000004D;


    &::before {

      content: "";
      position: absolute;
      top: -1.4vw;
      left: 8vw;
      margin-left: -5px;
      border-width: 1.6vw;
      border-style: solid;
      transform: rotate(180deg);
      border-color: #FFFFFF transparent transparent transparent;
      display: block;
      margin-left: 0;
      z-index: 199999999999999;
    }
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #d9d9d9;
    }
    .buy-art-img {
      width: 2.6vw;
      height: auto;
    }

    .tablinks {
      margin-bottom: 1vw;
    }
.last-section{
  padding: 1vw 2vw;
  background-color: #D9D9D9;
  border-bottom-left-radius: 0.8vw;
  border-bottom-right-radius: 0.8vw;
  bottom: -4.8vw;
  margin-bottom: 0px;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .darkbtn{
    font-size: 1.28vw !important;
    line-height: 1.6vw !important;
    border-radius: 0.8vw !important;
    min-width: 14.16vw !important;
    height: 2.48vw !important;
    box-shadow: none !important;
  }
}
.MuiButtonBase-root.MuiTab-root{
  justify-content: flex-start;
  overflow: visible;
}
.MuiButtonBase-root.MuiTab-root.Mui-selected{
  background-color: transparent !important;
&::before {

  content: "";
  position: absolute;
  top: 1vw;
  /* left: 11vw; */
  right: -2.9vw;
  margin-left: -5px;
  border-width: 1.5vw;
  // right: -4.4vw;
  // margin-left: -5px;
  // border-width: 2.6vw;
  border-style: solid;
  transform: rotate(269deg);
  border-color: #FFFFFF transparent transparent transparent;
  display: block;
  margin-left: 0;
  z-index: 199999999999999;
}
}
.last-arrow-section{
  &::before {
    display: none !important;
  }
}
.overflow-css{
  overflow: visible !important;
  border-right: none;
  .MuiTabs-scroller{
    overflow: visible !important;
  }
}

.MuiTabs-indicator{
  background-color: transparent;
}
.MuiTabs-scroller.MuiTabs-hideScrollbar{
  background-color: #FFFFFF;
  border-top-left-radius: 0.8vw;
}
.MuiTabs-flexContainer.MuiTabs-flexContainerVertical{
  background-color: #FFFFFF;
  // border-bottom-left-radius: 0.8vw;
  border-top-left-radius: 0.8vw;
  padding-top: 1vw;
}
.tabbg{
  background-color: #EAEAEA;
  // border-radius: 0.8vw;
  border-top-left-radius: 0.8vw;
  border-top-right-radius: 0.8vw;
}
.tab-right-section{
  width: 75%;
  padding-left: 2vw;
}
.subject-text-new{
  color: #000000;
  font-weight: 600 !important;
  font-family: 'Inter', sans-serif !important;

  margin-bottom: 1.2vw;
}
.geometry-text{
  font-family: 'Inter', sans-serif !important;

  margin-bottom: 0.6vw;
  cursor: pointer;
}
.geometry-text a:hover{
color: #f5445e !important;
}
.grid-padd{
  // padding-left: 5vw;
  column-count: 2;
  column-fill: auto;
  height: 29vw;
}
img.header-size-img {
  width: 100%;
  height: 8.48vw;
  cursor: pointer;
}


.youmightalsolike{
  .profilecard .img_card {
    width: 90%;
    height: 14vw;
    border-radius: 1.6vw;
    margin: 0 auto;
}
}
.budget-size-img{
  width: 7.8vw;
  height: 7vw;
  cursor: pointer;
}
.scroll-container{
  // overflow: hidden;
  overflow: auto;
  margin-top: 5vw !important;
  /* width */

}
.budget-section-inner{
  // width: 20%;
  // display: block;
  // min-width: 20%;
  // position: relative;
  width: fit-content;
  display: block;
  min-width:fit-content ;
  position: relative;
  margin-right: 4vw
;

}
.budget-section-inner-sec{
  // column-count: 3;
  column-fill: auto;
  height: 18vw
}
.budgetsectionsec{
  width: 19%;
  display: block;
  min-width: 19%;
  position: relative;
  margin: 0 1%;
}
.textcenter{
  text-align: center;
}
.inner-img-section{
  margin-right: 3.5vw;
  text-align: center;
}
.main-image-section{
  display: flex;
}
.diamond-img{
  height: 6vw;
}
.grid-center{
  text-align: center;
  margin-top: 5vw !important;
}
.seeall-text-new{
  text-decoration: underline;
  cursor: pointer;
}

  }


}



@media only screen and (max-width: 3000px) and (min-width: 1930px) {
  .logosection {
    .logedinbtn {
      justify-content: space-between;
      padding: 0 2rem;
      .circle-avatar {
        width: 1.823vw;
        height: 1.823vw;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .logosection {
    .logedinbtn {
      padding: 0 0.5rem;
      gap: 5px;
      .circle-avatar {
        width: 1.823vw;
        height: 1.823vw;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .header {
    .logosection {
      .mobileshow {
        display: none;
      }
      .topsearchdiv {
        .topsearchbar {
          .MuiOutlinedInput-root {
            height: 4vw;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    .logosection {
      padding: 3vw 3vw 0;
      .mobileshow {
        display: block;
        .MuiGrid-grid-xs-3 {
          background: transparent;
        }
      }
      .mobilehide {
        display: none;
      }
      .mainlogodiv {
        width: 38.4vw;
        height: 8.21vw;
      }
      .topsearchdiv {
        .topsearchbar {
          width: 100%;
          .MuiOutlinedInput-root {
            border-radius: 2.899vw;
            height: 10vw;
          }
        }
      }
    }
  }

  .logosection .loginbtn:hover {
    background-color: transparent !important;
  }
  .header {
    padding-bottom: 14px;
  }
  .mobilemenudiv {
    padding-left: 26px !important;
  }
  img.topbarimg {
    width: 100%;
    height: 33px;
  }
  .logosection {
    padding: 3vw 3vw 0;
    .loginbtn {
      font-size: 0;
      min-width: 15px;
      min-height: 16px;
      height: 16px !important;
      width: 15px;
    }
    .topsearchdiv .searciconimg {
      right: 22px;
      padding: 0;
      top: 10px;
      width: 15px;
      height: 16px;
      transform: translateY(0);
    }
    .carticontop img {
      height: 6.76vw;
      width: 6.76vw;
    }
    .carticontop a .valuecart {
      font-size: 12px;
      line-height: 14.5px;
      margin-left: 0;
    }
    .signbtnright {
      display: flex;
      justify-content: flex-end;

      img{
        width: 7.5vw !important;
        height: 7.5vw !important;
      }
    }
    .topsearchbar {
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #000000;
        opacity: 1; /* Firefox */
        font-family: 'Inter', sans-serif !important;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #000000;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #000000;
      }
      .MuiInputBase-input {
        font-family: 'Inter', sans-serif !important;

        font-size: 15px;
        line-height: 17px;
        min-height: 40px;
        padding: 0 17px;
      }
    }
    .mainlogo {
      width: 38.4vw;
      height: 8.21vw;
    }
    .makeStyles-root-1 {
      svg {
        width: 10vw;
        height: 9vw;
        color: #3e3e3e;
      }
    }
    .mobilemenudiv {
      svg {
        width: 8.93vw;
        height: 7.24vw;
      }
    }
  }

  .logosection {
    .loginbtn {
      font-size: 0;
      min-width: 20px;
      min-height: 21px;
      height: 21px !important;
      width: 40px;
      background-color: transparent;
      .MuiSvgIcon-root {
        height: 10vw;
        width: 8.5vw;
        font-size: 8.5vw;
        color: #000000;
      }
      .circle-avatar {
        width: 7.5vw;
        height: 7.5vw;
        min-width: 7.5vw;
        min-height: 7.5vw;
      }
    }
  }
}
