.portfolio-settings {
  .darkbtn {
    min-width: 12.61vw !important;
    min-height: 2.9vw;
    font-size: 0.9vw !important;
    line-height: 1.2vw !important;
    box-shadow: none;
  }
 
  .selectbox .selectboxwidth, .multiselect .selectboxwidth{
    margin: 0;
  }
  .profilesection {

    padding: 2.08vw 2.24vw;
    margin-bottom: 2.24vw;
    display: block;

    .nameemailsection {
      width: 100%;

      .MuiTextField-root {
        width: 100%;
      }
      .MuiTextField-root .MuiOutlinedInput-root {
        border-color: #d9d9d9;
        border-radius: 0.4vw;
        color: #000;
        min-height: 3vw;
      }
    }
   
   
  }
  .upload-file_new {
    border-radius: 0 !important;
    height: 18vw;
    width: 100%;
    margin-bottom: 1.5vw !important;
    border: 1px solid #e2e2e2 !important;
    .upload_full_img {
      border-radius: 0 !important;
    }
  }
  .upload-file_new:hover {
    border: 1px solid #e2e2e2;
  }
  .banner_img {
    margin-bottom: 0.6vw;
  }
}
.crop_btn{
  border-radius: 0.8vw !important;
}
.crop_upload_section{
  height: 20vw;
}
.banner_upload_text_new{
  font-weight: 600 !important;
  margin-top: 10px;
}
.upload_text_new{
  width: 50%;
  margin: 10px  auto;
}
.banner_upload_new{
  .react-player{
    width: 100% !important;
    body.vp-center{
      width: 100% !important;
    }
  }
  div.ContentAreaBackground_module_imgContainer__90d1a7f8{
    display: block !important;
  }
  .image-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .banner-image {
    max-width: 100%;
    width: 100%;
    border-radius: 5px;
    height: 26.24vw;
    object-fit: cover;
  }
  .hover-text {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .crop_upload_section{
    background-color: transparent;
    border: none;
    height: 100%;
  }
  .image-container:hover{
    .hover-text {
      opacity: 1;
      width: 100%;
      height: 99%;
    }
    .crop_upload_section{
      background-color: transparent;
      border: none;
      height: 100%;
    }
    .banner-image{
      opacity: 0.5;
    }
    .croping_full_img{
      filter: invert(100%);
    }
  } 
}
.ReactCrop__child-wrapper{
  height: 26.24vw;
    object-fit: cover;
}
@media screen and (max-width: 600px) {
  .croping_full_img{
    width: 60px;
  }
  .upload_text_new{
    width: 100%;
    margin: 10px auto;
  }
  .banner_upload_new .banner-image {
    height: 73.18vw;
}
.ReactCrop__child-wrapper{
  height: 73.18vw;
}
  .crop_upload_section{
    height: 60vw;
  }
  .portfolio-settings {
    margin: 20px;
    .upload-file_new {
      height: 57vw;
    }
    .darkbtn {
      min-width: 48vw !important;
      min-height: 9.9vw;
      font-size: 3.9vw !important;
      line-height: 4.2vw !important;
      width: 100%;
      margin-bottom: 8vw;
    }
    .profilesection {
      padding: 6.08vw 6.24vw;
      margin-bottom: 6.24vw;
    }
  }
}