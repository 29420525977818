.university {
  margin-top: 6.08vw;
  .university-container {
    .whitebtn {
      background: #fff;
      border: 2px solid #ec425b;
      border-radius: 0.8vw !important;
      width: 17.44vw;
      height: 3.6vw;
    }
    .university-form {
      margin-top: 1.6vw !important;
      margin-bottom: 5vw !important;
      padding: 3.76vw 4.16vw 5.44vw !important;
      display: flex;
      flex-direction: column;
      gap: 0 !important;
      align-items: center;
      p {
        font-size: 1.44vw;
        line-height: 1.76vw;
        text-align: center;
        margin-bottom: 2.48vw;
      }
      .textfiledspan {
        margin-bottom: 4.72vw;
        .MuiFormControl-root {
          width: 59.84vw;
          background: #fffcfc;
          .MuiInputBase-root {
            border-radius: 1.2vw;
            height: 4vw;
          }
        }
      }
      .darkbtn {
        box-shadow: none;
        width: 23.28vw;
        height: 4vw;
        border-radius: 1.2vw !important;
        font-size: 1.6vw !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .university {
    margin-top: 18.357vw;
    .university-container {
      .whitebtn {
        border-radius: 2.415vw !important;
        width: 52.657vw;
        height: 10.87vw;
        font-size: 4.348vw !important;
      }
      .university-form {
        margin-top: 4.831vw !important;
        margin-bottom: 12vw !important;
        padding: 11.353vw 12.56vw 16.425vw !important;
        p {
          font-size: 4.348vw;
          line-height: 5.314vw;
          margin-bottom: 7.488vw;
        }
        .textfiledspan {
          margin-bottom: 14.251vw;
          .MuiFormControl-root {
            width: 100%;
            .MuiInputBase-root {
              border-radius: 3.623vw;
              height: 12.077vw;
            }
          }
        }
        .darkbtn {
          width: 100%;
          height: 12.077vw;
          border-radius: 3.623vw !important;
          font-size: 4.831vw !important;
        }
      }
    }
  }
}
