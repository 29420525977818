.shippingaddress {
  margin-top: 4.5vw;

  textarea{
    height: 30.56vw !important;
    overflow: auto;
  }
  .min-width {
    width: 80vw;
    margin: 4.5vw auto;
  }

  .mainheading {
    font-size: 1.4vw;
    line-height: 1.72vw;
  }

  .textfiledspan {
    margin-bottom: 1.6vw;

    fieldset{
      border-color:#595959;
    }
    .MuiFormControl-root {
      width: 100%;

      .MuiInputBase-root {
        border-radius: 5px;
        height: 3.12vw;

        input {
          font-size: 1.6vw !important;
          color: #3e3e3e !important;
          -webkit-text-fill-color: #3e3e3e !important;
          padding: 0 14px;

          &::placeholder {
            color: #3e3e3e;
            opacity: 1;
          }
        }
      }
    }
  }

  .addressfrom {
    display: flex;
    flex-direction: column;
    gap: 0 !important;
    padding: 3.2vw 3.2vw 4.56vw !important;
    margin-bottom: 1.44vw !important;

    .heading {
      font-size: 1.28vw;
      line-height: 1.6vw;
    }
.para_graph{
  font-size: 1.12vw;
  line-height: 1.34vw;
  margin-bottom: 10px;
}
.fulfilment_text{
  margin-bottom: 1.44vw;

}
    .price {
      font-size: 2.400vw;
      margin-bottom: 1.6vw;

      span {
        font-size: 1.2vw;
      }
    }

    .plan-name {
      font-size: 1.6vw;
      margin-bottom: 0.698vw;
    }

    .plan-details {
      font-size: 1.6vw;
      margin-bottom: 1.6vw;
    }

    .coupan {
      display: flex;
      column-gap: 1.2vw;
      align-items: center;
      margin-bottom: 2.163vw;

      .coupan-text {
        font-size: 1.6vw;
      }

      .inputform {
        display: flex;
        column-gap: 1.6vw;
        flex: 1;
        align-items: center;

        .textfiledspan {
          .MuiFormControl-root {
            .MuiInputBase-root {
              height: 4.745vw;
            }
          }
        }

        .darkbtn,
        .whitebtn {
          box-shadow: none;
          font-size: 1.6vw !important;
          height: 4.745vw;
          border: 2px solid #f5445e;
        }
      }

      .coupan-text {
        font-size: 1.6vw;
      }

      .textfiledspan {
        margin-bottom: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;

        .error_text {
          position: absolute;
          top: 4.745vw;
        }
      }
    }

    .cardtaotal {
      background: #f6f6f7;
      border-radius: 1.2vw;
      display: flex;
      flex-direction: column;
      padding: 0.8vw 1.6vw;
      margin-bottom: 1.954vw;

      .quote {
        border-bottom: 1px solid #707070;
        padding-bottom: 1.2vw;
        display: flex;
        justify-content: space-between;
        margin-top: 1.6vw;

        p {
          font-size: 1.6vw;
        }
      }

      .discount {
        p {
          font-size: 1.6vw;
          color: #6a6a6a;
        }
      }

      .total {
        p {
          font-size: 2vw;
        }
      }
    }

    .billstart {
      margin-bottom: 1.465vw;
      font-size: 1.6vw;
    }

    .pin {

      .darkbtn,
      .greenbtn {
        width: 100% !important;
        box-shadow: none;
        font-size: 1.28vw !important;
        border-radius: 0.8vw !important;
        margin-bottom: 0.88vw;
        min-height: 3.12vw !important;
      }
    }

    .saved-card {
      .details {
        display: flex;
        flex-direction: column;

        div {
          .StripeElement {
            margin-bottom: 1.6vw;
          }

          .darkbtn {
            margin-bottom: 1.6vw;
          }
        }

        .textflex {
          .darkbtn {
            margin-bottom: 1.6vw;
          }
        }

        .bottomMargin {
          margin-bottom: 1.6vw !important;
        }

        .tearms {
          font-size: 1.6vw;
          color: #f5445e;
          margin-bottom: 1.6vw;
        }

        .conditions {
          font-size: 1.6vw;
          line-height: 2.582vw;
        }

        .card-container {
          .address-details {
            .address-text {
              display: flex;
              column-gap: 12px;
              align-items: center;

              img {
                width: 3.6vw;
                height: auto;
              }
            }
          }
        }

        .skip {
          margin: 0;
          margin-bottom: 1.6vw;
        }
      }
    }
  }

  .card-checkout {
    .saved-card {
      .details {
        .card-container {
          .address-details {
            .address-text {
              .MuiFormControl-root {
                width: fit-content !important;
              }
            }
          }
        }

        div {
          width: 100% !important;

          .error_message {
            margin-left: 0;
            margin-bottom: 0.8vw;
          }

          .darkbtn {
            box-shadow: none;
            height: 4vw;
            width: fit-content;
            font-size: 1.6vw !important;
            border-radius: 1.6vw !important;
            border: none !important;
            cursor: pointer;
          }
        }
      }
    }
  }

  .Personalised_section_updated {
    padding: 3.04vw 2vw !important;
    margin-top: 0 !important;
    gap: 0 !important;
    display: block;

    .textfiledspan {
      margin-bottom: 0;

      .MuiFormControl-root {
        .MuiInputBase-root {
          border-radius: 1.2vw;
          height: 3.68vw;
        }
      }
    }
    .Personalised_section{
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1.36vw;
    }
    .Personalised_heading{
        font-size: 1.28vw;
        line-height: 1.6vw;
        margin-right: 20px;
      
    }
    .outlinebtn{
      min-width: 12.76vw !important;
      font-size: 1.12vw !important;
  line-height: 1.34vw !important;
  border-radius: 0.8vw !important;

    }
    .darkbtn {
      min-width: 12.76vw !important;
      box-shadow: none;
      height: 2.864vw;
      border-radius: 0.8vw !important;
      padding: 11px 11px !important;
      font-size: 1.12vw !important;
  line-height: 1.34vw !important;
    }
    .personalise-table{
      .darkbtn{
        min-width: 12vw !important;
      }
      .greenbtn{
        background-color: #80C86E !important;
      }
     
    }
    .last_Save_btn{
      margin-top: 2vw;
      .darkbtn{
        min-width: 29.44vw !important;
        height: 2.864vw;
        border-radius: 0.8vw !important;
        padding: 11px 11px !important;
        font-size: 1.12vw !important;
        line-height: 1.34vw !important;
      }
    }
    fieldset{
      border-color: #595959;
    }
      .return_police_section{
       
        .MuiTypography-root.MuiTypography-body1{
          font-size: 1.12vw !important;
          line-height: 1.34vw !important;
        }
     .or_text{
      margin: 1vw 0;
     }
    }
  }

  .skip {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 5.6vw 0;

    .underline {
      text-decoration: underline;
      font-size: 1.6vw !important;
      text-transform: capitalize;
    }

    .darkbtn {
      width: 19.36vw !important;
      box-shadow: none;
      font-size: 1.6vw !important;
      height: 4vw;
      border-radius: 1.2vw !important;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    background-color: #ffffff !important;
    color: #555 !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #555555 !important;
  }

  .loader {
    color: #ffffff;
    flex-direction: column;
    text-align: center;
    line-height: 2.6vw;
    font-size: 1.6vw;
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.shippingaddress {
  .selectbox {
    .selectboxwidth {
      margin: 0;
    }

    .select-field {
      border-radius: 5px;
        height: 3.12vw;
      font-size: 1.6vw;
      line-height: 2.5vw;
    }
    .Mui-disabled {
      line-height: 1.5vw;
      -webkit-text-fill-color:#3e3e3e ;
      svg{
        color:#3e3e3e ;
      }
    }
  }
}

.personalise-table{
  margin-top: 1.8vw !important;
  fieldset{
    border-color: #595959;
  }
  .MuiInputBase-root {
    min-width: 13vw;
    width: 13vw;
  }
 
  button{
    min-width: 12vw !important;
    height: 2.864vw;
    border-radius: 0.8vw !important;
    padding: 11px 11px !important;
    font-size: 1.12vw !important;
    line-height: 1.34vw !important;
  }
  .delete-icon-btn{
    min-width: fit-content !important;
    padding: 11px 8px !important;
  }
  .MuiFormHelperText-root{
    margin-left: 0;
    margin-right: 0;
    word-break: break-word;
    width: 12.4vw;
  }
  .MuiGrid-root.MuiGrid-container{
    align-items: flex-start;
  }
}
.shipping_delete_popup{
  button{
    padding: 11px 11px !important;
    font-weight: normal !important;
    letter-spacing: 0px !important;
    min-height: 3.64vw;
    text-transform: inherit !important;
    min-width: 12.56vw !important;
    box-shadow: none !important;
    font-size: 1.25rem !important;
    line-height: 1.3rem !important;
    border-radius: 8px !important;
  
  }
}
@media screen and (min-width: 1930px) {
  .shippingaddress {
    .selectbox {
      .Mui-disabled {
        line-height: 2.5vw;
      }
    }
  }
}


@media screen and (max-width: 600px) {
  .personalise-table .MuiFormHelperText-root{
    width: 100%;
  }
  .shipping_delete_popup{
    button{
      padding: 11px 11px !important;
      font-weight: normal !important;
      letter-spacing: 0px !important;
      min-height: 10vw;
      text-transform: inherit !important;
      min-width: 45.56vw !important;
      box-shadow: none !important;
    
    }
  }
  .shippingaddress {
    margin-top: 4.5vw;
    padding: 0 20px;
   .Personalised_section_updated {
      padding: 6.662vw 6.662vw 6.768vw !important;
    
    }

    .min-width {
      width: 100%;
    }

    .card-checkout {
      .saved-card {
        .details {
          .error_message {
            margin-bottom: 1.8vw !important;
          }

          div {
            min-height: auto !important;

            .darkbtn {
              height: 12vw;
              width: 100% !important;
              font-size: 3.8vw !important;
              border-radius: 4.831vw !important;
            }
          }
        }
      }
    }

    .mainheading {
      font-size: 7vw;
    }

    .textfiledspan {
      margin-bottom: 4vw;

      .MuiFormControl-root {
        .MuiInputBase-root {
          border-radius: 15px;
          height: 9.37vw;
        }
      }

      .MuiFormControl-root .MuiInputBase-root input {
        font-size: 3.8vw !important;
        line-height: 5vw;
      }
    }
    .selectbox{
      margin-bottom: 4vw;
    }

    .addressfrom {
      padding: 9.662vw 9.662vw 13.768vw !important;
      margin-bottom: 6vw !important;
      .para_graph{
        font-size: 3.381vw;
        line-height: 4.07vw;
      
      }
      .fulfilment_text{
        margin-bottom: 4.347vw;
      }
      .heading {
        font-size: 4.831vw;
        margin-bottom: 4.831vw;
      }

      .price {
        font-size: 7.246vw;
        margin-bottom: 4.831vw;

        span {
          font-size: 3.623vw;
        }
      }

      .plan-name,
      .plan-details {
        font-size: 4.831vw;
        margin-bottom: 3.623vw;
      }

      .coupan {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding: 1.6vw 0;

        .inputform {
          flex-direction: column;
          width: 100%;

          .textfiledspan {
            .MuiFormControl-root {
              .MuiInputBase-root {
                height: 12vw;
              }
            }

            .error_text {
              top: 12vw;
            }
          }

          .darkbtn,
          .whitebtn {
            height: 12vw;
            width: 100% !important;
            font-size: 3.8vw !important;
            border-radius: 4.831vw !important;
          }

          .whitebtn {
            border: 2px solid #f5445e;
          }
        }

        .coupan-text {
          font-size: 4.831vw;
          margin-bottom: 3.623vw;
        }

        .textfiledspan {
          width: 100%;
          margin-bottom: 5.623vw;
        }
      }

      .cardtaotal {
        border-radius: 3.623vw;
        padding: 2vw 3.623vw;
        margin-bottom: 1.6vw;

        .quote {
          border-bottom: 1px solid #707070;
          padding-bottom: 1.2vw;
          display: flex;
          justify-content: space-between;
          margin-top: 1.6vw;

          p {
            font-size: 4.831vw;
          }
        }
      }

      .billstart {
        margin-bottom: 1.6vw;
        font-size: 4.831vw;
      }

      .pin {

        .darkbtn,
        .greenbtn {
          width: 100% !important;
          font-size: 4.831vw !important;
          height: 9.37vw;
          border-radius: 40px !important;
          margin-bottom: 4vw;
        }
      }

      .saved-card {
        .details {
          row-gap: 4vw;

          .tearms {
            font-size: 3.623vw;
            margin-bottom: 3.623vw;
          }

          .conditions {
            font-size: 3.623vw;
            line-height: 5vw;
            margin-bottom: 3.623vw;
          }

          .card-container {
            padding: 1.25rem 1rem !important;

            .address-details {
              .address-text {
                flex-wrap: wrap;
                column-gap: 0;

                .MuiFormControlLabel-root {
                  margin-right: 0;
                }

                img {
                  width: 9.6vw;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }

    .optionalfrom {
      padding: 9.179vw !important;
      gap: 0 !important;

      .textfiledspan {
        margin-bottom: 0;

        .MuiFormControl-root {
          .MuiInputBase-root {
            border-radius: 3.623vw;
            height: 11.111vw;
          }
        }
      }

      .darkbtn {
        width: 100% !important;
        box-shadow: none;
        font-size: 4.831vw !important;
        height: 11.111vw;
        border-radius: 3.623vw !important;
      }
    }

    .skip {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin: 5.6vw 0;

      .underline {
        font-size: 4.831vw !important;
      }

      .darkbtn {
        width: 48.309vw !important;
        box-shadow: none;
        font-size: 4.831vw !important;
        height: 12.077vw;
        border-radius: 3.623vw !important;
      }
    }
   .Personalised_section_updated{
    .Personalised_heading{
      font-size: 3.864vw;
      line-height: 4.83vw;
      margin-bottom: 4vw;
    }
    .Personalised_section{
      display: block;
      button{
        width: 100% !important;
        font-size: 4.831vw !important;
        height: 9.37vw;
        border-radius: 10px !important;
        margin-bottom: 4vw;
      }
    }
    .last_Save_btn {
      margin-top: 6vw;
      .darkbtn{
        width: 100% !important;
        font-size: 4.831vw !important;
        height: 9.37vw;
        border-radius: 10px !important;
        margin-bottom: 4vw;
      }
    }
   }
.Personalised_section_updated .return_police_section .MuiTypography-root.MuiTypography-body1{
  font-size: 3.381vw !important;
  line-height: 4.07vw !important;
}

    .error_text {
      font-size: 3.5vw;
    }

    .loader {
      line-height: 6.6vw;
      font-size: 5.6vw;
    }
  }
  .personalise-table{
    overflow: auto;
    .MuiInputBase-root {
      min-width: 42.99vw;
      width: 42.99vw;
  }
  .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-spacing-xs-1 {
    flex-wrap: nowrap;
  }
  button, .darkbtn{
    min-width: 42vw !important;
    height: 9vw;
    border-radius: 0.8vw !important;
    padding: 16px 11px !important;
    font-size: 3.86vw !important;
    line-height: 4vw !important;
  }
  } 
  .shippingaddress {
    .selectbox {
      .selectboxwidth {
        margin: 0;
      }

      .select-field {
        border-radius: 15px;
        height: 9.37vw;
        font-size: 3.8vw;
        line-height: 5vw;
      }
    }
  }
  .shippingaddress .Personalised_section_updated .personalise-table .darkbtn{
    min-width: 42vw !important;
    height: 9vw;
    border-radius: 0.8vw !important;
    padding: 16px 11px !important;
    font-size: 3.86vw !important;
    line-height: 4vw !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 1280px) {
  .shippingaddress {
    .selectbox {
      .Mui-disabled {
        line-height: 0.5vw;
      }
    }
  }
}
@media screen and (min-width: 601px) and (max-width: 1004px) {
  .personalise-table{
    overflow: auto;
 
  .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-spacing-xs-1 {
    flex-wrap: nowrap;
  }
 
  } 
}

@media screen and (min-width: 200px) and (max-width: 414px) {
  .shippingaddress {
    .card-checkout {
      padding: 9.662vw 5.662vw 13.768vw !important;
    }
  }
}

@media screen and (min-width: 1200px) {
  .main-checkout-page {
    max-width: 100% !important;
  }
}


