.mainmenu {
  .menulist {
    position: relative;
  }
  .navbar {
    display: flex;
    align-items: center;
    column-gap: 5vw;
  }
  .navbar a {
    font-size: 1.12vw;
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
  .subnav {
    overflow: hidden;
    z-index: 99;
    line-height: 2.5vw;
    font-size: 1.1vw;
    color: rgb(0, 0, 0);
    margin: 0;
  }

  .subnav:hover {
    color: black;
    z-index: 99;
  }
  .subnav:hover .menumaintext {
    color: #f5445e;
  }
  .padding0px {
    padding-top: 0;
    width: fit-content;
    flex-basis: fit-content;
    padding-right: 3vw;
  }

  .mainsubmenu {
    padding-inline-start: 0;
    list-style: none;
    li {
      line-height: normal !important;
    }
    a:hover {
      padding: 0;
      border: 0;
    }
  }
  .mainsubmenu3 .mainsubmenu,
  .mainsubmenu4 .mainsubmenu {
    margin-block-start: 0;
  }
  .menumaintext {
    padding: 1vw 2.4vw;
    padding: 0;
    cursor: pointer;
  }
  h5.menuheadsub {
    font-weight: 400;
    line-height: normal;
    margin-block-end: 0.8em;
    margin-block-start: 0em;
  }
}
