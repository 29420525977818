.card-detail {
  .darkbtn {
    box-shadow: none !important;
    border: none !important;
    margin-top: 2vw;
    height: 4vw;
  }
}

@media screen and (max-width: 600px) {
  .card-detail {
    .darkbtn {
      margin-top: 2vw;
      height: 11vw;
      width: 100%;
    }
  }
}
