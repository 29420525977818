.newartistpage {
  margin-top: 3.12vw;

  .new-btn-area {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 1.6vw;
    }

    .btn-group {
      display: flex;
      gap: 0.8vw;
      .outlinebtn,
      .graybtn,
      .darkbtn {
        box-shadow: none !important;
        min-width:12.76vw !important;
        max-width: 13.76vw !important;
        width: 100% !important;
        padding: 0 !important;
      }
      .outlinebtn {
        padding: 0 calc(3vw - 2px) !important;
      }
    }
  }
  .para-art {
    font-size: 0.96vw;
    margin-top: 1.76vw;
    .underline {
      text-transform: lowercase;
      text-decoration: underline;
      font-family: 'Inter', sans-serif !important;
      font-weight: 400 !important;
      font-size: 0.96vw;
      padding: 0;
      min-width: fit-content;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .empty-text {
    font-size: 3.2vw;
  }
  .main-table {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 40px 0.5px rgba(0, 0, 0, 0.3);
    table {
      thead,
      tbody {
        tr {
          th,
          td {
            border-bottom: none;
            font-family: 'Inter', sans-serif !important;

            font-size: 20px;
            font-weight: 400;
            white-space: nowrap;
          }
          td {
            width: fit-content;
            .MuiButtonBase-root {
              position: relative;
              &:hover {
                .error-msg-postion {
                  opacity: 1;
                }
              }
              .error-msg-postion {
                position: absolute;
                top: -30px;
                left: 50%;
                transform: translateX(-50%);
                background-color: #afaeae;
                padding: 5px 10px;
                border-radius: 5px;
                opacity: 0;
              }
            }
            img {
              width: 30px;
              height: 30px;
              object-fit: cover;
              background: #d9d9d9;
            }
            .btn-image{
              width: 30px;
              height: 30px;
              object-fit: contain;
              background: transparent;
            }
            .darkbtn {
              max-width: 12.32vw !important;
              width: 100% !important;
              box-shadow: none !important;
              font-size: 1.1vw !important;
              height: 2.5vw !important;
              min-width: 6.5vw !important;
            }
            .MuiCheckbox-root {
              border-radius: 0.16vw;
              color: transparent;
              height: 30px;
              padding: 0;
              width: 30px;
              svg {
                background: #d9d9d9;
                border-radius: 0.16vw;
                height: 30px;
                padding: 0;
                width: 30px;
              }
            }
            .Mui-checked {
              color: #ec425b;
              border: 5px solid #ec425b;
              svg {
                background: transparent;
              }
            }
          }
        }
      }
      thead {
        background: #595959;
        tr {
          th {
            color: #fff;
          }
        }
      }
    }
  }
}

.share-model {
  .MuiPaper-root,
  .MuiDialog-paper {
    width: 100% !important;
    min-height: fit-content !important;

    .MuiDialogContent-root {
      display: flex !important;
      flex-direction: column !important;
      gap: 20px !important;
      align-items: center;
      input {
        background: transparent;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
      }
      .darkbtn {
        box-shadow: none !important;
        font-size: 20px !important;
        padding: 0 !important;
        width: fit-content;
      }
    }
  }
}

@media screen and (max-width: 891px) {
  .newartistpage {
    margin-top: 4.382vw;

    .new-btn-area {
      h1 {
        font-size: 2.247vw;
      }

      .btn-group {
        gap: 1.124vw;
        .outlinebtn,
        .graybtn,
        .darkbtn {
          font-size: 1.573vw !important;
          border-radius: 50px !important;
          padding: 0 6.18vw !important;
        }
      }
    }
    .para-art {
      font-size: 1.348vw;
      margin-top: 2.472vw;
      .underline {
        font-size: 1.348vw;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .newartistpage {
    margin-top: 3.12vw;

    .artrightsection {
      .artcardarea {
        margin-top: 2rem;
      }
    }

    .new-btn-area {
      flex-direction: column;
      gap: 5vw;

      h1 {
        font-size: 4vw;
      }

      .btn-group {
        display: flex;
        gap: 2vw;
        flex-wrap: wrap;
        justify-content: center;
        .outlinebtn,
        .graybtn,
        .darkbtn {
          font-size: 2.8vw !important;
          height: 6.6vw !important;
          max-width: 34.4vw !important;
          min-width: 34.4vw !important; 
        }
      }
    }
    .para-art {
      font-size: 2.4vw;
      margin-top: 4.4vw;
      text-align: center;
      .underline {
        font-size: 2.4vw;
      }
    }
    .main-table {
      table {
        tbody {
          tr {
            td {
              .darkbtn {
                max-width: 31.4vw !important;
                min-width: 31.4vw !important;
                width: 100% !important;
                font-size: 2.8vw !important;
                height: 6.6vw !important;
                border-radius: 10rem !important;
              }
              .MuiCheckbox-root {
                svg {
                  border-radius: 0.4vw;
                }
              }
            }
          }
        }
      }
    }
  }
  .share-model .MuiPaper-root .MuiDialogContent-root .darkbtn, .share-model .MuiDialog-paper .MuiDialogContent-root .darkbtn {
    box-shadow: none !important;
    font-size: 20px !important;
    padding: 0 !important;
    width: -moz-fit-content;
    padding: 2.6vw 3.65vw !important;
    font-weight: normal !important;
    min-width: 24vw !important;
    letter-spacing: 0px !important;
    min-height: 8vw;
    border-radius: 4vw !important;
}
}
