.message-card {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
    padding: 0;
  }

  border: 0.5px solid #000000;
  border-radius: 20px;
  padding: 24px 13px;
  width: 98%;
  max-width: 98%;
  min-width: 185px;
  width: 100%;
  display: flex;
  gap: 17px;
  margin-bottom: 12px;
  cursor: pointer;
  margin-right: 2px;

  .profile-img {
    img {
      border-radius: 999px;
    }
  }

  .buyer-details {
    display: flex;
    flex-direction: column;
    gap: 7px;

    .buyer {
      h3 {
      font-size: 1.4vw;
  line-height: 1.72vw;
      }
    }
    p {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .message-card .buyer-details .buyer h3{
    font-size: 18px;
    line-height: 22px;
  }
}