.contactartist {
  .MuiPaper-root.MuiDialog-paper {
    width: 31.5vw !important;
  }
  .message_text {
    margin-bottom: 0.5vw;
  }
  .para_text {
    margin-bottom: 1.5vw;
  }
  .darkbtn{
    box-shadow: none;
  }
  .MuiFormHelperText-root.Mui-error {
    font-size: 1vw;
    line-height: 1.2vw;
    margin-left: 0;
    margin-top: 1.2vw;
  }
  .icon_section {
    align-items: center;
    margin-bottom: 2vw;
  }
  .clear-icon {
    text-align: end;
    cursor: pointer;

    svg {
      border-radius: 50%;
      padding: 5px;
      font-size: 2.2vw;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .dialogHeading {
    text-align: center;
    padding-top: 0;
    margin-left: 4vw;
  }

  .boldtext {
    color: #000;
  }

  .profile_section {
    margin-bottom: 1.6vw;
  }

  .profile_img {
    width: 100%;
    max-width: 6.72vw;
    height: 6.72vw;
    margin-top: 0px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 50%;
  }

  .titlecol {
    color: #000 !important;
  }

  .rating-value {
    display: flex;
    align-items: center;
  }

  .star_no {
    margin-left: 0.8vw;
    color: #000000;
  }

  .rating-position {
    position: relative;
  }

  .rating-img {
    height: 4vw;
    margin-top: 10px;
    display: block;
    right: 0;

    img{
      height: inherit !important;
      width: inherit !important;
      inset: inherit !important;
    }
  }

  .rating-value {
    .pinkcolor {
      color: #f6445e;
    }
  }

  .artist-number {
    position: absolute;
    border: 2px solid #f6445e;
    left: auto;
    height: 1.75vw;
    right: 0;
    width: 1.8vw;
    color: #f6445e;
    font-size: 1.12vw;
    line-height: 1.34vw;
    top: 1.25vw;
    text-align: center;
  }

  .artist-location {
    display: flex;
    align-items: center;
    margin-top: 0.5vw;

    .map_img {
      color: #f6445e;
      margin-right: 0.5vw;
      width: 1vw;
      height: 1.3vw;
    }
  }

  .title_text {
    margin-bottom: 1vw;
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 1.2vw;
  }

  .darkbtn {
    width: 100%;
    margin-bottom: 1.2vw;
    height: 3.5vw;
    margin-top: 1vw;
    font-size: 1.3vw !important;
  }

  .font20px,
  .font16px {
    color: #000000;
  }
}

@media only screen and (max-width: 600px) {
  .contactartist {
    .dialogHeading {
      margin-top: 0;
      margin-left: 9vw;
    }
    .icon_section {
      margin-bottom: 25px;
    }
    .profile_section {
      margin-bottom: 15px;
    }

    .title_text {
      margin-bottom: 12px;
      margin-top: 15px;
    }

    .artist-number {
      height: 19px;
      width: 20px;
      font-size: 12px;
      line-height: 15px;
      top: 4.2vw;
      text-align: center;
    }
    .titlecol {
      font-size: 5.0386vw !important;
      line-height: 7.246vw !important;
    }
    .clear-icon svg {
      border-radius: 50%;
      font-size: 21px;
      width: 29px;
      height: 28px;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    .rating-img {
      height: 45px;
    }

    .profile_img {
      width: 100%;
      height: 73px;
      max-width: 100%;
    }

    .rating-value {
      svg {
        width: 5vw;
        height: 5vw;
      }
    }

    .darkbtn {
      width: 100% !important;
      height: 50px;
      border-radius: 15px !important;
      font-size: 20px !important;
      line-height: 24.5px !important;
      margin-top: 25px;
    }

    .artist-location {
      .map_img {
        margin-right: 5px;
        width: 4.5vw;
        height: 5vw;
      }
    }
  }
  .contactartist .MuiFormHelperText-root.Mui-error{
    font-size: 2.5vw;
  }
}
@media  (min-width:921px) and ( max-width:1024px){
  .contactartist{
    .clear-icon svg {
      font-size: 3.2vw;
    }
    .artist-number{
      top: 1.8vw;
    }
  }
}
@media only screen and (max-width: 360px) {
  .contactartist .artist-number {
   top: 5vw;
  }
}
@media only screen and (min-width: 1930px) {
  .contactartist .artist-number {
    top: 1vw;
  }
}