.myprofilerightsection {
  margin-top: 1.36vw;
  margin-bottom: 10vw;
  h2 {
    margin-bottom: 0;
  }
  .upload_delete_photo {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: 2.5vw !important;
    .upload-file_new {
      cursor: auto;
    }
    .delete_photo_btn,
    .upload_photo_btn {
      min-height: 3.04vw !important;
      min-width: 13.12vw !important;
      text-transform: initial;
      border-radius: 0.8vw !important;
      font-family: 'Inter', sans-serif !important;
    }
    .delete_photo_btn {
      color: #fff;
      background-color: #3e3e3e;
    }
    .upload_photo_btn {
      background-color: #afaeae;
      margin-bottom: 6px;
      color: #000;
    }
  }
  .profilesection {
    background: #ffffff;
    box-shadow: 0px 0.32vw 3.2vw 0.016vw rgba(0, 0, 0, 0.3);
    border-radius: 1.6vw;
    padding: 2.08vw 2.24vw;
    margin-bottom: 2.24vw;
    .darkbtn {
      min-width: 12.61vw !important;
      min-height: 2.9vw;
      font-size: 0.9vw !important;
      line-height: 1.2vw !important;
      box-shadow: none;
    }
    .upload_section {
      display: flex;
      align-items: center;

      .MuiTypography-root.MuiTypography-body1.font16px {
        margin-left: 1vw;
      }
    }
    .nameemailsection {
      .greenbtn {
        background-color: transparent !important;
        padding: 0 !important;
      }
    }
    .profilepic {
      display: flex;
      margin-bottom: 2.08vw;
      .greenbtn {
        background-color: transparent !important;
        padding: 0 !important;
      }
      .img_card {
        width: 12.16vw;
        height: 12.08vw;
        margin: inherit;
        margin-bottom: 0;
        border-radius: 20px !important;
        overflow: hidden;
      }
      .changebtn {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-left: 2.8vw;
        justify-content: center;

        .darkbtn {
          width: 12.4vw !important;
          height: 4vw !important;
          border-radius: 1.2vw !important;
          font-size: 1.6vw !important;
          box-shadow: none;
          margin-bottom: 1.92vw;
        }
        .canclebtn {
          color: #000;
          margin: 0;
          text-decoration: underline;
        }
      }
      .greenbtn {
        .darkbtn {
          background-color: #80c86e !important;
        }
      }
    }
    .nameemailsection {
      width: 100%;
      .font18px {
        font-size: 1.3vw !important;
        line-height: 1.68vw !important;
      }
      .MuiTextField-root {
        width: 100%;
        .MuiOutlinedInput-root {
          border-color: #d9d9d9;
          border-radius: 0.4vw;
          color: #000;
          height: 3vw;
          input {
            color: #000;
            font-size: 1vw;
            padding-left: 1vw;
            padding-top: inherit;
            padding-bottom: inherit;
          }
          input::placeholder {
            color: #000;
            opacity: 1; /* Firefox */
            font-family: 'Inter', sans-serif !important;
          }

          input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #000;
          }

          input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #000;
          }
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: #595959;
        }
      }
      .darkbtn {
        width: 12.4vw !important;
        height: 2.73vw !important;
        border-radius: 1.2vw !important;
        box-shadow: none;
      }
    }
    .edit_section_new {
      .darkbtn {
        min-width: 14.96vw !important;
        width: 14.96vw !important;
        height: 2.72vw !important;
        box-shadow: none;
        font-size: 1.28vw !important;
      }
    }
    .greenbtn {
      .darkbtn {
        background-color: #80c86e !important;
      }
    }
  }
  .requestlinksection {
    background: #ffffff;
    box-shadow: 0px 0.32vw 3.2vw 0.016vw rgba(0, 0, 0, 0.3);
    border-radius: 0.8vw;
    padding: 2.8vw 2.08vw 2.8vw 5.2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.64vw;

    .darkbtn {
      width: 24.4vw !important;
      height: 4vw !important;
      box-shadow: none;
    }
  }
  .profile-checks {
    display: flex;
    flex-direction: column;
    gap: 0 !important;
    .check-profile {
      margin-bottom: 2vw;
      color: #000;
      font-size: 1.6vw;
    }
    .select_new_btn {
      border-radius: 0.625rem !important;
      min-width: 8vw !important;
      padding: 0.75vw !important;
      min-height: 2.48vw;
      font-size: 1.28vw !important;
      line-height: 1.6vw !important;
    }
    .profile-switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5vw;
      .MuiSwitch-track {
        background-color: #e2e2e2 !important;
      }
      h6 {
        font-size: 1.6vw;
      }
    }
  }
  .shareuri {
    .textfiledspan {
      width: 100%;
      background: #fffcfc;
      border-radius: 15px;
      .MuiTextField-root {
        width: 100%;
        .MuiInputBase-root {
          height: 4vw;
          input {
            font-size: 1.28vw;
          }
        }
        fieldset {
          border-color: #d9d9d9;
          border-radius: 0.8vw;
        }
      }
    }
    .shareBtn {
      font-size: 1.28vw !important;
      line-height: 1.6vw !important;
      width: 100%;
      max-width: 303px;
      box-shadow: none;
      border-radius: 0.8vw !important;
    }
  }
  .skip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      font-size: 0.8vw;
      text-decoration: underline;
      text-transform: capitalize;
      &:hover {
        background-color: transparent;
      }
    }
    .darkbtn {
      box-shadow: none;
      width: 14vw;
      height: 4vw;
      text-decoration: none;
    }
  }
  //new design code
  .my-account-new {
    margin-bottom: 2vw;
    .whitebtn {
      border: 1px solid #f5445e;
    }
    button {
      min-width: 13.76vw !important;
      height: 2.08vw;
      font-size: 1.28vw !important;
      line-height: 1.6vw !important;
      box-shadow: none;
    }
  }
  .file_upload_card {
    padding: 1.6vw 1.76vw;
    background-color: #ffffff;
  }
  .input-upload-section {
    .darkbtn {
      min-width: 10.16vw !important;
      height: 2vw !important;
      box-shadow: none;
      min-height: fit-content !important;
    }
    .upload_section {
      width: 40%;
      .MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
        width: 100%;
        margin-bottom: 1.68vw;
      }
    }
    .upload_section_two {
      width: 100%;
      .MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
        width: 100%;
        margin-bottom: 1.68vw;
      }
    }
  }
}
//contact dialog box
.new_contact_dialogbox {
  .contact_inner {
    padding: 2.64vw 4.2vw;
  }
  .MuiPaper-root.MuiDialog-paper {
    border-radius: 1.2vw;
    min-width: 26.4vw;
    min-height: 201px;
    max-width: 80vw !important;
    pointer-events: auto;
    width: 26.72vw !important;
  }
  .message_text {
    margin-bottom: 1.2vw;
  }
  .para_text {
    margin-bottom: 1.5vw;
  }
  button {
    font-size: 1.28vw !important;
    line-height: 1.6vw !important;
    border-radius: 0.8vw !important;
    min-height: 3.84vw !important;
    box-shadow: none;
    padding: 0.9vw 1.165vw !important;
  }
}
.sharecard {
  width: 46vw !important;
  .share-button {
    width: 3.4vw;
    height: 3.4vw;
    svg {
      width: 3.4vw;
      height: 3.4vw;
    }
    .xbtn {
      border-radius: 50%;
      background: #000;
      color: #fff;
      padding: 5px;
    }
  }
  .shareInput {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    input {
      width: 100% !important;
      margin: 1rem 0;
      font-size: 1.6vw;
    }
    button {
      width: 50% !important;
      background-color: #f5445e !important;
    }
  }
}
.sell-dashboard {
  .text_center_line {
    text-align: center;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 900px) {
  .sharecard {
    width: 45vw !important;
  }
  .sell-dashboard .progress_data {
    width: 100%;
    margin: 5px 0 20px;
  }
}

@media screen and (min-width: 1930px) {
  .myprofilerightsection {
    .profilesection {
      .nameemailsection {
        .inptbtn {
          .MuiTextField-root {
            width: 28vw;
          }
          .darkbtn {
            width: 10.4vw !important;
          }
        }
      }
    }
    .requestlinksection .darkbtn {
      width: 22.4vw !important;
    }
  }
}

@media screen and (min-width: 1290px) {
  .myprofilerightsection {
    .shareuri {
      .shareBtn {
        width: 43.4vw !important;
        height: 4vw !important;
        border-radius: 0.8vw !important;
        box-shadow: none;
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .new_contact_dialogbox .MuiPaper-root.MuiDialog-paper {
    min-width: 79.71vw;
  }
  .upgrade_dialog_box .MuiPaper-root.MuiDialog-paper {
    min-height: fit-content;
  }
  .myprofilerightsection {
    .profile-checks {
      padding: 8.696vw 4.831vw !important;
      border-radius: 2.415vw;
      .profile-switch {
        h6 {
          font-size: 4.831vw;
        }
        .MuiFormGroup-root {
          .MuiFormControlLabel-root {
            margin-right: 0;
          }
        }
      }
      .check-profile {
        font-size: 4.831vw;
        margin-bottom: 20px;
      }
    }
    padding: 0 20px;
    .profilesection {
      border-radius: 2.415vw;
      padding: 6.2vw 3.1vw 6.2vw;
      margin-bottom: 6.7vw;
      .darkbtn {
        min-height: 10.9vw;
        width: 100%;
      }
    }
    .profilesection {
      .profilepic {
        justify-content: space-between;
        margin-bottom: 6.28vw;
        align-items: flex-start;
        .img_card {
          width: 36.71vw;
          height: 36.473vw;
          border-radius: 4.8vw;
        }
        .changebtn {
          .darkbtn {
            width: 29.951vw !important;
            height: 12vw !important;
            border-radius: 3.6vw !important;
            font-size: 3.864vw !important;
            line-height: 4.83vw !important;
            margin-bottom: 14.4927vw;
          }
          .canclebtn {
            text-align: right;
          }
        }
      }
      .nameemailsection {
        .font18px {
          font-size: 4.347vw !important;
          line-height: 5.7971vw !important;
        }
        .inptbtn {
          margin-bottom: 3.14vw;
          align-items: center;
          .MuiTextField-root {
            width: 58.69vw;
            margin-right: 3vw;
            .MuiOutlinedInput-root {
              border-radius: 3.6vw;
              input {
                font-size: 4.8vw;
                padding-left: 4.34vw;
                padding-top: 3.1vw;
                padding-bottom: 3.1vw;
              }
            }
          }
          .darkbtn {
            width: 22.7vw !important;
            height: 12vw !important;
            border-radius: 2.415vw !important;
            font-size: 3.864vw !important;
            line-height: 4.83vw !important;
            box-shadow: none;
          }
        }
        .greenbtn {
          .darkbtn {
            background-color: #80c86e !important;
          }
        }
      }
    }

    .requestlinksection {
      align-items: flex-start;
      border-radius: 2.415vw;
      padding: 8.696vw 4.831vw !important;
      margin-bottom: 6.7632vw;
      flex-direction: column;
      align-items: center;
      .font20px {
        width: fit-content;
        margin-top: 0;
      }

      .darkbtn {
        width: 100% !important;
        height: 12vw !important;
        border-radius: 2.415vw !important;
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;
      }
    }
    .artistsec_card {
      display: grid;
      .font20px {
        width: 100%;
        margin-top: 0;
      }
      .darkbtn {
        width: 100% !important;
        height: 12vw !important;
        border-radius: 2.415vw !important;
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;
      }
    }
    .shareuri {
      display: flex;
      flex-direction: column;
      gap: 6.039vw !important;
      padding: 8.696vw 4.831vw !important;
      border-radius: 2.415vw;

      .textfiledspan {
        .MuiTextField-root {
          .MuiInputBase-root {
            height: 11vw;
            input {
              font-size: 3.28vw;
            }
          }
        }
      }
      .shareBtn {
        max-width: 100% !important;
        width: 100% !important;
        height: 12vw !important;
        border-radius: 2.415vw !important;
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;
      }
    }
    .skip {
      button {
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;
      }
      .darkbtn {
        width: 35vw;

        height: 12vw;
        border-radius: 2.415vw !important;
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;
      }
    }
    .my-account-new {
      margin-bottom: 5vw;
      button {
        min-width: 35.76vw !important;
        height: 8.971vw;
        border-radius: 2.415vw !important;
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;
      }
    }
    .input-upload-section {
      .upload_section,
      .upload_section_two {
        width: 100%;
        .MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
          margin-bottom: 4.5vw;
        }
      }
      .darkbtn {
        min-width: 31.76vw !important;
        height: 7.971vw !important;
        border-radius: 2.415vw !important;
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;
      }
    }
    .profilesection {
      .nameemailsection {
        .MuiTextField-root {
          .MuiOutlinedInput-root {
            height: 9.5vw;
            border-radius: 5px;
            input {
              font-size: 3.5vw;
              line-height: 4.5vw;
              margin-left: 2.2vw;
            }
          }
        }
      }
      .edit_section_new .darkbtn {
        min-width: 31.76vw !important;
        height: 7.971vw !important;
        border-radius: 2.415vw !important;
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;
      }
    }
  }
  .myprofilerightsection .profile-checks .select_new_btn {
    min-width: 22.946vw !important;
    min-height: 8.48vw;
    font-size: 3.381vw !important;
    line-height: 4.07vw !important;
  }
  .new_contact_dialogbox {
    .contact_inner {
      padding: 4.64vw 6.2vw;
    }
    .message_text {
      margin-bottom: 4.2vw;
    }
    .para_text {
      margin-bottom: 4.5vw;
    }
    button {
      border-radius: 2.415vw !important;
      font-size: 3.864vw !important;
      line-height: 4.83vw !important;
      box-shadow: none;
    }
  }
  .upgrade_dialog_box {
    .contact_inner {
      padding: 8.64vw 6.2vw;
    }
    .para_text {
      margin-bottom: 6vw;
    }
  }
  .sharecard {
    width: 85vw !important;
    .shareInput {
      display: flex;
      flex-direction: column;
      input {
        width: 100% !important;
        margin: 1rem 0;
      }
      button {
        width: 100% !important;
      }
    }
  }
  .sharecard {
    .shareInput input {
      font-size: 2.6vw;
    }
    .share-button {
      width: 7vw;
      height: 7vw;
      svg {
        width: 7vw;
        height: 7vw;
      }
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1280px) {
  .sharecard .shareInput input {
    width: 100% !important;
    margin: 1rem 0;
    font-size: 1.3vw;
  }
  .myprofilerightsection .shareuri .shareBtn {
    max-width: 23.561vw;
    font-size: 1.6vw !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 899px) {
  .myprofilerightsection .profile-checks .select_new_btn {
    min-width: 5.938rem !important;
    font-size: 0.75rem !important;
    min-height: 1.938rem;
  }
  .profile-switch {
    .font14px {
      font-size: 0.75rem;
    }
  }
}
