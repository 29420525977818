.empty-review {
  grid-template-columns: 1fr !important;
  .nodataimg {
    width: 26.4vw;
    margin: 3.2vw auto 0;
  }
  h3 {
    font-size: 1.6vw;
  }
}
.col-flex{
  display: flex;
  flex-direction: column;
  .nodataimg{
    width: 26.4vw;
    margin: 3.2vw auto 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .empty-review {
    .nodataimg {
      width: 30vw;
    }
    h3 {
      font-size: 4.8vw;
    }
  }
  .col-flex{
    .nodataimg{
      width: 30vw;
    }
  }
}

@media screen and (max-width: 600px) {
  .empty-review {
    .nodataimg {
      width: 30vw;
    }
    h3 {
      font-size: 4.8vw;
    }
  }
  .col-flex{
    .nodataimg{
      width: 30vw;
    }
  }
}
