.loginpage {
  .login_box {
    margin: 5vw 0;
    background: #fff;
    border-radius: 1.6vw;
    padding: 2vw 0;
  }
  .locationrightimg {
    width: 34.32vw !important;
    height: 24.72vw !important;
  }
  a.hyper_link {
    color: #000;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: underline;
  }

  .loginregpage {
    padding: 3vw 5.04vw;
    background: #fff;
    box-shadow: 0px 0.32vw 3.2vw 0.04vw rgba(0, 0, 0, 0.3);
    border-radius: 1.6vw;

    .MuiTabs-flexContainer {
      background: #d9d9d9;
      width: fit-content;
      margin: 0 auto;
      border-radius: 0.8vw;
      font-family: 'Inter', sans-serif !important;


      .MuiButtonBase-root.Mui-selected {
        background: #f5445e;
        border-radius: 0.8vw;
        color: #fffcfc;
      }

      button.MuiButtonBase-root {
        font-size: 1.5vw;
        line-height: 1.8vw;
        color: #000;
        opacity: 1;
        min-width: 10.4vw;
        min-height: 4.8vw;
        text-transform: initial;
        font-family: 'Inter', sans-serif !important;

      }
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiBox-root {
      border: none;
    }

    .MuiTabPanel-root {
      padding: 0;
    }

    .signuptab {
      padding: 2.5vw 0 0;
      color: #000;
      .error_text {
        margin-top: 0.4vw;
      }
      .autocomplete {
        margin: 0;
        width: 100% !important;
        .MuiTextField-root {
          margin-bottom: 0;
          .MuiInputBase-root {
            background: #fffcfc;
            height: 2.72vw;
            border-radius: 10vw !important;
          }
          input {
            padding: 0 24px !important;
            width: 100% !important;
            max-width: 100%;
            font-size: 1.28vw;
            &::placeholder {
              color: #000;
              opacity: 1 !important;
              font-family: 'Inter', sans-serif !important;
            }
          }
          button {
            display: none;
          }
        }
      }
      .dateofbirth_input{
        background: #fffcfc;
        height: 2.72vw;
        border-radius: 10vw !important;
        width: 100%;
        border: 1px solid #a5a5a5;
      }
      .selectbox {
        .MuiFormControl-root {
          margin: 0;
          .MuiInputBase-root {
            background: #fffcfc;
            height: 2.72vw;
            border-radius: 10vw !important;
            .MuiSelect-select {
              padding: 0 24px !important;
              font-size: 1.28vw;
              color: #000;
            }
            svg {
              font-size: 2vw;
              right: 0.88vw;
              color: #000;
            }
          }
        }
      }

      .textfiledspan {
        position: relative;

        .visibility-icon {
          position: absolute;
          right: 1.5vw;
          top: 0.9vw;

          svg {
            font-size: 1.92vw;
          }
        }
      }

      .MuiTextField-root {
        width: 100%;
        margin-bottom: 1.04vw;
      }

      .MuiOutlinedInput-root {
        border-radius: 1.2vw;
      }

      input {
        font-size: 1.6vw;
        line-height: 1.9vw;
        padding: 0.7vw 1.68vw;
      }

      .whitebtn {
        font-size: 1.5vw !important;
        line-height: 1.9vw !important;
        min-height: 4vw;
        width: 100% !important;
        text-align: center;
        margin-bottom: 1.04vw;
        display: flex;
        align-items: center;
        padding: 0.9vw 4.165vw !important;
        cursor: pointer;
        border-radius: 1.2vw !important;
      }

      .google-icon {
        margin-right: 3.5vw;
        width: 3.04vw;
        height: 2.8vw;
      }

      .darkbtn {
        font-size: 1.6vw !important;
        line-height: 1.9vw !important;
        min-height: 4vw;
        width: 100% !important;
        text-align: center;
        border-radius: 1.2vw !important;
        box-shadow: none !important;
      }

      .ortext {
        font-size: 1.6vw;
        line-height: 1.9vw;
        text-align: center;
        margin: 0.9vw;

        a {
          color: #000;
          text-decoration: underline;
        }
      }
      .forgot-text {
        margin-top: 3.68vw;
      }

      .MuiTypography-root {
        font-size: 0.96vw;
        line-height: 1.15vw;
        font-family: 'Inter', sans-serif !important;
        color: #000;
      }

      .tnccheckboxlogin {
        margin: 1vw 0vw 2vw;

        .Mui-checked {
          svg {
            background: transparent;
            border-radius: 5px;

            path {
              color: #d9d9d9;
            }
          }
        }

        svg {
          background: #d9d9d9;
          border-radius: 5px;
          color: #d9d9d9;
          path {
            color: transparent;
          }
        }
      }
    }
    .aboutsection {
      padding: 0;
    }
  }

  .textsection {
    padding: 3vw 2.5vw;

    h2.loginhead {
      font-size: 3.2vw;
      line-height: 3.8vw;
      font-weight: 400 !important;
    }

    ul.loginlisttext {
      padding-inline-start: 0;
    }

    ul.loginlisttext li {
      display: flex;
      align-items: center;
      margin-bottom: 1vw;

      img {
        margin-right: 0.8vw;
      }
    }

    .locationrighthead {
      font-weight: 400;
    }
  }
  .aboutus_right_text{
    padding-top: 0;
    h2{
      margin-top: 0;
    }
  }

  .textfiledspan {
    .MuiFormControl-root {
      .MuiInputBase-root {
        input {
          &::placeholder {
            color: #d9d9d9 !important;
            opacity: 1 !important;
            font-family: 'Inter', sans-serif !important;
          }
        }
      }
    }
  }
}

//password screen css

.loginpassword {
  padding-top: 7.9vw;
  padding-bottom: 22vw;
  .resetpassword-heading {
    padding-right: 11vw;
    margin: 0;
  }
  .password {
    .darkbtn {
      margin-top: 0.88vw;
    }
  }

  .font20px {
    text-align: left;
  }
}

.aboutyourself {
  padding-bottom: 3vw;
  padding-right: 1.5vw;
  .well-text {
    margin-left: 0.8vw;
    margin-bottom: 0vw;
  }
  .MuiOutlinedInput-root{
    height: 2.92vw;
    border-radius: 10vw !important;
  }
   input {
    font-size: 1.28vw !important;
   }
  .nearest-text {
    margin: 1.5vw 0 1vw;
    margin-left: 0.8vw;
  }
  .darkbtn {
    margin-top: 2.8vw;
  }

  .skiptext {
    text-decoration: underline;
    text-align: center;
    color: #f5445e;
    margin-top: 3.28vw;
    a {
      color: #f5445e;
    }
    a:hover {
      color: #f5445e;
    }
  }
  .profilepic {
    display: flex;
    align-items: center;
    column-gap: 0.96vw;
    width: 100%;
    margin: 1.68vw 0;
    .img_card {
      border-radius: 50%;
      width: 6vw;
      height: 6vw;
      img {
        object-fit: cover !important;
        width: 6vw;
        height: 6vw;
      }
    }
    .changebtn {
      width: 100%;
      .whitebtn {
        border: 1px solid #a5a5a5;
        border-radius: 1.2vw !important;
        padding: 0 !important;
        height: 2.72vw !important;
        margin: 0;
        color: #6b6969;
        font-size: 1.28vw !important;
        min-height: 0;
      }
    }
    .whitebtn {
      border: 1px solid #a5a5a5;
      border-radius: 1.2vw !important;
      padding: 0 !important;
      height: 2.72vw !important;
      margin: 0;
      color: #6b6969;
      font-size: 1.28vw !important;
      min-height: 0 !important;
    }
  }
}
.algorithm-text {
  .font40px {
    font-size: 2.4vw;
    line-height: 2.88vw;
  }
  .textsection {
    padding: 0.3vw 3vw 3vw 2.5vw;
  }
}

@media only screen and (max-width: 600px) {
  .algorithm-text {
    .font40px {
      font-size: 30px;
      line-height: 36px;
    }
  }

  .aboutyourself {
    .skiptext {
      margin-top: 18px;
    }
    input{
      font-size: 4.28vw !important;
    }
    .profilepic {
      display: flex;
      align-items: center;
      column-gap: 1vw;
      margin: 2.5vw 0;
      .img_card {
        width: 16vw;
        height: 16vw;
        img {
          width: 16vw;
          height: 16vw;
        }
      }
      .changebtn {
        .whitebtn {
          border-radius: 3.623vw !important;
          height: 8.213vw !important;
          font-size: 3.865vw !important;
          margin: 0 !important;
        }
      }
      .whitebtn {
        border-radius: 3.623vw !important;
        height: 8.213vw !important;
        font-size: 3.865vw !important;
        margin: 0 !important;
      }
    }
  }

  .loginpage {
    .login_box {
      .textleft {
        text-align: left;
        color: #000;
      }
    }
    .locationrightimg {
      width: 100% !important;
      height: auto !important;
      position: relative !important ;
    }
    .selectbox .selectboxwidth,
    .multiselect .selectboxwidth {
      margin-bottom: 14px;
    }

    .login_box {
      box-shadow: none;
    }

    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
      font-size: 20px;
      line-height: 24px;
      padding: 16px 14px 14px;
    }

    .loginhead {
      text-align: center;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 35px;
      padding: 5px;
    }

    .textsection ul.loginlisttext li {
      margin-bottom: 22px;

      img {
        margin-right: 15px;
      }
    }

    .all-screen-container,
    .css-1oqqzyl-MuiContainer-root {
      max-width: 100% !important;
    }

    .loginregpage {
      padding: 29px 30px 58px;
      border-radius: 20px;

      .signuptab {
        padding: 60px 0 0;
        .error_text {
          margin: 3vw 0;
          font-size: 3.865vw;
        }
        .dateofbirth_input{
          height: 10.87vw;
          border-radius: 3.623vw !important;
        }
        .autocomplete {
          .MuiTextField-root {
            .MuiInputBase-root {
              height: 10.87vw;
              border-radius: 3.623vw !important;
            }
            input {
              padding: 0 12px !important;
              font-size: 4.28vw;
            }
            button {
              display: none;
            }
          }
        }
        .selectbox {
          .MuiFormControl-root {
            margin: 0;
            .MuiInputBase-root {
              height: 10.87vw;
              border-radius: 3.623vw !important;
              .MuiSelect-select {
                padding: 0 24px !important;
                font-size: 4.28vw;
              }
              svg {
                font-size: 6vw;
                right: 3vw;
              }
            }
          }
        }

        input {
          font-size: 20px;
          line-height: 24px;
          padding: 11px 17px;
        }

        .MuiTextField-root {
          width: 100%;
          margin-bottom: 1.04vw;
          margin-bottom: 13px;
        }

        .MuiOutlinedInput-root {
          border-radius: 15px;
          height: 10.87vw;
          border-radius: 3.623vw !important;
        }

        .darkbtn,
        .graybtn {
          min-height: 46px;
          font-size: 20px !important;
          line-height: 24px !important;
          border-radius: 15px !important;
        }

        .whitebtn {
          min-height: 50px;
          font-size: 4.3vw !important;
          line-height: 4.5vw !important;
          border-radius: 15px !important;
          margin-bottom: 11px;
        }

        .google-icon {
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }

        .graybtn {
          margin-bottom: 11px;
        }

        .ortext {
          margin: 11px;
          font-size: 20px;
          line-height: 24.5px;
        }
        .forgot-text {
          margin-top: 30px;
        }
        .MuiTypography-root {
          font-size: 12px;
          line-height: 16px;
        }

        .tnccheckboxlogin {
          margin: 36px 0 32px;

          .MuiFormControlLabel-root {
            margin-right: 0;
          }
        }
        .iconoverlap input[type='password'] {
          padding-right: 45px;
        }
        .textfiledspan .visibility-icon {
          right: 3vw;
          top: 12px;

          svg {
            font-size: 25px;
            width: 25px;
            height: 25px;
          }
        }
      }

      .MuiTabs-flexContainer {
        border-radius: 10px;

        button.MuiButtonBase-root {
          min-width: auto;
          min-height: 51px;
          font-size: 16px;
          line-height: 19px;
          padding: 12px 20px;
          border-radius: 2.4vw !important;
        }

        .MuiTab-textColorInherit.Mui-selected {
          border-radius: 10px;
          color: #fffcfc;
          min-width: 102px;
        }
      }

      
    }
  }

  .forgotpage {
    margin-bottom: 50px;
    .textsection {
      padding: 3.5vw 2.5vw;
    }
    .forgotpassword {
      padding: 23px 31px 23px;
    }

    .font20px {
      margin-bottom: 0;
    }

    .loginpassword {
      padding: 0;
    }

    .darkbtn {
      margin-top: 60px !important;
      min-height: 51px !important;
    }
  }
  .confirmpassword {
    .loginregpage {
      padding: 29px 30px 58px;
    }

    .loginpassword .font20px {
      padding-top: 27px;
      text-align: left;
    }
    .password {
      padding: 13px 0px 0px !important;
    }
    .loginpassword .password .darkbtn {
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .loginpage {
    .loginregpage {
      .signuptab {
        input,
        .ortext,
        .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
          font-size: 16px;
          line-height: 24px;
        }
        .MuiTypography-root {
          font-size: 11px;
          line-height: 15px;
        }
        .darkbtn,
        .graybtn {
          font-size: 16px !important;
          line-height: 20px !important;
        }
      }
      .MuiTabs-flexContainer {
        button.MuiButtonBase-root {
          width: 85px;
          font-size: 14px;
          padding: 12px 13px;
        }
        .MuiTab-textColorInherit.Mui-selected {
          border-radius: 10px;
          color: #fffcfc;
          min-width: 85px;
        }
      }
    }
  }
}
@media (min-width: 930px) and (max-width: 1280px) {
  .textsection {
    .font18px {
      font-size: 1.3vw;
    }
  }
}
@media only screen and (min-width: 1920px) {
  .loginpage {
    .loginregpage {
      padding: 3vw 4vw;

      .signuptab {
        input {
          padding: 1vw 1.68vw;
        }

        .tnccheckboxlogin {
         
          .MuiFormGroup-root {
            margin-bottom: 1vw;
          }
          .MuiSvgIcon-root {
            width: 1.5vw;
            height: 1.5vw;
          }

          .MuiCheckbox-root {
            padding-right: 0.6vw;
          }
        }
      }
    }

    ul.loginlisttext li {
      img {
        width: 2vw;
        height: 2vw;
      }
    }
  }

  .textsection {
    h2.loginhead {
      font-size: 2.8vw;
      line-height: 3.2vw;
    }
  }
}
@media only screen and (min-width: 1930px) {
  .loginpage {
    .loginregpage {
      .signuptab {
        .whitebtn {
          font-size: 1.4vw !important;
          padding: 0.9vw 2.165vw !important;
        }
        input,
        .ortext {
          font-size: 1.2vw;
          line-height: 1.5vw;
        }
      }
    }
  }
}
