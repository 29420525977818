.reset-password {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 5% auto;

  .reset-form {
    .resend-otp {
      display: flex;
      gap: 10px;
      align-items: center;

      .resend-achor-tag{
        color: #EC425B;
        cursor: pointer;
        &:hover{
          color: #EC425B;
        }
      }

      .graybtn {
        width: fit-content !important;
        padding: 10px 14px !important;
        font-size: 1rem !important;
      }
    }
  }

  .main-title {
    font-size: 1.6vw;
    margin-bottom: 2rem;
  }

  .pop-content {
    padding: 0;
  }

  .textfiledspan {
    position: relative;

    .visibility-icon {
      position: absolute;
      right: 1vw;
      top: 1.3vw;
    }

    input {
      width: 85%;
    }
  }

  .reset-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .textfiledspan {
      margin-top: 2px;
    }

    .textfiledspan {
      .iconoverlap {
        width: 100%;

        .MuiInputBase-root {
          border-radius: 0.4vw;
          height: 4vw;
          font-size: 1.6vw;
        }
      }

      .visibility-icon {
        svg {
          font-size: 1.5vw;
        }
      }

      .error_text {
        padding-top: 10px;
      }
    }

    .darkbtn {
      box-shadow: none;
      padding: 0.96vw 0 !important;
      
    }

    .graybtn {
      padding: 0.96vw 0 !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .reset-password {
    .main-title {
      font-size: 1rem;
      padding-left: 0;
      padding-right: 0;
    }

    .reset-form {
      .textfiledspan {
        .iconoverlap {
          .MuiInputBase-root {
            border-radius: 2.415vw;
            height: 12.319vw;
            font-size: 3.6vw;
          }
        }

        .visibility-icon {
          right: 3vw;
          top: 4.1vw;

          svg {
            font-size: 4.5vw;
          }
        }

        .error_text {
          font-size: 3.623vw;
          padding-top: 2.415vw;
        }
      }

      .darkbtn {
        height: 12.319vw;
      }

      .graybtn {
        height: 12.319vw;
      }
    }
  }
}

@media screen and (min-width: 1930px) {
  .reset-password .textfiledspan .visibility-icon {
    top: 1.3vw;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .reset-password .textfiledspan .visibility-icon {
    top: 0.9vw;
  }
}

@media screen and (min-width: 250px) and (max-width: 1024px) {
  .reset-password {
    .reset-form {
      .resend-otp {
        flex-direction: column;

        .graybtn {
          line-height: 1.5rem !important;
        }
      }
    }
  }
}