.StripeElement {
  padding: 25px 12px;
  border: 1px solid #e6ebf1;
  border-radius: 0.4vw;
  box-shadow: none;
  transition: box-shadow 150ms ease;
  width: 100%;
  font-family: 'Inter', sans-serif !important;

  letter-spacing: 0.025em;
  text-transform: uppercase;
  background-color: #fffcfc;
  margin-bottom: 10px;
}

.main-pop-up{
  .darkbtn{
    cursor: pointer;
  }
}



.StripeElement--invalid {
  border-color: #e53e3e;
}



@media screen and (max-width: 600px) {
  .StripeElement {
    padding: 12px;
    border-radius: 1.2vw;
  }
}
