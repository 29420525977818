.paginationselectbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6.72vw;
    .selectbox{
        .MuiSelect-select.MuiSelect-outlined{
            display: flex;
            align-items: center;
        }
    }
    .MuiPagination-ul li button{
    width: 4.56vw;
    height: 4vw;
    font-size: 1.6vw;
    border-radius: 1.2vw;
    margin-left: 0;
    }
    .MuiPaginationItem-previousNext.Mui-disabled{
        opacity: 1;
    }
    .MuiPaginationItem-previousNext{
        svg{
            font-size: 3.5vw;
        }
    }
    .selectbox{
        width: 19.68vw;
        .selectboxwidth {
            width: 19.68vw;
            margin: 0;
        }
        .select-field{
            width: 19.68vw;
            border-radius: 1.2vw;
            height: 3.68vw;
        }
        .MuiSelect-select{
            padding-top: 0;
            padding-bottom: 0;
            font-size: 1.6vw;
        }
        svg{
            color:#000;
            font-size: 1.6em;
        }
    }
    .MuiPaginationItem-ellipsis{
        font-size: 1.6vw;
        line-height: 2vw;
        color: #000;
    }
}
@media only screen and (max-width: 600px) {
    .paginationselectbox {
        flex-direction: column-reverse;
        margin-bottom: 2.5em;
       .selectbox {
        width: 70%;
        margin: 10px auto 41px;
        .select-field {
            width: 100%;
            border-radius: 15px;
            height: 11.59vw;
            margin: 0 auto;
        }
        .selectboxwidth {
            width: 100%;
        }
        .MuiSelect-select {
            font-size: 4.00vw;
            line-height: 5.79vw;
        }
        svg{
            font-size: 5em;
        }
       }
       .MuiPagination-ul li button {
        width: 8.83vw;
        height: 8.38vw;
        border-radius: 10px;
        font-size: 4.347vw;
        line-height: 5.7971vw;
       }
      .MuiPaginationItem-previousNext svg {
        font-size: 1.8em;
       }
       .MuiPaginationItem-ellipsis {
        font-size: 5vw;
        line-height: 5vw;
       }
    }
}
@media (min-width:920px) and (max-width: 1024px) {
    .paginationselectbox .MuiPagination-ul li button {
        width: 3.56vw;
        height: 3.5vw;
        font-size: 1.2vw;
    }
    .paginationselectbox .selectbox .MuiSelect-select {
        font-size: 1.2vw;
    }
}
@media only screen and (min-width: 1930px) {
    .paginationselectbox {
        .MuiPaginationItem-ellipsis{
            font-size: 20px;
            line-height: 25px;
        }
        .MuiPagination-ul li button{
            width: 3vw;
            height: 3vw;
        }
        .selectbox{
            width: 13vw;
            .MuiFormControl-root{
                width: 100% !important;
                .MuiInputBase-root{
                    width: 100%;
                }
            }
            .MuiSelect-select{
                font-size: 1vw;
            }
            .select-field, .selectboxwidth{
                width: 13vw;
            }
        }
    }
}