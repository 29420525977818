.noficationcontainer {
  background: rgb(236, 66, 91);
  border-radius: 20px 20px 0px 0px;
  padding: 33px;
  text-align: center;
  color: rgb(255, 255, 255);
  position: relative;
  .heading-text {
    font-size: 30px;
  }
  .cross {
    position: absolute;
    top: 40%;
    right: 37px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
.notification-box {
  padding: 0 40px;
  margin: 40px 0;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  max-height: 500px;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 1vw;
    background: #afaeae;
    border-radius: 5vw;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 25vw;
  }
  &::-webkit-scrollbar-thumb {
    background: #f5445e;
    border-radius: 5vw;
  }
  .main-heading {
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 47px;
    text-align: center;
  }
  p {
    font-size: 18px;
    padding: 0 40px;
  }
  .top-heading {
    padding-bottom: 2.24vw;
  }
  .date {
    padding-bottom: 5.12vw;
  }
  .darkbtn {
    box-shadow: none;
    width: 184px;
    height: 45px;
    font-size: 20px !important;
    border-radius: 15px !important;
  }
}

@media screen and (max-width: 600px) {
  .notification-box {
    max-height: 320px;
    .main-heading {
      font-size: 25px;
    }
  }
}
