@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
// 1. Configuration and helperss
@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';

// 2. Vendors
@import 'vendors/normalize';

// 3. Base stuff
@import 'base/base', 'base/fonts', 'base/typography', 'base/helpers';
// 4. Layout-related sections
@import 'layout/header.scss', 'layout/footer.scss', 'layout/mainmenu.scss', 'layout/mobilemenu.scss';

// 5. Components
@import 'components/button', 'components/paymentStripForm.scss', 'universal/inputType.scss',
  'universal/profileCard.scss', 'universal/mainskeleton.scss', 'components/dialogs/JoinOrSignIn.scss',
  'components/sidebar.scss', 'components/dashboardlayout/dashboardlayout.scss', 'components/cardContainer.scss',
  'components/notification/notification.scss', 'universal/notificationpopup.scss',
  'components/dialogs/ResetPassword.scss', 'components/accordion.scss', 'components/message/messageCard.scss',
  'components/message/msgPreviewBox.scss', 'universal/msgPreviewBox.scss', 'universal/orderTable.scss',
  'universal/artistscard.scss', 'universal/allnotification.scss', 'universal/badge.scss', 'universal/pagination.scss',
  'components/stripcard.scss', 'universal/blurcontainer.scss', 'universal/allpopup.scss',
  'universal/nodatacontainer.scss', 'components/newlabelinputs.scss', 'components/dialogs/ContactArtist.scss';

// 6. Page-specific styles
@import 'pages/home', 'pages/loginpage.scss', 'pages/Sitemap.scss', 'pages/sell-dashboard.scss',
  'pages/seller-account.scss', 'pages/bio_profile.scss', 'pages/mysubscription.scss', 'pages/contactpage.scss',
  'pages/alartistsfollowed.scss', 'pages/savedartworks.scss', 'pages/artworkscollection.scss',
  'pages/collectionform.scss', 'pages/allmessage.scss', 'pages/addartworkpage.scss', 'pages/allreivew.scss',
  'pages/artfollower.scss', 'pages/orderDetail.scss', 'pages/certificate.scss', 'pages/myaddress.scss',
  'pages/shippingaddress.scss', 'pages/university.scss', 'pages/orderpopup.scss', 'pages/invoice.scss',
  'pages/supportpage.scss', 'pages/pdfcertificate.scss', 'pages/faqpage.scss', 'pages/retrunpolicy.scss',
  'pages/discountCode', 'pages/newArtistPage.scss', 'pages/pricing-tool.scss', 'pages/academyNew.scss', 'pages/directory_filter_screen',
  'pages/artist-statement',  'pages/portfolio-settings', 'pages/UnlockCourseCard'
  ;


// 7. Themes
@import 'themes/default';

body {
  overflow-x: hidden;
  font-family: 'Inter', sans-serif !important;
}
.sharecard.MuiBox-root{
  text-align: center;
}
.error_message_btn{
  background-color: #f5445e !important;
  box-shadow: none !important;
  font-family: 'Inter', sans-serif !important;
}
.error_message_btn:hover{
    background-color: #f5445e !important;
    box-shadow: none !important;
   
}

.inner_gray_theme, .MuiInputBase-root, .textareabox , .ql-editor, .ql-container, .ql-toolbar{
    font-size: 1rem;
    line-height: 1.4375em;
  }
  .ql-snow .ql-editor h1{
    line-height: 2.5rem;
  }
.cv_builder_table {
  scrollbar-width:thin;
}
/* Firefox */
.cv_builder_table {
  scrollbar-width: thin;
  scrollbar-color: #afaeae #f1f1f1;
}

/* Chrome, Safari */
.cv_builder_table::-webkit-scrollbar {
  width: 5px; /* Adjust width */
  border-radius: 25px;
}

.cv_builder_table::-webkit-scrollbar-thumb {
  background: #afaeae;
  border-radius: 25px; /* Rounded edges */
}

.cv_builder_table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}
.btn-class{
  width: 100% !important;
}
.customTooltip{
  .MuiTooltip-tooltip.MuiTooltip-tooltipArrow{
    padding: 0 !important;
  }
}
.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper{
 box-shadow: -1px 3px 2px 3px rgb(128 128 128 / 5%);
 border-radius: 15px;
}
.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper{
box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
}
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters:hover{
  background-color: #f5445e;
  color: #fff;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  font-family: 'Inter', sans-serif !important;

  font-weight: 400 !important;
}
.MuiFormLabel-root, .MuiTypography-root, .MuiInputBase-input, .MuiButtonBase-root {
  font-family: 'Inter', sans-serif !important;

}
.MuiBadge-badge.MuiBadge-standard{
  background-color: #f5445e;
  color: #fff;
}
.form-box {
  :focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
}

//new css 

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .ReactCrop__child-wrapper{
    height:310px;
  }
}

.upload-box {
  width: 800px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  border: 2px dashed #ccc;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.upload-icon {
  font-size: 50px;
  color: black;
}

.upload-box p {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  color: black;
}

//new css end 
.swal2-center,
.swal2-backdrop-show,
.swal2-container {
  z-index: 101 !important;
}

:focus-visible {
  outline: none;
}
.padd_new_top{
  padding-top: 1.3vw  !important;
}
.upload-file_new {
  background: #fff;
  padding: 0px !important;
  height: 7vw;
  width: 7vw;

  border: none !important;
  .upload_full_img {
    width: 100%;
    height: -webkit-fill-available;
    object-fit: cover;
  }
  .upload_default_img {
    width: 7.44vw;
    height: 7.44vw;
  }
  .profile_text {
    text-transform: initial;
    color: #000;
    margin-top: 2vw;
  }
}
.MuiTooltip-tooltip.MuiTooltip-tooltipArrow{
  div{
    font-weight: 500 !important;
    font-family: 'Inter', sans-serif !important;

  }
}
.info_btn_new_dashboard{
  min-width: fit-content !important;
  padding: 0 !important;
  svg{
    width: 20px;
    height: 20px;
    color: #000000;
  }
}
.info_btn_new_dashboard:hover{
  background-color: transparent !important;
}
.upload_section{
  
  position: relative;
  width: fit-content;
  .edit_icon_section{
    min-width: auto !important;
    position: absolute;
    right: -24px;
    top: -16px;
    background-color: #c9c4c4;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      cursor: pointer;
    }
  }
}
.upload-file_new:hover {
  background-color: transparent !important;
}
button.read-morebtn {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #f5445e;
  padding: 0;
}

// universial font size
.font25px {
  font-size: 1.8vw !important;
  line-height: 2.2vw !important;
}

.font20px {
  font-size: 1.4vw;
  line-height: 1.72vw;
}
.font20pxtypo {
  font-size: 1.4vw !important;
  line-height: 1.72vw !important;
}

.Link-text {
  font-size: 1.4vw !important;
  line-height: 1.72vw !important;
}

.font14px {
  font-size: 1.12vw;
  line-height: 1.34vw;
}
.font14pxtable{
  font-size: 1.12vw !important;
  line-height: 1.34vw !important;
}
.font12px {
  font-size: 0.96vw;
  line-height: 1.15vw;
}
.font12pxNew {
  font-size: 0.96vw !important;
  line-height: 1.15vw !important;
  color: #000000;
}
.font18px {
  font-size: 1.3vw;
  line-height: 1.68vw;
}

.font16px {
  font-size: 1.28vw;
  line-height: 1.6vw;
}

.font15px {
  font-size: 1.2vw;
  line-height: 1.44vw;
}
.font15pxtable{
  font-size: 1.2vw;
  line-height: 1.44vw;
}
.font30px {
  font-size: 2vw;
  line-height: 2.88vw;
}
.font30pxtypo{
  font-size: 2vw !important;
  line-height: 2.88vw !important;
}

.font35px {
  font-size: 2.8vw;
  line-height: 3.2vw;
}

.font40px {
  font-size: 3.2vw;
  line-height: 3.84vw;
}

.buy-art-menu,
.basket-icon {
  .font12px,
  a {
    font-size: 0.96vw !important;
    line-height: 1.15vw !important;
  }
  .font14px {
    font-size: 1.12vw !important;
    line-height: 1.34vw !important;
  }
}
.basket-icon {
  .subnav a {
    font-size: 1.12vw !important;
  }
}
.font10px {
  font-size: 0.8vw !important;
  line-height: 1.2vw !important;
}
.font10px {
  a {
    font-size: 0.8vw !important;
    line-height: 1.2vw !important;
  }
}


// unversal mobile hide and mobile show css
.mobilehide {
  display: block;
}

.mobileshow {
  display: none;
}


.tabhide {
  display: block;
}

.tabshow {
  display: none;
}
.all-screen-container // ,.css-1oqqzyl-MuiContainer-root
{
  max-width: 90% !important;
}
.message_container{
  max-width: 100% !important;
}
.MuiDrawer-modal .MuiDrawer-paper {
  width: 100% !important;
}

//dialogs box border radius
.MuiPaper-root.MuiDialog-paper {
  border-radius: 1.2vw;
  min-width: 360px;
  min-height: 201px;
  max-width: 80vw !important;
  pointer-events: auto;
  width: 38.89vw !important;
}

.error_message {
  color: red;
  margin-top: 0.8vw;
  font-size: 1vw;
  line-height: 1.2vw;
  margin-left: 0.5vw;
}

.error_text {
  color: red;
  margin-bottom: 0.8vw;
  font-size: 1vw;
  line-height: 1.2vw;
  margin-top: 5px;
}
.error_text_updated{
  color: red !important;
  margin-bottom: 0.8vw !important;
  font-size: 1vw !important;
  line-height: 1.2vw !important;
  margin-top: 5px !important;
}
.textfiledspan {
  .MuiFormControl-root {
    .MuiInputBase-root {
      input {
        &::placeholder {
          color: black !important;
          font-family: 'Inter', sans-serif !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

.textareabox {
  .MuiFormControl-root {
    .MuiInputBase-root {
      textarea {
        &::placeholder {
          color: black !important;
          font-family: 'Inter', sans-serif !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.MuiInputBase-root {
  &::after {
    border-bottom: none !important;
  }

  &::before {
    border-bottom: none !important;
  }
}

@media screen and (max-width: 600px) {
  .mobileshow {
    display: block;
  }
 
}
@media screen and (max-width: 786px) {}

@media only screen and (max-width: 600px) {
  // mobile hide show css
.directory_dialog_box{
  .MuiPaper-root.MuiDialog-paper{
    min-width: 80%;
    width: 80% !important;
  }
  .MuiDialogContent-root{
    padding: 20px 15px;
  }
}
  .padd_new_top{
    padding-top: 0.8rem !important;
  }
  .mobilehide {
    display: none;
  }
  .upload-file_new {
    height: 23vw;
    width: 23vw;
min-height: 23vw;

  }
  .Link-text {
    font-size: 4.83vw !important;
    line-height: 6vw !important;
  }

  // universal font css
  .font18px {
    font-size: 4.347vw;
    line-height: 5.7971vw;
  }

  .font20px {
    font-size: 4.83vw;
    line-height: 6vw;
  }
  .font20pxtypo {
    font-size: 4.83vw !important;
    line-height: 6vw !important;
  }
  .font10px {
    font-size: 2.4154vw !important;
    line-height: 3.6vw !important;
  }
  .font10px {
    a {
      font-size: 2.4154vw !important;
      line-height: 3.6vw !important;
      color: #000000;
    }
  }
  .font25px {
    font-size: 6.0386vw !important;
    line-height: 7.246vw !important;
  }

  .font30px {
    font-size: 7.246vw;
    line-height: 8.724vw;
  }
  .font30pxtypo{
    font-size: 7.246vw !important;
    line-height: 8.724vw !important;
  }

  .font12px {
    font-size: 2.898vw;
    line-height: 3.5vw;
  }
  .font12pxNew {
    font-size: 2.898vw !important;
    line-height: 3.5vw !important;
  }

  .font16px {
    font-size: 3.864vw;
    line-height: 4.83vw;
  }

  .font15px {
    font-size: 3.62vw;
    line-height: 4.362vw;
  }
.font15pxtable{
  font-size: 3.62vw;
    line-height: 4.362vw;
}
  .font40px {
    font-size: 9.66vw;
    line-height: 11.59vw;
  }

  .font14px {
    font-size: 3.381vw;
    line-height: 4.07vw;
  }
.font14pxtable{
  font-size: 3.381vw !important;
  line-height: 4.07vw !important;
}
  .font35px {
    font-size: 8.454vw;
    line-height: 10.17vw;
  }
  .buy-art-menu,
  .basket-icon {
    .font14px {
      font-size: 3.381vw !important;
      line-height: 4.07vw !important;
    }
    .font12px,
    a {
      font-size: 2.898vw !important;
      line-height: 3.5vw !important;
    }
  }

  .error_text,
  .error_message {
    margin-bottom: 1.8vw;
    font-size: 3.6vw;
    line-height: 3.5vw;
    position: relative;
    margin-top: 5px;
  }
  .error_text_updated{
    margin-bottom: 1.8vw !important;
    font-size: 3.6vw !important;
    line-height: 3.5vw !important;
    position: relative;
    margin-top: 5px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .tabshow {
    display: block;
  }
  .tabhide{
    display: none;
  }
}
.react-player{
  width: 100% !important;

  body.vp-center{
    width: 100% !important;
  }
}
div.ContentAreaBackground_module_imgContainer__90d1a7f8{
  display: block !important;
}