.send-message {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
    padding: 0;
  }
  width: 100%;
  min-width: 394px;
  position: relative;
  column-gap: 12px;
  display: flex;
  flex-direction: column;

  textarea::placeholder {
    color: #000;
    font-family: 'Inter', sans-serif !important;
  }

  textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000;
  }

  textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #000;
  }


  .avatar_new-img {
    width: 5.52vw;
    height: 5.52vw;
  }
  .msg_avatar {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .messagesbox {
    height: 315px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    direction: ltr;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 1.2vw;
      background: #afaeae;
      border-radius: 25vw;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 25vw;
    }
    &::-webkit-scrollbar-thumb {
      background: #f5445e;
      border-radius: 25vw;
    }

    .message-preview-box {
      background: #ffffff;
      border: 0.2px solid #00000069;
      border-radius: 15px;
      padding: 25px 21px;
      position: relative;
      margin-right: 5px;
      width: 100%;
      span {
        padding-left: 10px;
      }
    }

    .date-text {
      position: absolute;
      left: 21px;
      font-size: 12px;
    }
  }

  .form-box {
    display: flex;
    flex-direction: column;
    gap: 26px;

    textarea {
      border-radius: 15px;
      height: 14.64vw;
      margin-top: 26px;
      padding: 21px;
      resize: none;
      background: #ffffff;
      border: 0.2px solid #00000069;
    }
    .darkbtn {
      padding: 15px 0 15px !important;
      border-radius: 15px !important;
      box-shadow: none !important;
      text-transform: capitalize !important;
      font-size: 1.6vw !important;
      line-height: 1.8vw !important;
    }
    .message_last_submit_btn {
      display: flex;
      justify-content: flex-end;
      .darkbtn, .graybtn {
        padding: 10px 0 10px !important;
        min-width: 7.5vw !important;
        font-size: 0.96vw !important;
        line-height: 1.15vw !important;
      }
      .graybtn{
        background-color: #e2e2e2 !important;
      }
    }
  }
  .form-container {
    width: 100%;
  }
}

.verify_user_card {
  border: 2px solid #f5445e;
  background-color: #fffcfc;
  padding: 0.8vw 1.2vw;
  border-radius: 0.4vw !important;
  box-shadow: none !important;
  margin-bottom: 1.92vw;
}
.error_icon {
  position: absolute;
  top: -13px;
  right: 12px;
  z-index: 9;
  color: #f5445e;
}
.reply_section {
  display: flex;
  justify-content: end;
  margin-top: 8px;
}
.reply_email {
  background-color: #e2e2e2 !important;
  font-size: 0.96vw !important;
  line-height: 1.15vw !important;
  min-width: 14.96vw !important;
  border-radius: 0 !important;
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .send-message {
    min-width: 31.488vw;
    .messagesbox {
      .message-preview-box {
        .message-text {
          font-size: 1.25rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 4000px) {
  .send-message {
    .messagesbox {
      .message-preview-box {
        width: 98%;
      }
    }
    .form-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .send-message {
    .avatar_new-img {
      width: 9.52vw;
      height: 9.52vw;
    }
    .message-text{
      font-size: 5vw;
      line-height: 6vw;
    }
    .reply_email{
      font-size: 3.864vw !important;
      line-height: 4.83vw !important;
      width: 100%;
    }
    .form-box {
      .darkbtn {
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;
      }
      .whitebtn {
        font-size: 3.864vw !important;
        line-height: 4.83vw !important;
      }
      textarea {
        height: 42.27vw;
      }
    }
   
    min-width: auto;
    .messagesbox {
      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
        border-radius: 0;
      }
      &::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 25vw;
      }
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 0;
      }
    }
  }
  .reply_email {
    font-size: 3.898vw !important;
    line-height: 4.5vw !important;
    min-width: 44.6859vw !important;
    height: 10vw;
    width: 100%;
  }
  .verify_user_card{
    padding: 2.5vw 3.5vw;
    border-radius: 1.2vw !important;
  }
}
