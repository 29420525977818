.loginsignin {
  .MuiPaper-root.MuiPaper-elevation {
    padding: 3.5vw 1.84vw 1.4vw;
    overflow: visible;
  }

  .dialogHeading {
    text-align: center;
    padding: 0;
    color: #000;
    font-size: 2vw !important;
    line-height: 2.4vw !important;
  }
  .btn-section {
    display: flex;
    justify-content: center;
    margin-top: 1.5vw;
  }
  .darkbtn {
    border-radius: 0.8vw !important;
    padding: 0 !important;
    width: 14.9vw;
    height: 5.3vw;
    box-shadow: none;
  }
  .artistbtnset {
    margin-right: 0.8vw;
  }
  .clear-icon {
    svg {
      position: absolute;
      right: -1.5vw;
      top: -1.5vw;
      background: #fff;
      border-radius: 40px;
      z-index: 9999;
      width: 50px;
      height: 50px;
      padding: 15px;
      box-shadow: 0px 0.32vw 0.32vw rgba(0, 0, 0, 0.25);
    }
  }
  .already-text {
    color: #000000;
    margin-top: 2vw;
    text-align: center;
    text-decoration: underline;
    font-family: 'Inter', sans-serif !important;

  }
}
@media only screen and (min-width: 1930px) {
  .loginsignin {
    .clear-icon {
      svg {
        width: 3.2vw;
        height: 3.2vw;
        border-radius: 50vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .loginsignin {
    .MuiPaper-root.MuiPaper-elevation {
      padding: 20px 24px;
    }
    .font25px {
      text-align: center;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .aretext {
      margin-top: 29px;
      color: #000;
      margin-bottom: 40px;
    }
    .clear-icon {
      svg {
        box-shadow: none;
        position: relative;
        border-radius: 50%;
        padding: 5px;
        width: 24px;
        height: 24px;
        color: #fff;
        background-color: #f5445e;
        right: 0;
        left: 90%;
        top: 0;
      }
    }
    .or_text {
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 31px;
      text-align: center;
      margin-top: 14px;
      margin-bottom: 19px;
      color: #000000;
    }
    .btn-section {
      display: block;
      text-align: center;
    }
    .artistbtn {
      margin-right: 0vw;
    }
    .darkbtn {
      padding: 20px !important;
      font-size: 20px !important;
      line-height: 24px;
      width: 136px;
      height: 62px;
      border-radius: 2.415vw !important;
    }
    .already-text {
      margin-top: 25px;
    }
  }
}
