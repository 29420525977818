.directory_filter_screen {
    .filter_text {
        margin-left: 1vw;
        margin-bottom: 0.5vw;
    }

    .mainheadart {
        margin-top: 3vw;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        box-shadow: none;
        border-bottom-right-radius: '0.8vw';
        border-bottom-left-radius: '0.8vw';
        margin-top: 0;

    }

    .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters {
        margin: 12px 0;
    }

    .filter_box {
        box-shadow: 0px 2px 5px 0px #00000040;
        border-radius: 0.8vw;
        padding: 1vw 0.2vw;
        margin-bottom: 2vw;
    }

    .topsearchdiv {
        margin-bottom: 1.5vw;

        .topsearchbar {
            width: 100%;
        }

        fieldset {
            border: none;
            box-shadow: 0px 2px 6px 0px #00000040;
            border-radius: 1.8vw;
        }

        input {
            padding: 0 1vw;
            height: 3.4vw;
            font-size: 0.96vw;
            font-weight: 600;
            line-height: 1.156vw;

            &::placeholder {
                font-size: 0.96vw;
                line-height: 1.156vw;
                font-family: 'Inter', sans-serif !important;
                font-weight: 400;
            }
        }

        .graybtn {
            position: absolute;
            right: 0.3vw;
            color: #fff !important;
            top: 8%;
            box-shadow: none;
            min-width: 14.96vw !important;
            height: 2.56vw;
            font-size: 0.96vw !important;
            line-height: 1.15vw !important;

        }

    }

    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded.MuiAccordionSummary-gutters {
        min-height: auto;
        padding: 0 13px 0 16px;
    }

    .padd0 {
        padding-left: 0 !important;
    }

    .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
        min-height: 34px
    }

    .MuiAccordion-root:before {
        height: 0;
    }

    .MuiAccordionDetails-root {
        padding: 0px 0px 0px 9px;
    }

    .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
        margin-left: 13px;
        margin-bottom: 0.5vw;
    }

    .MuiFormControlLabel-label {
        min-width: 8.2vw;
        font-size: 0.96vw;
        line-height: 1.15vw;
        width: 8.5vw;
    }

    .MuiFormGroup-root.MuiFormGroup-row {
        position: relative;
        width: 100%;
        display: block;
    }

    .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary {
        padding-top: 2px;
        padding-bottom: 2px;
        position: absolute;
        right: 3px;
    }

    .directory_filter_card {
        box-shadow: 0px 2px 5px 0.5px #0000004D;
        background-color: #fff;
        border-radius: 1.6vw;
        padding: 1.5vw;
        margin-bottom: 1vw;
    }

    .clear_filter {
        text-decoration: underline;
        cursor: pointer;
        margin-right: 13px;
    }

    .location_img {
        width: 1.36vw;
        height: 1.36vw;
        margin-right: 0.5vw;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .desktophide {
        display: none;
    }

    .img_directory_logo {
        display: flex;
        align-items: center;
    }

    .darkbtn {
        font-size: 1.12vw !important;
        line-height: 1.34vw !important;
        box-shadow: none;
        min-width: 10.32vw !important;
        height: 2.8vw;
    }

    .avatar_img {
        width: 3.44vw;
        height: 3.44vw;
    }

    // pagination css 

    .paginationselectbox .MuiPagination-ul li button {
        width: 2.56vw;
        height: 2.5vw;
        font-size: 1vw;
        border-radius: 0.8vw;
    }

    .paginationselectbox .MuiPaginationItem-previousNext svg {
        font-size: 2.5vw;
    }

    .paginationselectbox .selectbox .select-field {
        width: 15vw;
        border-radius: 1vw;
        height: 2.8vw;
    }

    .paginationselectbox .selectbox {
        width: 15vw;
    }

    .paginationselectbox .selectbox .selectboxwidth {
        width: 15vw;
    }

    .paginationselectbox .selectbox .MuiSelect-select {
        font-size: 1vw;
    }

    // pagination css 

    .font5px {
        font-size: 0.4vw;
        line-height: 0.8;
    }

    .cosimo_icon {
        width: 0.8vw;
        height: 0.8vw;
    }

    .directory_card_section {
        margin-left: 2%;
    }
}

@media screen and (min-width: 208px) and (max-width: 600px) {
    .directory_filter_screen {
        padding: 24px;

        .buffering_section {
            padding-top: 0 !important;
        }

        .width100 {
            width: 100%;
            display: none !important;
        }

        // pagination css 

        .paginationselectbox .selectbox {
            width: 70%;
        }

        .paginationselectbox .selectbox .selectboxwidth {
            width: 100%;
        }

        .paginationselectbox .selectbox .select-field {
            width: 100%;
            border-radius: 15px;
            height: 11.59vw;
        }

        .paginationselectbox .MuiPagination-ul li button {
            width: 8.83vw;
            height: 8.38vw;
            border-radius: 10px;
            font-size: 4.347vw;
            line-height: 5.7971vw;
        }

        .paginationselectbox .MuiPaginationItem-previousNext svg {
            font-size: 1.8em;
        }

        // pagination css 


        .filter_box {
            margin-right: 14px;

            .MuiSvgIcon-root {
                width: 1rem;
                height: 1rem;
            }
        }

        .directory_card_section {
            margin-left: 0;
            margin-right: 14px;
            padding-left: 0 !important;
        }

        .filter_text {
            margin-left: 16px;
        }

        .filter_img {
            width: 4vw;
            height: 3vw;
        }

        .topsearchdiv {
            margin-bottom: 5vw;
            margin-top: 3vw;

            input {
                padding: 0 14px;
                font-size: 2.4vw;
                line-height: 2.8vw;

                &::placeholder {
                    font-size: 2.4vw;
                    line-height: 2.8vw;
                    font-family: 'Inter', sans-serif !important;
                }
            }

            .graybtn {
                right: 5px;
                min-width: 20.96vw !important;
                height: 7.729vw;
                font-size: 3.6vw !important;
                line-height: 3vw !important;
                border-radius: 15px !important;
            }

            fieldset {
                border-radius: 20px;
            }

            input {
                height: 9.17vw;
            }
        }

        .avatar_img {
            width: 10.38vw;
            height: 10.38vw;
        }

        .directory_filter_card {
            border-radius: 3.6vw;
            padding: 5.5vw;
            margin-bottom: 5vw;
        }

        .font5px {
            font-size: 1.4154vw !important;
            line-height: 2vw !important;
        }

        .cosimo_icon {
            width: 2.8vw;
            height: 2.8vw;
        }

        .location_img {
            width: 4.10vw;
            height: 4.10vw;
            margin-right: 2.5vw;
        }

        .desktophide {
            display: block;
        }

        .darkbtn {
            min-width: 100% !important;
            height: 9.56vw;
            font-size: 3.6vw !important;
            line-height: 4.15vw !important;
        }

        .MuiFormControlLabel-label {
            min-width: 18.2vw;
            font-size: 2.96vw;
            line-height: 3.15vw;
        }

        .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary {
            position: relative;
        }

        .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
            margin-left: 0;
            display: block;
        }

        .MuiFormGroup-root.MuiFormGroup-row {
            display: block;
        }

        .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
            font-size: 3.5vw !important;
            line-height: 5vw !important;
        }
    .paginationselectbox .selectbox .MuiSelect-select{
        font-size: 4vw;
    }
    }

    .directory_dialog_box {
        .clear_filter {
            text-decoration: underline;
            cursor: pointer;
            margin-right: 13px;
        }

        .darkbtn {
            font-size: 2.5vw !important;
            border-radius: 15px !important;
            width: 100%;
            box-shadow: none;
            min-height: 8.864vw;
            margin-bottom: 3vw;
        }

        .directory_filter_screen {
            padding: 0;
        }
    }
}

@media screen and (min-width: 601px) and (max-width: 920px) {
    .directory_filter_screen {

        .MuiFormGroup-root.MuiFormGroup-row {
            display: inherit;

            label {
                margin-bottom: 10px;
            }
        }

        .topsearchdiv {
            input {
                padding: 0 14px;
                height: 4.8vw;
            }

            fieldset {
                border-radius: 20px;
            }

            .graybtn {
                min-width: 17.96vw !important;
                height: 4vw;
                border-radius: 20px !important;
            }

        }

        .filter_box {
            .MuiSvgIcon-root {
                width: 1rem;
                height: 1rem;
            }
        }

        img.filter_img {
            width: 1rem;
            height: 0.6rem;
        }

    }
}


@media screen and (min-width: 921px) and (max-width: 1200px) {

    .directory_filter_screen {
        .filter_box {
            .MuiSvgIcon-root {
                width: 1rem;
                height: 1rem;
            }
        }

        .filter_text {
            margin-left: 1rem;
        }
    }
}


@media screen and (min-width: 1600px)  {
    .directory_filter_screen .MuiFormControlLabel-label {
        width: 9.5vw;
        font-size: .8vw;
        line-height: 1.5vw;
    }
    .directory_filter_screen .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
        margin-bottom: 0.1vw;
    }

}

