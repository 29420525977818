.artistscard {
  width: 30%;
  .image-section {
    width: 15.2vw;
    border-radius: 1.6vw;
    .img_card {
      width: 15.2vw;
      height: 18vw;
      background: #d9d9d9;
      border-radius: 1.6vw;
    }
    .artistbtn {
      text-align: center;
      display: none;
      .darkbtn {
        width: 85%;
        margin: 1.2vw 0 !important;
        font-size: 0.96vw !important;
        line-height: 1.15vw !important;
        min-height: 2.42vw !important;
        box-shadow: none !important;
      }
    }
  }
  &:hover {
    .image-section {
      background: hsla(0, 0%, 85%, 0.2);
      width: fit-content;
    }
    .protextsection {
      display: none;
    }
    .artistbtn {
      display: block;
    }
  }
}

@media screen and (max-width: 700px) {
  .artistscard {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 34px 22px 20px;
    .image-section {
      width: 100%;
      .img_card {
        width: 100%;
        height: 54vw;
      }
      .protextsection {
        .proname,
        .prolocation {
          font-size: 3.34vw;
          line-height: 3.34vw;
          padding-left: 0;
        }
      }
    }
  }
}
