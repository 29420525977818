// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footersection {
  background: linear-gradient(111.56deg, #1e282c 28.13%, #3e3e3e 102.03%);
  padding: 0.5vw 0 2vw;
  .font25px {
    color: #fff;
    font-weight: 400;
  }
  .floating-search-container {
    position: relative;
    input {
      padding: 0.8vw 1vw;
      width: 14vw;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      &::placeholder {
        font-size: 1.2vw !important;
        line-height: 1.68vw !important;
        color: #000000e5 !important;
        font-family: 'Inter', sans-serif !important;

      }
    }
    .darkbtn {
      position: absolute;
      top: 50%;
      right: 4px;
      transform: translateY(-50%);
      font-size: 1.23vw !important;
      width: 100% !important;
      padding: 1.05vw 1.9vw !important;
      max-width: fit-content !important;
      min-width: 68px !important;
      box-shadow: none;
      min-height: auto !important;
      border-radius: 40px !important;
      height: fit-content !important;
      color: #fff !important;
      font-family: 'Inter', sans-serif !important;

    }
  }
  .graybtn {
    font-size: 1.28vw !important;
    line-height: 1.6vw !important;
    background-color: #595959 !important;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 12.32vw !important;
    height: 3.12vw;
    color: #fff !important;
  }
  .search_input_hover {
    box-shadow: none;
    border-radius: 2vw;
    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }

    .MuiInputBase-root.MuiOutlinedInput-root {
      min-height: 3.4615vw;
    }
  }
  .search_input_hover .MuiInputBase-root.MuiOutlinedInput-root {
    background-color: #fff;
    border-radius: 25px;
  }
  .cardiconfooter li:nth-child(1),
  .cardiconfooter li:nth-child(2),
  .cardiconfooter li:nth-child(3) {
    padding-top: 0.5vw;
  }
  .artists_heading {
    margin-block-end: 1em;
  }
  ul.footermenu li {
    font-size: 1.2vw;
    line-height: 2.1vw;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  ul.footermenu {
    padding-inline-start: 0;
    list-style: none;
    color: #fff;
    margin-bottom: 0;
  }
  ul.bottomlinks li:last-child:after {
    display: none;
  }

  ul.bottomlinks li:after {
    content: '|';
  }
  ul.bottomlinks {
    padding-inline-start: 0;
    list-style: none;
    color: #fff;
    display: flex;
    margin: 0px;
    li:first-child {
      padding-left: 0;
    }
    li {
      font-size: 1.4vw;
      line-height: 1.8vw;
      a {
        color: #fff;
        padding: 0 0.4vw;
        text-decoration: none;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
  ul.bottomlinks li:first-child {
    padding-left: 0;
  }
  .copyrighttext {
    text-align: right;
    font-size: 1.28vw;
    line-height: 1.6vw;
    color: #fff;
    margin: 0;
    font-family: 'Inter', sans-serif !important;

  }
  .inspiration_text {
    margin-bottom: 1vw;
  }

  .bootomcopy {
    margin-top: 0vw;
  }
  .footercopy {
    margin-top: 2vw;
  }
  .cardiconfooter {
    margin-top: 3vw;
    ul {
      display: flex;
      justify-content: flex-end;
      list-style: none;
      padding-inline-start: 0;
      li {
        padding: 0 0.4vw;
        width: 4.88vw;
        height: 3.8vw;
      }
    }
  }
  .socialiconfooter {
    margin-top: 4vw;
  }
  .socialiconfooter ul {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding-inline-start: 0;
    margin-top: 0vw;
    li {
      padding: 0 0.3vw;
    }
    li img {
      width: 2.15vw;
      height: 2.15vw;
    }
  }
  .iconimgfooter {
    width: 100% !important;
    height: auto !important;
    margin: 0 0.2vw;
  }
}
.footersection ul.bottomlinks li a:first-child {
  padding-left: 0;
}
@media (min-width: 601px) and (max-width: 950px) {
  .footersection {
    .floating-search-container {
      input {
        width: 13vw;
        &::placeholder {
          font-size: 1.3vw !important;
          line-height: 1.5vw !important;
        }
      }
      .darkbtn {
        font-size: 1.3vw !important;
      }
    }
  }
}
@media (min-width: 921px) and (max-width: 1280px) {
  .footersection {
    ul.bottomlinks li {
      display: flex;
      align-items: center;
    }
    ul.bottomlinks {
      margin-block-start: 0;
    }
  }
}
@media only screen and (min-width: 1930px) {
  .footersection {
    .floating-search-container {
      .darkbtn {
        top: 51%;

        transform: translateY(-50%);
        padding: 13px 25px !important;
      }
      .MuiInputBase-root.MuiOutlinedInput-root {
        font-size: inherit;
        line-height: inherit;
      }
    }
    ul.footermenu li {
      font-size: 1.2vw;
      line-height: 1.5vw;
    }
    ul.bottomlinks li {
      font-size: 1.2vw;
      line-height: 1vw;
    }
    .cardiconfooter {
      img {
        width: 3.7vw;
        height: 4vw;
      }
    }
    .cardiconfooter li:nth-child(1),
    .cardiconfooter li:nth-child(2),
    .cardiconfooter li:nth-child(3) {
      padding-top: 0.5vw;
    }
    .socialiconfooter {
      img {
        width: 2.5vw;
        height: 2.5vw;
      }
    }
    .copyrighttext {
      margin: 0;
      font-size: 1.2vw;
    }
  }
}
@media only screen and (max-width: 599px) {
  .footersection {
    .graybtn {
      height: auto;
      font-size: 4.8vw !important;
      border-radius: 2.4vw !important;
      min-width: 50.4615vw !important;
      width: 100%;
      min-height: 9vw;
      padding: 2.6vw 11vw !important;
      border-radius: 2.415vw !important;
      margin-bottom: 4vw;
    }
    .socialiconfooter {
      margin-top: 6vw;
    }
    .floating-search-container {
      input {
        padding: 11px 14px;
        width: 34vw;
        &::placeholder {
          font-size: 4.347vw !important;
          line-height: 5.7971vw !important;
        }
      }
      .darkbtn {
        font-size: 3.864vw !important;
        padding: 3.2vw 5.03vw !important;
        min-width: 37.19vw !important;
        min-height: 9.42vw !important;
      }
    }
    .search_input_hover .MuiInputBase-root.MuiOutlinedInput-root {
      min-height: 10.86vw;
    }
    .cardiconfooter li:nth-child(1),
    .cardiconfooter li:nth-child(2),
    .cardiconfooter li:nth-child(3) {
      padding-top: 0.5vw;
    }
    .cardiconfooter li:last-child {
      margin-top: -1.2vw !important;
    }
    .artists_heading {
      font-size: 6vw !important;
      line-height: 7.2vw !important;
    }
    ul.footermenu {
      margin-bottom: 0;
      li {
        font-size: 4.83vw;
        line-height: 6.2vw;
      }
    }
    .cardiconfooter ul {
      justify-content: center;
      li {
        margin: 0 1.2vw;
        width: 13.28vw;
        height: 8vw;
      }
    }
    .bootomcopy {
      flex-direction: column-reverse;
      .MuiGrid-item {
        width: 100%;
      }
    }
    .copyrighttext {
      display: none;
    }
    .socialiconfooter {
      ul {
        justify-content: center;
        li {
          padding: 0px 2vw;
          img {
            width: 8vw;
            height: 8vw;
          }
        }
      }
    }
    ul.bottomlinks {
      margin-block-start: 0em;
      li {
        font-size: 4vw;
        line-height: 5.2vw;
        a {
          padding: 0 1.2vw;
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .footersection .floating-search-container {
    .darkbtn {
      min-height: 10.5vw !important;
    }
  }
}
@media only screen and (max-width: 290px) {
  .footersection {
    overflow-x: hidden;
    ul.bottomlinks li {
      font-size: 4vw;
    }
  }
}
