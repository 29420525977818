.unlock_plan_card {
  border: 0.5px solid #595959;
  border-radius: 1.6vw;
  padding: 6vw 2.24vw 2.24vw;
  min-width: 23.36vw;
  min-height: 34.08vw;
  position: relative;
  .purchase_text {
    margin-bottom: 3vw;
  }
  .payment_text {
    margin-bottom: 2.2vw;
  }
  .btn_section {
    position: absolute;
    bottom: 2.24vw;
    .outlinebtn {
      box-shadow: none;
      font-size: 1.4vw !important;
      line-height: 1.72vw !important;
      width: 100%;
      min-height: 3.84vw;
      min-width: 17.44vw !important;
      border-radius: 1.2vw !important;
      padding: initial !important;
    }
  }
}
// most popular card

.most_popular_card {
  border: 0.5px solid #f5445e;
  border-radius: 1.6vw;
  min-width: 23.36vw;
  min-height: 34.08vw;
  position: relative;

  .popular_inner {
    padding: 0vw 2.24vw 2.24vw;
  }
  .popular_text {
    border: 0.5px solid #f5445e;
    background-color: #f5445e;
    padding: 0.88vw;
    text-align: center;
    border-top-left-radius: 1.6vw;
    border-top-right-radius: 1.6vw;
    margin-bottom: 2.64vw;
    color: #ffffff;
  }
  .purchase_text {
    margin-bottom: 3vw;
  }
  .payment_text {
    margin-bottom: 2.2vw;
  }
  .btn_section {
    position: absolute;
    bottom: 2.24vw;
    .darkbtn {
      box-shadow: none;
      font-size: 1.4vw !important;
      line-height: 1.72vw !important;
      width: 100%;
      min-height: 3.84vw;
      min-width: 17.44vw !important;
      border-radius: 1.2vw !important;
    }
  }
}

.unlock_course_dialogbox {
  .MuiPaper-root.MuiDialog-paper {
    width: 51.12vw !important;
  }
}
.close_icon_most_popular{
  position: relative;
  top: -10px;
  right: -10px;

  svg{
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
    .unlock_course_dialogbox {
        .MuiPaper-root.MuiDialog-paper {
          width: 100% !important;
          min-width: 90%;
        }
      }
      .most_popular_card, .unlock_plan_card {
        min-height: 102.8vw;
        border-radius: 4.83vw;
        .btn_section{
            bottom:  6.76vw;
            width: 80%;

            .darkbtn, .outlinebtn{
                font-size: 4.83vw !important;
                line-height: 6vw !important;
                border-radius: 3.6vw !important;
                height: 11.59vw;
            }
        }
        .purchase_text{
            margin-bottom: 9.178vw;
        }
        .payment_text{
            margin-bottom: 7.48vw;
        }
      }
      .unlock_plan_card{
        padding:18.11vw  6.76vw  6.76vw;
      }
      .most_popular_card{
        .popular_text{
            padding: 2.657vw 0;
            border-top-left-radius: 4.83vw;
            border-top-right-radius: 4.83vw;
        }
        .popular_inner{
            padding:0 6.76vw  0 6.76vw ;
          
        }
      }

}



@media screen and (min-width: 601px) and (max-width: 1300px) {
    .unlock_course_dialogbox {
        .MuiPaper-root.MuiDialog-paper {
          width: 55.12vw !important;
        }
      } 
}