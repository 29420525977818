.order-detail {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0;
    margin: 0;
  }
  .order-wrap {
    margin-top: 11.5vw;
    .top-heading {
      display: none;
    }
    .order-history {
      margin-top: 0;
    }
    .whitebtn {
      color: #f5445e;
      border: 1px solid #f5445e;
      font-size: 1.25rem !important;
      padding: 1.25rem 1.5rem !important;
      border-radius: 1.25rem !important;
      width: 296px;
    }
  }
  .order-table {
    box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin-top: 1.6rem;
    .order-head {
      background: #595959;
      tr {
        th {
          color: #fff;
          font-size: 20px;
          font-weight: 400;
          font-family: 'Inter', sans-serif !important;

        }
      }
    }
    .order-body {
      min-height: 100px;
      max-height: 100px;
      height: 100px;
      .MuiTableCell-body {
        font-size: 20px;
        border-bottom: none;
        font-weight: 400;
        font-family: 'Inter', sans-serif !important;

      }
    }
  }
  .order-grid {
    display: grid;
    grid-gap: 1.68vw;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5rem;

    .additional-box {
      flex-direction: column;
    }

    .recipt {
      margin-top: 2rem;
      .darkbtn {
        font-size: 1.6vw !important;
        height: 4vw;
        border-radius: 1.6vw !important;
        box-shadow: none;
        width: 100%;
      }
    }

    .shipping-detail {
      display: flex;
      flex-direction: column;
      gap: 0 !important;
      margin-bottom: 0 !important;
      height: inherit;
      .greenbtn,
      .darkbtn {
        font-size: 1.6vw !important;
        height: 4vw;
        border-radius: 1.6vw !important;
        box-shadow: none;
        width: 100% !important;
        margin-top: auto;
        margin-bottom: 1.5vw;
      }
      .topmargin {
        margin-top: 1vw;
      }
      .detials {
        margin: 26px 0 100px;
        .detials-headings {
          display: flex;
          gap: 12px;
        }
      }
    }
    .order-detail {
      display: flex;
      flex-direction: column;
      gap: 0 !important;
      margin-bottom: 0 !important;
      .order-heading {
        margin-bottom: 30px;
      }
      .order-detail-table {
        width: 100%;
        text-align: left;
        .order-items {
          display: flex;
          justify-content: space-between;
          margin-bottom: 13px;
          .price {
            text-align: end;
          }
        }
        .order-total {
          display: flex;
          justify-content: space-between;
          margin: 26px 0 36px;
          border-top: 0.5px solid #3e3e3e;
          border-bottom: 0.5px solid #3e3e3e;
          padding: 20px 0 27px;
          .price {
            text-align: end;
          }
        }
      }
      .artwork-detail {
        max-height: 250px;
        height: 100%;
        min-height: 250px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .single-artwork-detail {
          display: flex;
          gap: 24px;
          img {
            border-radius: 20px;
          }
          .detail {
            p {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .order-required {
    padding: 1.68vw 2vw !important;
    .order-left {
      h4 {
        font-size: 1.6vw;
        margin-bottom: 0.8vw;
      }
      p {
        font-size: 1.28vw;
        line-height: 1.68vw;
        text-align: inherit;
      }
    }
    .order-right {
      display: flex;
      flex-direction: column;
      row-gap: 0.56vw;
      .graybtn,
      .greenbtn {
        width: 26.16vw !important;
        height: 4.8vw;
        font-size: 1.6vw !important;
        border-radius: 1.6vw !important;
      }
      .greenbtn {
        background: #80c86e !important;
      }
    }
  }
}
.print-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .top-heading {
    font-size: 1.76vw;
    line-height: 2.08vw;
  }
  .input {
    border: 1px solid #00000069;
    border-radius: 0.8vw;
    padding: 1.04vw 1.36vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2.4vw;
    table {
      font-size: 1.28vw;
      .mainhead {
        white-space: nowrap;
        width: 7.031vw !important;
      }
      .right-tr {
        margin-left: 1vw;
        word-break: break-all;
      }
    }
    button {
      text-transform: capitalize;
      text-decoration: underline;
      color: #000;
      padding: 0;
      font-size: 1.28vw;
      &:hover {
        background: transparent;
      }
    }
  }
  .description {
    font-size: 1.44vw;
    margin-bottom: 2.96vw;
  }
  .greenbtn {
    width: 14.96vw;
    height: 4.8vw;
    font-size: 1.6vw !important;
    background: #80c86e !important;
    border-radius: 1.6vw !important;
  }
}
.print-pop {
  .decline-box,
  .accept-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .decline-heading {
      font-size: 1.76vw;
    }
    .decline-msg {
      font-size: 1.44vw;
    }
    .selectbox {
      width: 100%;
      .MuiFormControl-root {
        .MuiInputBase-root {
          border-radius: 0.8vw;
          height: 4.16vw;
          svg {
            color: #000;
            font-size: 2.6vw;
          }
        }
      }
    }
    .darkbtn {
      box-shadow: none;
      width: 14.96vw;
      height: 4.8vw;
      border-radius: 1.6vw !important;
      font-size: 1.6vw !important;
    }
    .greenbtn {
      width: 14.96vw !important;
      height: 4.8vw;
      font-size: 1.6vw !important;
      background: #80c86e !important;
      border-radius: 1.5vw !important;
      color: #fffcfc !important;
    }
  }
  .decline-box {
    .decline-heading {
      width: 32.8vw;
    }
    .darkbtn {
      margin-top: 4.96vw;
    }
  }
}

.confirm-decline {
  display: flex;
  flex-direction: column;
  align-items: center;
  .decline-msg-confirm {
    font-size: 1.76vw !important;
    line-height: 2.08vw;
  }
  .darkbtn {
    box-shadow: none;
    width: 14.96vw;
    height: 4.8vw;
    border-radius: 1.6vw !important;
    font-size: 1.6vw !important;
  }
}
.artlovermyaccount {
  padding-top: 8.5vw;
  .top-heading {
    .only-invoice {
      display: none;
    }
  }
}

@media only screen and (min-width: 1930px) and (max-width: 4000px) {
  .order-detail {
    .order-required {
      .order-left {
        h4 {
          font-size: 1vw;
        }
        p {
          font-size: 0.8vw;
        }
      }
    }
  }
  .order-detail .order-required .order-right {
    .graybtn,
    .greenbtn {
      width: 16.35vw !important;
      height: 3vw;
      font-size: 1vw !important;
      border-radius: 1vw !important;
    }
  }
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
  .order-detail {
    .order-grid {
      grid-template-columns: auto;
    }
  }
}

@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .order-detail .order-grid .shipping-detail {
    .greenbtn,
    .darkbtn {
      height: 6.5vw;
    }
  }
}

@media screen and (max-width: 800px) {
  .order-detail {
    .order-required {
      .order-left {
        h4 {
          font-size: 2.545vw;
        }
        p {
          font-size: 2.036vw;
          line-height: 2.672vw;
        }
      }
    }
  }
  .order-detail .order-required .order-right {
    .graybtn,
    .greenbtn {
      width: 30vw !important;
      height: 7.634vw;
      font-size: 2.545vw !important;
      border-radius: 2.545vw !important;
    }
  }
  .print-box {
    .top-heading {
      font-size: 3vw;
      line-height: 4vw;
    }
    .input {
      border-radius: 1.5vw;
      padding: 1.5vw 2vw;
      margin-bottom: 4vw;
      table {
        font-size: 2vw;
      }
    }
    .description {
      font-size: 2.5vw;
      margin-bottom: 3vw;
    }
    .greenbtn {
      width: 24.96vw;
      height: 7.8vw;
      font-size: 2vw !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .artlovermyaccount {
    padding: 0 20px;
  }
  .order-detail {
    .order-grid {
      .shipping-detail {
        .greenbtn,
        .darkbtn {
          font-size: 3.8vw !important;
          border-radius: 3.8vw !important;
          margin-top: auto;
          height: 12vw !important;
          padding: 0 !important;
          margin-bottom: 4vw;
        }
        .topmargin {
          margin-top: 4vw;
        }
      }
    }
  }
  .order-detail {
    padding: 0 20px;
    .order-grid {
      .shipping-detail {
        .order-detail,
        .shipping-detail,
        .recipt {
          width: clamp(245px, 78vw, 326px);
        }
        .detials {
          width: clamp(180px, 71vw, 300px);
          overflow: auto;
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
        .darkbtn {
          height: 60px;
        }
      }
      .recipt {
        .darkbtn {
          height: 60px;
        }
      }
    }
    .order-wrap {
      .whitebtn {
        font-size: 1.25rem !important;
        padding: 1.25rem 1.5rem !important;
        border-radius: 1.25rem !important;
        width: 100%;
      }
    }
    .order-required {
      padding: 5.072vw 6.522vw !important;
      flex-direction: column;
      gap: 0 !important;
      .order-left {
        text-align: center;
        margin-bottom: 7vw;
        h4 {
          font-size: 4.831vw;
          margin-bottom: 1.5vw;
        }
        p {
          font-size: 3.865vw;
          line-height: 5.5vw;
          text-align: center;
        }
      }
      .order-right {
        row-gap: 2vw;
        width: 100%;
        .graybtn,
        .greenbtn {
          width: 100% !important;
          height: 12vw;
          font-size: 4.831vw !important;
          border-radius: 1.2rem !important;
        }
      }
    }
  }
  .print-box {
    .top-heading {
      font-size: 3.865vw;
      line-height: 4.5vw;
    }
    .input {
      border-radius: 1.5vw;
      overflow: auto;
      table {
        font-size: 3.865vw;
        .mainhead {
          width: 20.773vw !important;
        }
        .right-tr {
          width: 38vw !important;
        }
      }
      button {
        font-size: 3.86vw;
      }
    }
    .description {
      font-size: 3.865vw;
      margin-bottom: 4vw;
    }
    .greenbtn {
      width: 32vw;
      height: 13vw;
      font-size: 4.6vw !important;
      border-radius: 4.6vw !important;
    }
  }
  .print-pop {
    .decline-box,
    .accept-box {
      .decline-heading {
        font-size: 4.5vw;
      }
      .decline-msg {
        font-size: 3.4vw;
      }
      .selectbox {
        width: 100%;
        .MuiFormControl-root {
          .MuiInputBase-root {
            border-radius: 2.415vw;
            height: 14.976vw;
            svg {
              font-size: 6.6vw;
            }
          }
        }
      }
      div {
        width: 100%;
        .darkbtn {
          box-shadow: none;
          width: 100%;
          height: 14.976vw;
          border-radius: 4.831vw !important;
          font-size: 4.831vw !important;
        }
      }
    }
    .decline-box {
      .decline-heading {
        width: 100%;
      }
      .darkbtn {
        margin-top: 14.976vw;
      }
    }
  }
}
