.alartistsfollowed {
  .search_result_container {
    max-width: 100% !important;
    padding: 0;
  }

  .MuiGrid-grid-xs-3 {
    background-color: #f4f4f4;
    margin-top: 1.1vw;
    .sidebar {
      width: inherit;
    }
  }

  .searchbtninput {
    padding-top: 9.6vw;
    margin-bottom: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .font25px {
      font-size: 2.5rem !important;
      line-height: 2rem !important;
    }

    .selectbox {
      width: 19.68vw;
      .selectboxwidth {
        width: 19.68vw;
        margin: 0;
      }
      .select-field {
        width: 19.68vw;
        border-radius: 1.2vw;
        height: 3.68vw;
      }
      .MuiSelect-select {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 22px;
        font-size: 1.6vw;
      }
      svg {
        color: #000;
        font-size: 2em;
      }
    }
  }
  .searchcardbox {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2.604vw;
    column-gap: 2.604vw;
    margin-bottom: 5.5rem;
    .artistscard {
      width: 30%;
      .image-section {
        width: auto;
        .img_card {
          width: auto;
          height: 18vw;
        }
      }
    }
    .profilenameprice {
      margin-top: 0.5vw !important;
      width: 19.5vw;

      .profilename {
        width: 80%;
      }
    }
    p {
      margin-bottom: 0.3vw;
    }
    .topzeromar {
      margin-top: 0;
    }
    .profilecard {
      width: 33.33%;
      margin-bottom: 5.2vw !important;
      margin: inherit;
      vertical-align: top;
      .img_card {
        width: 19.5vw;
        height: 18.8vw;
      }
      .profilelocation {
        width: 19.5vw;
      }
    }
    .image_section:hover {
      position: relative;
    }
    .image_section:hover .check_icon_heart {
      position: absolute;
      display: block;
      top: 1vw !important;
      width: 2.24vw !important;
      height: 2.24vw !important;
      z-index: 999 !important;
      left: 1.5vw !important;
    }
    .image_section:hover .search_data {
      display: none;
    }
    .image_section:hover .searchbtnhover {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .graybtn {
        min-width: 8.08vw !important;
        letter-spacing: 0px !important;
        min-height: 2.32vw;
        font-size: 0.96vw !important;
        line-height: 1.15vw !important;
        text-transform: inherit !important;
        background: transparent !important;
        border: 1px solid #000;
        padding: 0.8vw 1.165vw !important;
      }
      .darkbtn {
        min-height: 2.32vw;
        font-size: 0.96vw !important;
        line-height: 1.15vw !important;
        box-shadow: none;
        padding: 0.8vw 1.165vw !important;
        min-width: 8.08vw;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .alartistsfollowed {
    .mobileshow {
      display: block;
    }
    .all-screen-container {
      .mobilehide {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .alartistsfollowed {
    .MuiGrid-grid-xs-3 {
      display: none;
    }
    .filter-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px auto;
      .selectbox {
        width: 19.68vw;
        .selectboxwidth {
          width: 19.68vw;
          margin: 0;
        }
        .select-field {
          width: 19.68vw;
          border-radius: 1.2vw;
          height: 3.68vw;
        }
        .MuiSelect-select {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 22px;
          font-size: 1.6vw;
        }
        svg {
          color: #000;
          font-size: 2em;
        }
      }
    }
    .searchcardbox {
      .artistscard {
        .image-section {
          .img_card {
            height: 34vw;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .alartistsfollowed {
    .searchbtninput {
      padding-top: 12.077vw;
    }
    .filter-section {
      .selectbox {
        width: 30vw;
        .selectboxwidth {
          width: 30vw;
        }
        .select-field {
          width: 30vw;
          height: 6.68vw;
        }
      }
    }
    .searchcardbox {
      row-gap: 2.25rem;
      .artistscard {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 34px 22px 20px;
        .image-section {
          .img_card {
            height: 54vw;
          }
          .protextsection {
            .proname,
            .prolocation {
              font-size: 3.34vw;
              line-height: 3.34vw;
              padding-left: 0vw;
            }
          }
        }
      }

      .artists-card-mobile {
        &:hover {
          .protextsection {
            display: block;
          }
          .artistbtn {
            display: none;
          }
        }
      }
    }
  }
  .artistscard:hover {
    .artistbtn {
      display: none;
    }
    .protextsection {
      display: block;
    }
    .image-section {
      background: transparent;
    }
  }
}

@media only screen and (min-width: 1930px) {
  .alartistsfollowed {
    .searchcardbox {
      column-gap: 2vw;
      .artistscard {
        .image-section {
          .img_card {
            height: 13vw;
          }
        }
      }
    }
    .search_result_container {
      max-width: 70% !important;
      padding: 0;
      .above4Ksidebar {
        padding-left: 0 !important;
      }
      .above4K {
        max-width: 90% !important;
      }
    }
  }
}
