
.selectbox, .multiselect {
    .selectboxwidth {
        width: 100%;
        margin: 0.6vw;
        min-width: 100px !important;
    }
    .select-field, .multi-select-field {
       color: #000;
        cursor: pointer;
        font-size: 1.6vw;
        line-height: 1.92vw;
    }
 
}

@media only screen and (min-width: 1930px) {
   .selectbox, .multiselect{
    .select-field, .multi-select-field {
        font-size: 1.2vw;
        line-height: 1.52vw;
    }
    .MuiSelect-select{
       padding: 1vw;
    }
    .MuiSvgIcon-root{
        width: 2.5vw;
        height: 1.8vw;
        top: auto;
      } 
   } 
    .MuiMenu-list {
        li{
            font-size: 0.8vw;
            line-height: 1.2vw;
            .MuiTypography-root{
                font-size: 0.8vw;
                line-height: 1.2vw; 
            }
            .MuiSvgIcon-root {
                width: 1.8vw;
                height: 1.5vw;
            }
        }
    }
}