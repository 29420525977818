.main-form {
  .collection-form {
    display: flex;
    flex-direction: column;
    gap: 0 !important;
    .textfiledspan {
      .error_text {
        padding-top: 0;
        margin-bottom: 0.8vw;
        margin-top: 0;
      }
    }
        h2 {
      font-size: 2vw;
    }
    .MuiFormControl-root {
      width: 100%;
      margin: 0;
      margin-bottom: 1.2vw;
      .MuiInputBase-root {
        background: #fffcfc;
        border-radius: 10px;
        height: 4vw;

       
        .MuiSelect-select {
          font-size: 1.3vw;
          color: #000;
          display: flex;
          align-items: center;
        }
        svg {
          font-size: 2.5rem;
          right: 17px;
          top: 50%;
          color: rgb(0, 0, 0);
          transform: translateY(-50%);
        }
        input {
          height: 4vw;
          font-size: 1.3vw;
          &::placeholder {
            color: #000;
            font-size: 1.3vw;
            font-family: 'Inter', sans-serif !important;
          }
        }
        fieldset {
          border-color: #d9d9d9;

          legend {
            max-width: 0;
          }
        }
      }
      .multiselect-chip{
        height: auto;
        min-height: 4vw;
        & span{
          font-size: 1.3vw !important;
        }     
      }
    }
    .textareabox {
      margin-bottom: 1.6vw;
      .MuiFormControl-root {
        height: 310px;
        padding: 21px;
        width: 100%;
        margin: 0;
        background: #fffcfc;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        resize: none;
        .MuiInputBase-root {
          height: auto;
          font-size: 1.6vw;
          &::before,
          &::after {
            border-bottom: none;
          }
        }
      }
      textarea {
        height: 260px !important;
        font-size: 1.3vw !important;
       
        &::placeholder {
          color: #000;
          font-size:1.3vw;
          font-family: 'Inter', sans-serif !important;
        }
      }
    }
    .profilepic {
      .img_card {
        width: 15.2vw;
        height: 15.2vw;
        border-radius: 0.8vw;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .greenbtn {
        background: transparent !important;
        padding: 0 !important;
        span {
          color: #000;
          text-decoration: underline;
        }
      }
      .changebtn {
        margin-top: 1.28vw;
        margin-bottom: 1vw;
      }
    }
  }
  .darkbtn {
    box-shadow: none;
    width: 100%;
    margin-bottom: 1vw !important;
    height: 4.08vw;
  }
  .error_text {
    padding-top: 0.9vw;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .main-form {
    .collection-form {
     
      .MuiFormControl-root {
        .MuiInputBase-root {
          border-radius: 1.29vw;
          display: flex;
          align-items: center;
          input {
            &::placeholder {
              font-size: 1.086vw !important;
              font-family: 'Inter', sans-serif !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  
  .main-form {
    padding: 0 26px;
    .textfiledspan {
      .error_text {
        font-size: 4.5vw;
        padding-top: 1.9vw;
        margin-bottom: 4vw;
      }
    }
    .error_text {
      font-size: 4.5vw;
      padding-top: 4vw;
      margin-bottom: 4vw;
    }

    .collection-form {
      padding: 2.6vw 3vw !important; 
      h2 {
        font-size: 6.039vw;
      }
      .profilepic {
        .greenbtn {
          margin-bottom: 4vw;
          .darkbtn {
            margin-bottom: 6vw !important;
          }
        }
        .img_card {
          width: 35.2vw;
          height: 35.2vw;
          margin-bottom: 4vw;
        }
      }
      .MuiFormControl-root {
        margin-bottom: 3.2vw;
        .MuiInputBase-root {
          height: 12.077vw;
          font-size: 4.5vw;

        
          svg {
            font-size: 2.5rem;
          }
          input {
            height: 12.077vw;
            padding: 0 auto;
            font-size: 4.5vw;
            &::placeholder {
              font-size: 4.5vw;
            }
          }
        }
        .multiselect-chip {
          height: auto !important;
          min-height: 12.077vw;
          font-size: 4.5vw;
        }
      }
      
      .textareabox {
        margin-bottom: 4.831vw;
        .error_message {
          font-size: 4.5vw;
          padding-top: 4vw;
          margin-bottom: 4vw;
        }
        .MuiFormControl-root {
          height: 310px;
          padding: 21px;
          textarea {
            height: 270px !important;
            font-size: 4.5vw;
            line-height: 5.2vw;
            &::placeholder {
              font-size: 4.5vw;
              font-family: 'Inter', sans-serif !important;
            }
          }
        }
      }
    }
    .darkbtn {
      font-size: 18px !important;
      margin-bottom: 12.077vw !important;
      height: 12.319vw;
     
    }
  }
  .main-form .collection-form .MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
    font-size: 4.5vw;
   
  }
  
}
