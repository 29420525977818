.pdfbleed {
  width: 100vw;
  height: 100%;
  .main {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    position: relative;
    .main-heading {
      text-align: center;
      font-size: 4vw;
      font-weight: 500;
      font-family: 'Inter', sans-serif !important;

      margin: 6vw 0 6.96vw;
    }

    .title-heading {
      font-size: 1.6vw;
      line-height: 1.167;
      letter-spacing: 0em;
      word-break: break-word;
      width: 16.8vw;
      margin-bottom: 0.8vw;
    }

    table {
      font-size: 1.12vw;
      margin-bottom: 2.4vw;
    }

    .art-title {
      display: flex;
      column-gap: 2vw;
      .title-container {
        h4 {
          font-size: 1.6vw;
          font-weight: 500 !important;
          margin-bottom: 5.2vw;
        }
        p {
          font-size: 1.6vw;
        }
      }
      .art-img {
        .big-art-img {
          width: 21.44vw;
          height: 21.6vw;
          object-fit: cover;
          border-radius: 1.2vw;
        }
        .art-row {
          display: flex;
          column-gap: 0.72vw;
          margin-top: 0.96vw;
          .small-art {
            width: 6.64vw;
            height: 5.84vw;
            object-fit: cover;
            border-radius: 1.2vw;
          }
        }
      }
    }

    .discription {
      width: 40vw;
      .certificate-heading {
        color: #000;
        font-size: 1.12vw;
        line-height: 1.5vw;
        margin-bottom: 1vw;
        margin-top: 1vw;
      }
      margin-top: 3.2vw;
      h4,
      p {
        font-size: 1.12vw;
      }
      p {
        width: 40vw;
        padding-top: 0.96vw;
      }
    }
    .footer {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: 50vw;
      p {
        margin-bottom: 0.56vw;
        font-size: 1.12vw;
      }
      img {
        width: 16.16vw;
        height: auto;
      }
    }
  }
}
.return-btn {
  display: flex;
  width: 61.6vw;
  margin: 0 auto;
  padding-top: 2vw;
  .pdfbtn {
    border-radius: 1.2vw !important;
    height: 4vw;
    font-size: 1.6vw !important;
  }
}

@media screen and (max-width: 600px) {
  .pdfbleed {
    margin: 4vw 0;
    .main {
      width: 90.6vw;
      .main-heading {
        font-size: 7.039vw;
        font-family: 'Inter', sans-serif !important;

        margin: 8vw 0 8.96vw;
      }
      .title-heading {
        font-size: 4.6vw;
        width: 45.8vw;
        margin-bottom: 2.8vw;
      }

      table {
        font-size: 2.12vw;
        margin-bottom: 5.4vw;
      }

      .art-title {
        .title-container {
          h4 {
            font-size: 4.6vw;
            margin-bottom: 8.2vw;
          }
          p {
            font-size: 4.6vw;
          }
        }
        .art-img {
          .big-art-img {
            width: 30.44vw;
            height: 30.6vw;
            border-radius: 3.2vw;
          }
          .art-row {
            margin-top: 2.96vw;
            .small-art {
              width: 9.64vw;
              height: 9.84vw;
              object-fit: cover;
              border-radius: 1.2vw;
            }
          }
        }
      }

      .discription {
        margin-top: 5.2vw;
        width: 80vw;
        .certificate-heading {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 10px;
        }
        h4,
        p {
          font-size: 2.45vw;
        }
        p {
          width: 77vw;
          padding-top: 3.96vw;
        }
      }
      .footer {
        width: 90.6vw;
        p {
          font-size: 2.5vw;
        }
        img {
          width: 27vw;
        }
      }
    }
  }
  .return-btn {
    padding-top: 5vw;
    width: 90.6vw;
    .pdfbtn {
      border-radius: 3.623vw !important;
      height: 12.077vw;
      font-size: 4.831vw !important;
      width: 100%;
    }
  }
}
