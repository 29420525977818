.order-history {
  margin-top: 3vw;
  margin-bottom: 2rem;
  .top-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin-bottom: 1.6rem;
    }
    .only-invoice {
      display: flex;
      column-gap: 15px;
      align-items: center;
      h5 {
        font-size: 1.2vw;
        color: #000;
      }
      h4 {
        font-size: 1.2vw;
      }
      .infobtn {
        padding: 0 !important;
        width: 1.2vw;
        height: 1.2vw;
        min-width: 0 !important;
        img {
          width: 1.2vw;
          height: 1.2vw;
        }
      }
    }
  }
  .order-table {
    box-shadow: 0px 4px 40px 0.5px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    .order-head {
      background: #595959;
      tr {
        th {
          color: #fff;
          font-size: 20px;
          font-weight: 400;
          font-family: 'Inter', sans-serif !important;

          border: 2px solid #595959;
        }
      }
    }
    .order-body {
      min-height: 100px;
      max-height: 100px;
      height: 250px;
     
      .btn-detail {
        .darkbtn {
          font-size: clamp(0.9rem, 2vw, 20px) !important;
          padding: 0 !important;
          box-shadow: none;
          width: 100%;
          max-width: 170px;
          min-width: 100px;
        }
      }
      .on-demand-text{
        font-size: 12px;
        line-height: 15px;
        border-bottom: none;
        font-weight: 600;
        font-family: 'Inter', sans-serif !important;

        text-transform: capitalize;
        color: green;
        vertical-align: middle;

      }
      .MuiTableCell-body {
        font-size: 20px;
        border-bottom: none;
        font-weight: 400;
        font-family: 'Inter', sans-serif !important;

        text-transform: capitalize;
      }
    }
  }
}

@media screen and (min-width: 1921px) and (max-width: 6000px) {
  .order-history {
    .order-table {
      .order-head {
        tr {
          th {
            font-size: 1.042vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) and (max-width: 1000px) {
  .order-history .order-table .order-body .btn-detail .darkbtn {
    padding: 1.412vw 3.474vw !important;
  }
}

@media screen and (max-width: 600px) {
  .order-history {
    .top-heading {
      flex-direction: column;
      align-items: start;
      margin-top: 10vw;
      .only-invoice {
        margin-bottom: 4vw;
        h4 {
          font-size: 3.623vw;
        }
        h5 {
          font-size: 4.2vw;
        }
        .infobtn {
          width: 4vw;
          height: 4vw;
          img {
            width: 4vw;
            height: 4vw;
          }
        }
      }
    }
    .order-table {
      .order-head {
        tr th {
          font-size: 12px;
        }
      }
      .order-body {
        .MuiTableCell-body {
          font-size: 12px;
        }
        .on-demand-text{
          font-size: 10px;
          line-height: 15px;
        }
        .btn-detail {
          .darkbtn {
            padding: 11px 18px 12px !important;
            width: 75px;
            font-size: 12px !important;
            border-radius: 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .order-history {
    .top-heading {
      flex-direction: column;
      align-items: start;
      margin-top: 10vw;
      .only-invoice {
        margin-bottom: 4vw;
        h4 {
          font-size: 3.623vw;
        }
        h5 {
          font-size: 4.2vw;
        }
        .infobtn {
          width: 4vw;
          height: 4vw;
          img {
            width: 4vw;
            height: 4vw;
          }
        }
      }
    }
    .order-table {
      .order-head {
        tr th {
          font-size: 12px;
        }
      }
      .order-body {
        .MuiTableCell-body {
          font-size: 12px;
        }
        .btn-detail {
          .darkbtn {
            padding: 11px 18px 12px !important;
            width: 75px;
            font-size: 12px !important;
            border-radius: 10px !important;
          }
        }
      }
    }
  }
}
